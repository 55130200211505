<template>
  <FveForm class="mdl-community__form">
    <FveImagePreview
      :field="field.profilePhoto"
      name="profilePhoto"
      class="mdl-community__image"
    />
    <FveText
      :field="field.firstName"
      class="mdl-community__field"
      label="First name"
      name="firstName"
      placeholder="Type first name"
    />
    <FveText
      :field="field.lastName"
      class="mdl-community__field"
      label="Last name"
      name="lastName"
      placeholder="Type last name"
    />
    <FveEmail
      :field="field.mail"
      class="mdl-community__field"
      label="E-mail"
      name="email"
      placeholder="Type email"
    />
  </FveForm>
</template>

<script>
import FveMixinForm from "@fve/Mixin/FveMixinForm";
import FveText from "@fve/Element/Text/FveText";
import FveEmail from '@fve/Element/Text/FveEmail';
import FveImagePreview from "@fve/Element/File/FveImagePreview";

export default {
  name: "ContactAddForm",
  components: {
    FveText,
    FveEmail,
    FveImagePreview,
  },
  mixins: [FveMixinForm],
  methods: {
    formSchema() {
      return {
        profilePhoto: {
          type: FileClass,
          default: () => {
            return new FileClass();
          },
        },
        firstName: {
          type: String,
          default: () => { return ''; },
          field: {
            required: true,
          },
        },
        lastName: {
          type: String,
          default: () => { return ''; },
          field: {
            required: false,
          },
        },
        mail: {
          type: String,
          default: () => { return ''; },
          field: {
            required: true,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mdl-community__form {
  display: grid;
  grid-gap: 0.875rem 1rem;
  align-items: flex-end;
  grid-template-columns: 3rem 1fr;
  .btn-group {
    grid-column: 1 / 3;
  }

  .mdl-community__image {
    @include icon-size(3);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1;
  }

  .mdl-community__field {
    grid-column: 2;
    ::v-deep(.fve-control > input) {
      background-color: transparent;
      border: 1px solid var(--schedule-event-input-border-color);
    }
  }
}
</style>
