import { ref } from "vue";
import { communityGetSelected } from "@store/community.store";

const scheduleList = ref([]);
const scheduleView = ref("month");
export const scheduleFilter = ref({
  date: new DateTime(),
  scheduleType: {
    meeting: false,
    task: false,
    reminder: false,
  },
});

// getters
export const scheduleGetList = () => {
  // это вызывается 1 раз, а дальше работает как ссылка (возможно стоит углубиться в работу setup )
  return scheduleList;
};

export const getScheduleView = () => {
  return scheduleView;
};

//

export const setScheduleView = (status) => {
  scheduleView.value = status;
};

export const fetchScheduleList = () => {
  const community = communityGetSelected();
  let communityId = null;
  if (community !== null && typeof community !== "undefined") {
    communityId = community.id;
  }
  RequestManager.Schedule.getScheduleList().then((res) => {
    scheduleList.value = res;
  });
};

// let id = 9000;
// const getId = () =>  {
//   id++;
//   return id;
// };

export const ScheduleItemAdd = (scheduleItem) => {
  RequestManager.Schedule.addScheduleItem({ scheduleItem: scheduleItem }).then(
    () => {
      fetchScheduleList();
    }
  );
  // let newScheduleItem = {...scheduleItem};
  // newScheduleItem.id = getId();
  // scheduleList.value.push(newScheduleItem);
};

export const ScheduleItemEdit = (scheduleItemId, scheduleItem) => {
  RequestManager.Schedule.editScheduleItem({
    scheduleId: scheduleItemId,
    scheduleItem: scheduleItem,
  }).then(() => {
    fetchScheduleList();
  });
  // let editScheduleItem = scheduleList.value.find(event => event.id === scheduleItemId);
  // if(!editScheduleItem) {
  //   console.error('Undefined scheduleItemId', scheduleItemId);
  //   return;
  // }
  // Object.assign(editScheduleItem, scheduleItem);
};

export const deleteScheduleItem = (scheduleItemId) => {
  RequestManager.Schedule.deleteScheduleItem({
    scheduleId: scheduleItemId,
  }).then(() => {
    fetchScheduleList();
  });
  // const index = scheduleList.value.findIndex(item => item.id === scheduleItemId);
  // scheduleList.value.splice(index, 1);
};
