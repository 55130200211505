const $enum = {
  SERVER_TYPE: {
    LOCAL        : 'SERVER_TYPE_LOCAL',
    DEVELOP      : 'SERVER_TYPE_DEVELOP',
    PRODUCTION   : 'SERVER_TYPE_PRODUCTION',
  },

  BREAKPOINT: {
    XS: 0,
    SM: 768,
    MD: 992,
    LG: 1200,
    XL: 1620,
  },

  SCHEDULE_EVENT_TYPE: {
    MEETING  : "meeting",
    TASK     : "task",
    REMINDER : "reminder",
  },

  SCHEDULE_EVENT_STATUS: {
    ACCEPTED : "ACCEPTED",
    DECLINED : "DECLINED",
    INVITED  : "INVITED",
    MAYBE    : "MAYBE",
  },

  // prettier-ignore
  EMAIL_FOLDER_NAME: {
    INBOX   : "INBOX",
    UNREAD  : "INBOX",
    SENT    : "SENT",
    SENDING : "SENDING",
    SPAM    : "SPAM",
    DRAFT   : "DRAFTS",
    TRASH   : "TRASH",
  },
};

export default $enum;
