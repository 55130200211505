import {communityList} from '@store/reactiveObject';

export const getFileClassSrc = async (fileClass) => {
  let src = null;

  // TODO: Проблема с Undefined
  // fileClass !== null

  if (fileClass) {
    if ( fileClass.getId() ) {
      src = fileClass.getSrc();
    } else {
      let file = fileClass.getFile();
      if (file instanceof File) {
        try {
          const fileAddResponse = await RequestManager.FileStorage.add({
            file: file,
            shared: true,
          });
          src = fileAddResponse.url;
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  return src;
}


export const diffList = (listFirst, listSecond, key = 'id') => {

  const objFirstKey = {};
  const objSecondKey = {};

  for (let i = 0; i < listFirst.length; i++) {
    const item = listFirst[i];
    objFirstKey[item[key]] = {num: i};
  }

  for (let i = 0; i < listSecond.length; i++) {
    const item = listSecond[i];
    objSecondKey[item[key]] = {num: i};
  }

  //
  // const objAdd    = {};
  // const objDelete = {};
  const listAdd = [];
  const listDelete = [];


  for (let deleteKey in objFirstKey) {
    if (!objSecondKey[deleteKey]) {
      // objDelete[deleteKey] = listFirst[ objFirstKey[deleteKey] ];
      listDelete.push( listFirst[ (objFirstKey[deleteKey].num) ] );
    }
  }

  for (let addKey in objSecondKey) {
    if (!objFirstKey[addKey]) {
      // objAdd[addKey] = listSecond[ objSecondKey[addKey] ];
      listAdd.push( listSecond[ (objSecondKey[addKey].num) ] );
    }
  }

  return {
    add: listAdd,
    delete: listDelete,
  }
};
