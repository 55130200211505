// Do we need this?

import {NOTIFY_TYPES} from '@store/test/testNotification';
import { useRouter } from 'vue-router';

import * as pageName from '@router/variable';
import {ref} from 'vue';

export const notificationFilterState = ref({});

export const prepareNotificationData = (notificationList) => {
  console.log(notificationList);
  let output = {
    notificationDate: [],
    notificationObj: {},
  };
  notificationList.forEach(notification => {
    const date = DateTime(notification.datetime).format('YYYY-MM-DD');
    if (!output.notificationDate.includes(date)) {
      output.notificationDate.push(date);
    }
    
    if (!output.notificationObj[date]) {
      output.notificationObj[date] = [];
    }
  
    output.notificationObj[date].push(notification);
  });
  return output;
};

export function pushAction(pushObj) {
  const $router = VueApp ? VueApp.$root.$router : null;
  switch(pushObj.type) {
    //
    case NOTIFY_TYPES.SCHEDULE_MEETING_CREATED:         $router.push({ name: pageName.HOME }); break;
    case NOTIFY_TYPES.SCHEDULE_TASK_CREATED:            $router.push({ name: pageName.HOME }); break;
    case NOTIFY_TYPES.SCHEDULE_REMINDER_CREATED:        $router.push({ name: pageName.HOME }); break;
    //
    case NOTIFY_TYPES.COMMUNITY_ADD_USER:               $router.push({ name: pageName.HOME }); break;
    case NOTIFY_TYPES.COMMUNITY_REMOVE_USER:            $router.push({ name: pageName.HOME }); break;
    case NOTIFY_TYPES.COMMUNITY_CREATE_BOARD:           $router.push({ name: pageName.BOARD }); break;
    case NOTIFY_TYPES.COMMUNITY_CHAT_MESSAGE:           $router.push({ name: pageName.HOME }); break;
    //
    case NOTIFY_TYPES.PERSONAL_ADD_USER:                $router.push({ name: pageName.HOME }); break;
    case NOTIFY_TYPES.PERSONAL_CHAT_MESSAGE:            $router.push({ name: pageName.HOME }); break;
    //
    case NOTIFY_TYPES.EMAIL_DELAY_SENDING_COMPLETE:     $router.push({ name: pageName.EMAIL }); break;
    case NOTIFY_TYPES.EMAIL_RECEIVED:                   $router.push({ name: pageName.HOME }); break;
    case NOTIFY_TYPES.EMAIL_RECEIVED_EXTERNAL:          $router.push({ name: pageName.HOME }); break;
    
    default: console.warn('No push type provided: ', pushObj);
  }
};