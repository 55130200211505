<template>
  <div class="dlg">

    <transition appear name="fade">
      <div v-if="overlayDisplay" class="dlg-overlay" @click="overlayClick()"></div>
    </transition>

    <template v-for="(groupList, groupName) in modalObj" :key="groupName">
      <div class="dlg-container" :class="'dlg-container-' + groupName">
        <template v-for="(modalInfoObj, index) in modalObj[groupName]" :key="modalInfoObj.id">

          <transition name="component-fade" mode="out-in">
            <template v-if="index < groupSettings[groupName].maxDisplayItem">
              <div class="dlg-item" :class="modalInfoObj.setting.theme ? 'dlg-item__' + modalInfoObj.setting.theme : ''">

                <div class="dlg-item__header-actions">
                  <div v-if="modalInfoObj.setting.remove" class="dlg-item-remove" @click="onRemove(modalInfoObj)">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.9059 6.75652C19.2301 6.75652 19.5 7.03107 19.5 7.38041V7.70341C19.5 8.04426 19.2301 8.3273 18.9059 8.3273H5.09488C4.76988 8.3273 4.5 8.04426 4.5 7.70341V7.38041C4.5 7.03107 4.76988 6.75652 5.09488 6.75652H7.52464C8.01821 6.75652 8.44775 6.39867 8.55879 5.89378L8.68603 5.31409C8.88378 4.52444 9.53458 4 10.2794 4H13.7206C14.4573 4 15.1154 4.52444 15.3059 5.27244L15.442 5.89293C15.5522 6.39867 15.9818 6.75652 16.4762 6.75652H18.9059Z"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00074 10.6102C5.9931 10.4515 6.045 10.3012 6.15031 10.1803C6.2541 10.067 6.40368 10 6.56089 10H17.4449C17.6013 10 17.7433 10.067 17.8555 10.1803C17.96 10.3012 18.0127 10.4515 17.9974 10.6102C17.9974 10.6625 17.6026 15.6064 17.3637 17.792C17.2141 19.1333 16.3334 19.9476 15.0124 19.97C13.9967 19.9923 13.0038 20 12.0262 20C10.9884 20 9.97338 19.9923 8.98815 19.97C7.71141 19.9399 6.82997 19.111 6.68803 17.792C6.49091 16.0327 6.1727 12.5084 6.05056 11.1557C6.02044 10.822 6.00225 10.6205 6.00074 10.6102ZM15.7773 13.2321C15.8147 12.7907 15.8496 12.3721 15.8802 12H8.13536C8.16978 12.3784 8.20765 12.7932 8.24659 13.214C8.38696 14.7311 8.5552 16.4948 8.67559 17.5694L8.67656 17.578C8.70425 17.8353 8.7831 17.9078 8.78621 17.9107C8.79217 17.9163 8.80423 17.9265 8.83331 17.9378C8.86391 17.9497 8.9273 17.9679 9.03454 17.9705C9.99942 17.9924 10.998 18 12.0262 18C12.9935 18 13.9711 17.9924 14.9685 17.9704L14.9786 17.9702C15.1072 17.968 15.1834 17.9475 15.2203 17.9338C15.2545 17.9211 15.2658 17.9106 15.2685 17.9081C15.2714 17.9054 15.2872 17.8908 15.3077 17.8469C15.3292 17.8009 15.3598 17.7141 15.3758 17.5721C15.4922 16.5064 15.6487 14.7484 15.7773 13.2321Z"/>
                    </svg>
                  </div>

                  <div v-if="modalInfoObj.setting.edit" class="dlg-item-edit" @click="onEdit(modalInfoObj)">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66791 19.1359L17.478 9.03595C17.9025 8.49129 18.0534 7.8616 17.9119 7.22044C17.7893 6.63756 17.4309 6.08335 16.8932 5.66292L15.5821 4.62139C14.4408 3.71364 13.0259 3.80919 12.2147 4.85072L11.3375 5.98876C11.2243 6.13113 11.2526 6.34135 11.3941 6.45601C11.3941 6.45601 13.6107 8.2333 13.6579 8.27152C13.8088 8.41485 13.922 8.60596 13.9503 8.83528C13.9974 9.28438 13.6862 9.70482 13.224 9.76215C13.007 9.79082 12.7995 9.72393 12.6486 9.59971L10.3188 7.74598C10.2056 7.66094 10.0358 7.67909 9.94145 7.79375L4.40457 14.9602C4.04613 15.4093 3.92351 15.9922 4.04613 16.556L4.75357 19.6232C4.7913 19.7857 4.93279 19.9003 5.10257 19.9003L8.2153 19.8621C8.78125 19.8526 9.30947 19.5946 9.66791 19.1359ZM14.0264 18.1807H19.102C19.5972 18.1807 20 18.5887 20 19.0903C20 19.5929 19.5972 20 19.102 20H14.0264C13.5312 20 13.1284 19.5929 13.1284 19.0903C13.1284 18.5887 13.5312 18.1807 14.0264 18.1807Z"/>
                    </svg>
                  </div>

                  <div v-if="modalInfoObj.setting.close" class="dlg-item-close" @click="onClose(modalInfoObj, {})">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6569 7.75735C18.0474 7.36682 18.0474 6.73366 17.6569 6.34313C17.2663 5.95261 16.6332 5.95261 16.2426 6.34313L12.7071 9.87867C12.3166 10.2692 11.6834 10.2692 11.2929 9.87867L7.75736 6.34313C7.36684 5.95261 6.73367 5.95261 6.34315 6.34313C5.95262 6.73366 5.95262 7.36682 6.34315 7.75735L9.87868 11.2929C10.2692 11.6834 10.2692 12.3166 9.87868 12.7071L6.34315 16.2426C5.95262 16.6332 5.95262 17.2663 6.34315 17.6568C6.73367 18.0474 7.36683 18.0474 7.75736 17.6568L11.2929 14.1213C11.6834 13.7308 12.3166 13.7308 12.7071 14.1213L16.2426 17.6568C16.6332 18.0474 17.2663 18.0474 17.6569 17.6568C18.0474 17.2663 18.0474 16.6332 17.6569 16.2426L14.1213 12.7071C13.7308 12.3166 13.7308 11.6834 14.1213 11.2929L17.6569 7.75735Z"/>
                    </svg>
                  </div>
                </div>
                <!-- TODO: ?Оставляем ли здесь клик вне окна? -->
                <!-- TODO: v-click-outside="() => onClose(modalInfoObj, {})" -->
                <!--  modalInfoObj.setting.group === 'sidebar-right'  -->
                <component
                    :is="modalInfoObj.VueComponent"
                    v-click-outside="() => modalInfoObj.setting.group === 'sidebar-right' ? onClose(modalInfoObj, {}) : false"
                    v-bind="modalInfoObj.VueComponentProps"
                    @close="onClose(modalInfoObj, $event)"
                />
              </div>
            </template>
          </transition>

        </template>
      </div>
    </template>

  </div>
</template>

<script>

import {setHandler} from "@plugin/vue-dlg/src/DialogThinClient";
import {getGroupSetting} from "@plugin/vue-dlg/src/DialogGroupSettings";
import vClickOutside from 'click-outside-vue3'

const key = () => `${Date.now()}-${Math.random()}`;

import {shallowRef} from 'vue';

export default {
  name: 'DialogCore',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {

      modalObj: {},
      modalList: [],
      groupSettings: {}, // cache
    };
  },
  methods: {
    // add(VueComponent, VueComponentProps, groupName) {
    add(modalInfoObj) {
      modalInfoObj.id = modalInfoObj.id || key(); // TODO: test;
      modalInfoObj.setting = Object.assign({theme: 'default', group: 'modal'},  modalInfoObj.setting)

      const group = modalInfoObj.setting.group;
      if (!this.groupSettings[group]) {
        this.groupSettings[group] = getGroupSetting(group);
      }

      if (!this.modalObj[group]) {
        this.modalObj[group] = [];
      }

      this.modalObj[group].push(modalInfoObj);
      this.modalList.push(modalInfoObj);

      return modalInfoObj;
    },

    remove(modalInfoObj, resolveDate = {}) {
      let i = this.modalList.indexOf(modalInfoObj);
      if (i >= 0) {
        this.modalList.splice(i, 1);
      }

      const group = modalInfoObj.setting.group;
      
      if (typeof this.modalObj[group] !== 'undefined') {
        let i2 = this.modalObj[group].indexOf(modalInfoObj);
        if (i2 >= 0) {
          if (this.modalObj[group].length === 1) {
            delete this.modalObj[group];
            // TODO: add overlay delete logic
          } else {
            this.modalObj[group].splice(i2, 1);
          }
        }
      }

      modalInfoObj.resolve(resolveDate);
    },

    //
    overlayClick() {
      if (this.modalList.length === 0) {
        return;
      }

      // TODO: исправить для тех, у кого нет overlay
      const modalInfoObj = this.modalList[0];
      this.remove(modalInfoObj);
    },


    // core
    open(VueComponent, VueComponentProps, setting = {}) {
      return new Promise((resolve) => {
        const modalInfoObj = {
          VueComponent: VueComponent,
          VueComponentProps: VueComponentProps,
          setting: setting,
          //
          resolve: resolve,
        };

        this.add(modalInfoObj);
      });
    },

    onClose(modalInfoObj, closeData) {
      this.remove(modalInfoObj, closeData);
    },

    onEdit(modalInfoObj) {
      if (modalInfoObj.VueComponentProps.onActionEdit) {
        modalInfoObj.VueComponentProps.onActionEdit();
      }
    },

    onRemove(modalInfoObj) {
      if (modalInfoObj.VueComponentProps.onActionRemove) {
        modalInfoObj.VueComponentProps.onActionRemove();
      }
    },

    keyUp(e) {
      /*
      if ('Escape' === e.key) {
        if (this.items.length > 0) {
          this.onClose(this.items[this.items.length - 1]);
        }
      }
       */
    }
  },
  computed: {
    overlayDisplay() {
      let overlay = false;
      for (let key in this.modalObj) {
        if (this.groupSettings[key].overlay) {
          overlay = true;
          break;
        }
      }
      return overlay;
    }
  },
  created() {
    // window.addEventListener('keyup', this.keyUp);
    setHandler((element) => {
      // fix vue component
      element.VueComponent = shallowRef(element.VueComponent);
      const modalInfoObj = this.add(element);

      const close = (closeData = {}) => {
        this.remove(modalInfoObj, closeData)
      }
      return {
        close: close,
      }
    });
  }
};

</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.6s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.6s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}


/*
.--dlg {
  align-items: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .dlg-container {
    max-width: 700px;
    width: 100%;
    padding: 0 25px;
  }

}
.--dlg:target,
.--dlg.active {
  display: flex;
  display: -ms-flexbox;
  opacity: 1;
  z-index: 400;
}

.--dlg:target .dlg-container,
.--dlg.active .dlg-container {
  animation: slide-down 0.2s ease 1;
  z-index: 1;
}
.--dlg-container {
  display: flex;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 75vh;
  max-width: 640px;
  width: 100%;
  box-shadow: 0 4px 30px 2px rgb(0 0 0 / 1%);
}
 */

</style>
