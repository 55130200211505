<template>
  <div>
    <template v-if="step === COMMUNITY_CREATE_STEPS.SELECT_COMMUNITY">
      <CommunitySelect
        v-model:selected="selectedContact"
        :communityList="communityList"
        @select="
          setStep(COMMUNITY_CREATE_STEPS.SELECT_COMMUNITY_USER, {
            participants: $event.participants,
            communityName: $event.name,
          })
        "
      />

      <div class="btn-group th-px">
        <button class="btn btn--outline" @click.prevent="close">Discard</button>
        <button class="btn btn--primary" @click.prevent="saveCommunityContact">
          Save
        </button>
      </div>
    </template>

    <template v-if="step === COMMUNITY_CREATE_STEPS.SELECT_COMMUNITY_USER">
      <CommunitySelectContact
        v-model:selected="selectedContact"
        :participants="stepData.participants"
        :communityName="stepData.communityName"
      />

      <div class="btn-group th-px">
        <button
          class="btn btn--outline"
          @click.prevent="setStep(COMMUNITY_CREATE_STEPS.SELECT_COMMUNITY)"
        >
          Back
        </button>
        <button class="btn btn--primary" @click.prevent="saveCommunityContact">
          Save
        </button>
      </div>
    </template>
  </div>
</template>

<script>
const COMMUNITY_CREATE_STEPS = {
  SELECT_COMMUNITY: "SELECT_COMMUNITY",
  SELECT_COMMUNITY_USER: "SELECT_COMMUNITY_USER",
};

import CommunitySelect from "@component/Community/CommunitySelect";
import CommunitySelectContact from "@component/Community/CommunitySelectContact";

export default {
  name: "CommunityEditContact",
  components: {
    CommunitySelectContact,
    CommunitySelect,
  },
  props: {
    communityList: {
      type: Array,
      default: () => [],
    },
    selectedContactObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      COMMUNITY_CREATE_STEPS,
      //
      step: COMMUNITY_CREATE_STEPS.SELECT_COMMUNITY,
      stepData: {},
      //
      selectedContact: Object.assign({}, this.selectedContactObj),
    };
  },
  methods: {
    setStep(stepName, stepData = {}) {
      this.step = stepName;
      this.stepData = stepData;
    },
    close() {
      this.$emit("close");
    },

    // step 2
    saveCommunityContact() {
      this.$emit("submit", {
        participants: Object.values(this.selectedContact),
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
