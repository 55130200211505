<template>
  <router-view/>
</template>


<script>
export default {
  name: 'layout-fullscreen',
  components: {}
};
</script>

<style lang="scss" scoped>
</style>
