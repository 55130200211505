
import * as pageName from '@router/variable';
import LAYOUT from '@layout/layoutName';
import { ROLE_AUTH } from '@permission/roleName';

export default [
  {
    path: '/board',
    name: pageName.BOARD,
    component: () => import('@page/Board/BoardList'),
    meta: {
      layout: LAYOUT.DEFAULT,
      permission: {
        [ROLE_AUTH]: true,
      },
    },
  },
  {
    name: pageName.BOARD_CREATE,
    path: '/board/:id',
    component: () => import('@page/Board/BoardCreate'),
    props: (route) => ({ id: route.params.id }),
    meta: {
      layout: LAYOUT.DEFAULT,
      permission: {
        [ROLE_AUTH]: true,
      },
    },
  },
];
