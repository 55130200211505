<template>
  <section class="v-calendar-schedule__content">
    <!--    <pre>-->
    <!--      {{ events }}-->
    <!--    </pre>-->
    <div class="v-calendar-schedule__weekday-wrapper">
      <div
        class="v-calendar-schedule__weekday-item"
        v-for="weekday in weekdays"
        :key="weekday"
      >
        {{ weekday }}
      </div>
    </div>
    <div class="ps-wrapper">
      <perfect-scrollbar>
        <div
          class="v-calendar-schedule__days"
          v-for="(row, index) in calendar"
          :key="index"
        >
          <div
            :ref="'days.day_' + day.d.format('DDD')"
            class="v-calendar-schedule__day v-cal-day--month"
            @click="!day.isDifferentMonth && dayClicked(day)"
            :class="{
              'is-today': day.isToday,
              'is-past': day.isPast,
              'is-disabled': day.isDisabled,
              'is-different-month': day.isDifferentMonth,
            }"
            v-for="day in row"
            :key="day"
          >
            <div
              class="v-calendar-schedule__day-month"
              :class="{ 'is-today': day.isToday }"
            >
              {{ day.d.format('MMM DD') }}
            </div>
            <div style="height: 130px">
              <perfect-scrollbar style="height: 100%">
                <template v-for="event in day.events" :key="event.id">
                  <event-item
                    @click.stop="$emit('eventClicked', event)"
                    :event="event"
                  />
                </template>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </section>
</template>

<script>
import dayjs from 'dayjs';
import EventItem from '../EventItem';
import IsView from '../mixins/IsView';

export default {
  name: 'month',
  mixins: [IsView],
  components: { EventItem },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      calendar: [],
    };
  },
  mounted() {
    this.buildCalendar();
  },
  methods: {
    dayClicked(day) {
      this.$emit('timeClicked', day.d.toDate());
      // EventBus.$emit('day-clicked', day.d.toDate())
    },
    buildCalendar() {
      this.calendar = [];

      let temp = dayjs(this.activeDate).date(1);
      const monthStart = dayjs(temp);
      let m = temp.month();
      let now = dayjs();
      this.days = [];

      do {
        const day = dayjs(temp);
        let newDay = {
          d: day,
          isPast: temp.isBefore(now, 'day'),
          isToday: temp.isSame(now, 'day'),
          isDisabled: this.isDayDisabled(temp),
          events: this.events
            .filter((e) => dayjs(e.date).isSame(day, 'day'))
            .sort((a, b) => {
              if (!a.startTime) return -1;
              if (!b.startTime) return 1;
              return (
                dayjs(a.startTime, 'HH:mm').format('HH') -
                dayjs(b.startTime, 'HH:mm').format('HH')
              );
            }),
        };
        this.days.push(newDay);
        temp = temp.add(1, 'day');
      } while (temp.month() === m);
      let items = [];
      let paddingOffset = 1;
      // Some padding at the beginning
      for (let p = 0; p < dayjs(this.activeDate).date(1).weekday(); p++) {
        items.unshift({
          d: dayjs(monthStart).subtract(paddingOffset, 'day'),
          isPast: true,
          isToday: false,
          isDifferentMonth: true,
        });
        paddingOffset++;
      }
      // Merge in the array of days
      items.push.apply(items, this.days);
      // Some padding at the end if required
      if (items.length % 7) {
        for (let p = 7 - (items.length % 7); p > 0; p--) {
          items.push({
            d: dayjs(temp),
            isPast: true,
            isToday: false,
            isDifferentMonth: true,
          });
          temp = temp.add(1, 'day');
        }
      }
      // Split the array into "chunks" of seven
      this.calendar = items
        .map(function (e, i) {
          return i % 7 === 0 ? items.slice(i, i + 7) : null;
        })
        .filter(function (e) {
          return e;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ps-wrapper {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 32px;
}

.v-calendar-schedule__content {
  height: 100%;
  font-size: 0.875rem;
  color: var(--color-gray-06);
}

.v-calendar-schedule__weekday-wrapper {
  width: 100%;
  background-color: var(--schedule-background);
  padding: 8px 0;
  text-align: center;
  display: flex;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid var(--schedule-border-color);
}

.v-calendar-schedule__empty {
  min-width: 60px;
}

.v-calendar-schedule__weekday-item {
  width: calc(100% / 7);
  line-height: 1.45;
  font-size: 0.875rem;
  color: var(--color-gray-04);
}

.v-calendar-schedule__days {
  display: flex;
  min-height: calc(100vh / 5);
  max-height: calc(100vh / 5);
}

//.v-cal-weekday-item {
//  width: calc(100% / 7);
//  text-align: center;
//}
//

.v-calendar-schedule__day {
  width: calc(100% / 7);
  padding-right: 16px;
  overflow: hidden;
  background-color: var(--schedule-background);
  border-right: 1px solid var(--schedule-border-color);
  border-bottom: 1px solid var(--schedule-border-color);
  &.is-different-month {
    cursor: not-allowed;
    background-color: var(--schedule-background-no-allowed);
  }
}

.v-calendar-schedule__day-month {
  text-align: center;
  line-height: 1.35;
  padding-top: 2px;
  &.is-today {
    color: var(--color-secondary);
  }
}

.v-calendar-schedule__remove-event {
  display: flex;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background-color: var(--color-gray-03);
  }
}
</style>
