import { ref } from "vue";
import $requestManager from "@service/RequestManager";

export const userList = ref([]);
export const userSelected = ref(null);

export const userSelect = (userObj) => {
  userSelected.value = userObj;
};

// export const userAdd = () => {
//   try {
//     await
//   } catch (error) {

//   }
// }

export const userListIsLoaded = ref(false);

export const userGetList = () => {
  return userList;
};

export const userFetchList = async ({ pattern }) => {
  userListIsLoaded.value = false;
  userList.value = [];
  try {
    userList.value = await $requestManager.User.getAll({ pattern });
  } catch (error) {
  } finally {
    userListIsLoaded.value = true;
  }
};
