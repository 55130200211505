import * as pageName from '@router/variable';
import LAYOUT from '@layout/layoutName';
import { ROLE_AUTH } from '@permission/roleName';

export default [
  {
    path: '/chat',
    name: pageName.CHAT,
    component: () => import('@page/Chat/Chat'),
    meta: {
      layout: LAYOUT.DEFAULT,
      permission: {
        [ROLE_AUTH]: true,
      },
    },
  },
  {
    path: '/chat/personal/:chatId',
    name: pageName.CHAT_PERSONAL,
    component: () => import('@page/Chat/Chat'),
    meta: {
      layout: LAYOUT.DEFAULT,
      permission: {
        [ROLE_AUTH]: true,
      },
    },
    props: (route) => {
      return { chatId: route.params.chatId, chatType: 'PERSONAL' };
    },
  },
  {
    path: '/chat/community/:chatId',
    name: pageName.CHAT_COMMUNITY,
    component: () => import('@page/Chat/Chat'),
    meta: {
      layout: LAYOUT.DEFAULT,
      permission: {
        [ROLE_AUTH]: true,
      },
    },
    props: (route) => {
      return { chatId: route.params.chatId, chatType: 'COMMUNITY' };
    },
  },
];