import { MethodInfo } from '@service/RequestManagerSrc';

export default {
  getNotifications() {
    return new MethodInfo({
      type: 'GET',
      url: 'api://v1/cmm-api/notifications',
    });
  },
  
  getShortNotification() {
    return new MethodInfo({
      type: 'GET',
      url: 'api://v1/cmm-api/short-notifications',
    });
  },

  readShortNotification({part = ''}) {
    return new MethodInfo({
      type: 'GET',
      url: `api://v1/cmm-api/short-notifications/read/${part}`,
    });
  },


};