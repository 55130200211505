// base
export const START        = 'START';
//
export const HOME         = 'HOME';
export const ABOUT        = 'ABOUT';
export const SCHEDULE     = 'SCHEDULE';
export const MEETING      = 'MEETING';

// email
export const EMAIL          = 'EMAIL';
export const EMAIL_CREATE   = 'EMAIL_CREATE';
export const EMAIL_READ     = 'EMAIL_READ';

// chat
export const CHAT           = 'CHAT';
export const CHAT_PERSONAL  = 'CHAT_PERSONAL';
export const CHAT_COMMUNITY = 'CHAT_COMMUNITY';

// board
export const BOARD        = 'BOARD';
export const BOARD_CREATE = 'BOARD_CREATE';

// auth
export const USER_AUTH                      = 'USER_AUTH';
export const USER_REGISTRATION              = 'USER_REGISTRATION';
export const USER_PASSWORD_RECOVERY         = 'USER_PASSWORD_RECOVERY';
export const USER_PASSWORD_RECOVERY_CONFIRM = 'USER_PASSWORD_RECOVERY_CONFIRM';
export const USER_PROFILE_VIEW              = 'USER_PROFILE_VIEW';
export const USER_PROFILE_EDIT              = 'USER_PROFILE_EDIT';

// system
export const NOT_FOUND      = 'NOT_FOUND';
export const NOT_ACCESS     = 'NOT_ACCESS';
