<template>
  <div class="cm">
    <div
      :class="['cm-avatar', { 'cm-avatar-selected': selected }]"
      :style="{ 'background-color': color || getRandomColor(name) }"
    >
      {{ getInitials(name) }}
      <img v-if="imageSrc" :src="imageSrc" alt="" />
    </div>
    <div v-if="selected" class="cm-avatar-selected-icon">
      <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.38 4.325a.5.5 0 00-.76-.65l.76.65zM5.57 8l-.34.366a.5.5 0 00.72-.04L5.571 8zm-2.23-2.766a.5.5 0 00-.682.732l.682-.732zm5.28-1.56l-3.43 4 .76.651 3.429-4-.76-.65zm-2.708 3.96l-2.572-2.4-.682.732 2.571 2.4.683-.732z"/></svg>
    </div>
  </div>
</template>

<script>
import getRandomColor from "@helper/getRandomColor";
export default {
  name: "CMAvatar",
  props: {
    image: {
      type: [String, FileClass], // TODO: fix
      default: '',
    },
    name: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getInitials(name) {
      name = String(name).split(" ");
      let initials = name[0].substring(0, 1).toUpperCase();

      if (name.length > 1) {
        initials += name[1].substring(0, 1).toUpperCase();
      }

      return initials;
    },
  },
  computed: {
    imageSrc() {
      if(!this.image) { return null; }
      if(this.image.getSrc) { return this.image.getSrc();; }
      return this.image;
    }
  },
  setup() {
    return {
      getRandomColor,
    };
  },
};
</script>

<style lang="scss" scoped>
.cm-avatar {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &.cm-avatar-selected {
    position: relative;
    border: 2px solid;
    border-color: var(--color-primary);
  }
  @include random-bg();
  //@include icon-size(1.5);
}
.cm-avatar-selected-icon {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: var(--color-primary);
  bottom: 0px;
  right: 0px;
  z-index: 2;
  border-radius: 100%;

  & > svg {
    display: block;
    fill: var(--color-gray-01);
    width: 100%;
    height: 100%;
  }
}
.cm {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
