<template>
  <div
    class="v-cal-event-item"
    :class="[
      `v-cal-event-item-type__${event.type}`,
      `v-cal-event-item-status__${event.status}`,
    ]"
  >
    <template v-if="event.userData.community">
      <div>
        <span class="v-cal-event-item__community">{{
          event.userData.community.name
        }}</span>
      </div>
    </template>
    <template v-else>
      <div>
        <span class="v-cal-event-item__community">Personal</span>
      </div>
    </template>
    <template v-if="event.userData.period">
      {{ event.name }}, {{ event.userData.period.startDate.format('HH:mm') }} -
      {{ event.userData.period.endDate.format('HH:mm') }}
    </template>
    <template v-else>
      {{ event.name }}, {{ event.startTime.format('HH:mm') }}
      <template v-if="event && event.endTime && event.type === 'meeting'">
        - {{ event.endTime.format('HH:mm') }}
      </template>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'EventItem',
  props: {
    event: {
      type: Object,
      required: true,
    },
    use12: {
      type: Boolean,
      // required: true
      default: false,
    },
    hasDynamicSize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ancestorHeight: 0,
    };
  },
  mounted() {
    this.displayHeight();
  },
  methods: {
    displayHeight() {
      const end =
        this.event.endTime.hour() > 0
          ? dayjs(this.event.endTime)
          : dayjs(this.event.endTime).add(1, 'd');
      const hours = Math.round(end.diff(this.event.startTime, 'h', true));
      const bordersOffset = hours - 1;
      const result = hours * 96 + bordersOffset;
      return result;
    },
  },
  computed: {
    eventClasses() {
      return {
        'is-overlapping': this.event.overlaps > 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-cal-event-item {
  padding: 8px;
  border-radius: 4px;
  color: var(--color-gray-10);
  border: 1px solid #333;
  font-size: 12px;
  width: 100%;
  margin-bottom: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// Event types
.v-cal-event-item-type__meeting {
  border-color: var(--color-secondary);
  &.v-cal-event-item-status__accepted {
    background-color: rgba(86, 107, 214, 0.1);
  }
}

.v-cal-event-item-type__task {
  border-color: var(--color-cyan);
  &.v-cal-event-item-status__accepted {
    background-color: rgba(42, 210, 201, 0.1);
  }
}

.v-cal-event-item-type__reminder {
  border-color: var(--color-yellow-05);
  &.v-cal-event-item-status__accepted {
    background-color: rgba(225, 177, 20, 0.1);
  }
}
//

// Event status
// TODO: Event background to rgba

.v-cal-event-item-status__declined {
  background-color: var(--color-gray-02);
  text-decoration: line-through;
  border-color: var(--color-gray-04);
  color: var(--color-gray-04);
}

.v-cal-event-item-status__invited {
  background-color: #fff;
  .theme-dark & {
    background: transparent;
  }
}
//

.v-cal-event-item__community {
  color: #2c2c3b;
  padding: 3px 4px;
  border-radius: 4px;
  background-color: #f8f9fa;
}
</style>
