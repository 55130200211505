import { MethodInfo } from '@service/RequestManagerSrc';

/*
Не работает получение списка, без owner page size

 */

export default {
  // TODO: use standart intarface for all pagination page
  getAll(/*{ offset, limit }*/) {
    return new MethodInfo({
      type: 'GET',
      url: 'api://v1/cmm-api/board',
      get: {
        // TODO: fix
        owner: false,
        page: 0,
        size: 100,
      },
      responsePrepare: (list) => {
        // fix backend method pagination
        return {
          list: list.map((item) => {
            
            return {
              ...item,
              createDate: DateTime(item.createDate),
              lastUpdate: DateTime(item.lastUpdate),
            };
          }),
        };
      },
    });
  },
  
  add({name, description = '', communityId = null}) {
    return new MethodInfo({
      type: 'POST',
      url: 'api://v1/cmm-api/board',
      post: {
        // id: null,
        name: name,
        description: description,
        communityId: communityId,
      },
    });
  },
  delete({ id }) {
    // TODO: Test DELETE api://v1/cmm-api/board/${id}/elements
    return new MethodInfo({
      type: 'DELETE',
      url: `api://v1/cmm-api/board/${id}`,
    });
  },
  

  getById({ id }) {
    return new MethodInfo({
      type: 'GET',
      url: `api://v1/cmm-api/board/${id}`,
      get: {
        boardId: id,
      },
    });
  },
  
  updateById({ id, name, description }) {
    return new MethodInfo({
      type: 'PUT',
      url: `api://v1/cmm-api/board/${id}`,
      post: {
        name: name,
        description: description,
      },
    });
  },
  
  //
  getElementListById({ id }) {
    return new MethodInfo({
      type: 'GET',
      url: `api://v1/cmm-api/board/${id}/elements`,
    });
  },
  updateElementListById({ id, elementList }) {
    return new MethodInfo({
      type: 'PUT',
      url: `api://v1/cmm-api/board/${id}/elements`,
      post: elementList,
    });
  },
  overwriteElementListById({ id, elementList }) {
    return new MethodInfo({
      type: 'POST',
      url: `api://v1/cmm-api/board/${id}/elements/refresh`,
      post: elementList,
    });
  },

  //
  addUserById({ id, userIdList }) {
    return new MethodInfo({
      type: 'POST',
      url: `api://v1/cmm-api/board/${id}/addUsers`,
      post: {usersId: userIdList},
    });
  },
  deleteUserById({ id, userIdList }) {
    return new MethodInfo({
      type: 'POST',
      url: `api://v1/cmm-api/board/${id}/deleteUsers`,
      post: {usersId: userIdList},
    });
  },
};
