<template>
  <transition name="slide-fade">
    <div class="sidebar-notification" v-if="isShown" style="display: flex; flex-direction: column">
      <div class="sidebar-notification__header">
        <span class="sidebar-notification__title">Notifications</span>

        <div class="notification-panel__action-list">

          <div class="notification-panel__action" :class="{ 'active': isFiltersActive }" @click="toggleFilters">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.449 17.426L10.105 19v-5h3.344v3.426zM14.733 12H8.924L5.278 7.98A1.08 1.08 0 015 7.253v-1.19C5 5.474 5.46 5 6.03 5h11.94c.57 0 1.03.475 1.03 1.062v1.164c0 .287-.11.56-.308.76L14.732 12z"/></svg>
          </div>

          <div class="notification-panel__action" @click="closeNotifications">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.657 7.757a1 1 0 00-1.414-1.414L12.707 9.88a1 1 0 01-1.414 0L7.757 6.343a1 1 0 00-1.414 1.414l3.536 3.536a1 1 0 010 1.414l-3.536 3.536a1 1 0 101.414 1.414l3.536-3.536a1 1 0 011.414 0l3.536 3.536a1 1 0 101.414-1.414l-3.536-3.536a1 1 0 010-1.414l3.536-3.536z"/></svg>
          </div>

        </div>
      </div>

      <div class="notification-panel__filters" v-if="isFiltersActive">
        <div class="notification-panel__filters-header">
          <span class="filters-discard">Discard</span>
          <span class="filters-save">Save as default</span>
        </div>
        <div class="notification-panel__filters-body">
          <div class="filter-item">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 6h-4c-1.47 0-2.373.004-3.025.092-.574.077-.67.187-.681.2a.025.025 0 01-.002.002c-.013.011-.123.107-.2.68C6.004 7.628 6 8.53 6 10v4c0 1.47.004 2.373.092 3.025.077.574.187.67.2.681v.001l.002.001c.011.013.107.123.68.2C7.628 17.996 8.53 18 10 18h4c1.47 0 2.373-.004 3.025-.092.574-.077.67-.187.681-.2h.001l.001-.002c.013-.011.123-.107.2-.68.088-.653.092-1.555.092-3.026v-4c0-1.47-.004-2.373-.092-3.025-.077-.574-.187-.67-.2-.681v-.001l-.002-.001c-.011-.013-.107-.123-.68-.2C16.372 6.004 15.47 6 14 6zM4.879 4.879C4 5.757 4 7.172 4 10v4c0 2.828 0 4.243.879 5.121C5.757 20 7.172 20 10 20h4c2.828 0 4.243 0 5.121-.879C20 18.243 20 16.828 20 14v-4c0-2.828 0-4.243-.879-5.121C18.243 4 16.828 4 14 4h-4c-2.828 0-4.243 0-5.121.879z"/></svg>
            <span class="filter-item__title">Emails</span>
          </div>
          <div class="filter-item">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 6h-4c-1.47 0-2.373.004-3.025.092-.574.077-.67.187-.681.2a.025.025 0 01-.002.002c-.013.011-.123.107-.2.68C6.004 7.628 6 8.53 6 10v4c0 1.47.004 2.373.092 3.025.077.574.187.67.2.681v.001l.002.001c.011.013.107.123.68.2C7.628 17.996 8.53 18 10 18h4c1.47 0 2.373-.004 3.025-.092.574-.077.67-.187.681-.2h.001l.001-.002c.013-.011.123-.107.2-.68.088-.653.092-1.555.092-3.026v-4c0-1.47-.004-2.373-.092-3.025-.077-.574-.187-.67-.2-.681v-.001l-.002-.001c-.011-.013-.107-.123-.68-.2C16.372 6.004 15.47 6 14 6zM4.879 4.879C4 5.757 4 7.172 4 10v4c0 2.828 0 4.243.879 5.121C5.757 20 7.172 20 10 20h4c2.828 0 4.243 0 5.121-.879C20 18.243 20 16.828 20 14v-4c0-2.828 0-4.243-.879-5.121C18.243 4 16.828 4 14 4h-4c-2.828 0-4.243 0-5.121.879z"/></svg>
            <span class="filter-item__title">Chat messages</span>
          </div>
          <div class="filter-item">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 6h-4c-1.47 0-2.373.004-3.025.092-.574.077-.67.187-.681.2a.025.025 0 01-.002.002c-.013.011-.123.107-.2.68C6.004 7.628 6 8.53 6 10v4c0 1.47.004 2.373.092 3.025.077.574.187.67.2.681v.001l.002.001c.011.013.107.123.68.2C7.628 17.996 8.53 18 10 18h4c1.47 0 2.373-.004 3.025-.092.574-.077.67-.187.681-.2h.001l.001-.002c.013-.011.123-.107.2-.68.088-.653.092-1.555.092-3.026v-4c0-1.47-.004-2.373-.092-3.025-.077-.574-.187-.67-.2-.681v-.001l-.002-.001c-.011-.013-.107-.123-.68-.2C16.372 6.004 15.47 6 14 6zM4.879 4.879C4 5.757 4 7.172 4 10v4c0 2.828 0 4.243.879 5.121C5.757 20 7.172 20 10 20h4c2.828 0 4.243 0 5.121-.879C20 18.243 20 16.828 20 14v-4c0-2.828 0-4.243-.879-5.121C18.243 4 16.828 4 14 4h-4c-2.828 0-4.243 0-5.121.879z"/></svg>
            <span class="filter-item__title">Boards</span>
          </div>
          <div class="filter-item">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 6h-4c-1.47 0-2.373.004-3.025.092-.574.077-.67.187-.681.2a.025.025 0 01-.002.002c-.013.011-.123.107-.2.68C6.004 7.628 6 8.53 6 10v4c0 1.47.004 2.373.092 3.025.077.574.187.67.2.681v.001l.002.001c.011.013.107.123.68.2C7.628 17.996 8.53 18 10 18h4c1.47 0 2.373-.004 3.025-.092.574-.077.67-.187.681-.2h.001l.001-.002c.013-.011.123-.107.2-.68.088-.653.092-1.555.092-3.026v-4c0-1.47-.004-2.373-.092-3.025-.077-.574-.187-.67-.2-.681v-.001l-.002-.001c-.011-.013-.107-.123-.68-.2C16.372 6.004 15.47 6 14 6zM4.879 4.879C4 5.757 4 7.172 4 10v4c0 2.828 0 4.243.879 5.121C5.757 20 7.172 20 10 20h4c2.828 0 4.243 0 5.121-.879C20 18.243 20 16.828 20 14v-4c0-2.828 0-4.243-.879-5.121C18.243 4 16.828 4 14 4h-4c-2.828 0-4.243 0-5.121.879z"/></svg>
            <span class="filter-item__title">Reminders</span>
          </div>
          <div class="filter-item">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 6h-4c-1.47 0-2.373.004-3.025.092-.574.077-.67.187-.681.2a.025.025 0 01-.002.002c-.013.011-.123.107-.2.68C6.004 7.628 6 8.53 6 10v4c0 1.47.004 2.373.092 3.025.077.574.187.67.2.681v.001l.002.001c.011.013.107.123.68.2C7.628 17.996 8.53 18 10 18h4c1.47 0 2.373-.004 3.025-.092.574-.077.67-.187.681-.2h.001l.001-.002c.013-.011.123-.107.2-.68.088-.653.092-1.555.092-3.026v-4c0-1.47-.004-2.373-.092-3.025-.077-.574-.187-.67-.2-.681v-.001l-.002-.001c-.011-.013-.107-.123-.68-.2C16.372 6.004 15.47 6 14 6zM4.879 4.879C4 5.757 4 7.172 4 10v4c0 2.828 0 4.243.879 5.121C5.757 20 7.172 20 10 20h4c2.828 0 4.243 0 5.121-.879C20 18.243 20 16.828 20 14v-4c0-2.828 0-4.243-.879-5.121C18.243 4 16.828 4 14 4h-4c-2.828 0-4.243 0-5.121.879z"/></svg>
            <span class="filter-item__title">Tasks</span>
          </div>
          <div class="filter-item">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 6h-4c-1.47 0-2.373.004-3.025.092-.574.077-.67.187-.681.2a.025.025 0 01-.002.002c-.013.011-.123.107-.2.68C6.004 7.628 6 8.53 6 10v4c0 1.47.004 2.373.092 3.025.077.574.187.67.2.681v.001l.002.001c.011.013.107.123.68.2C7.628 17.996 8.53 18 10 18h4c1.47 0 2.373-.004 3.025-.092.574-.077.67-.187.681-.2h.001l.001-.002c.013-.011.123-.107.2-.68.088-.653.092-1.555.092-3.026v-4c0-1.47-.004-2.373-.092-3.025-.077-.574-.187-.67-.2-.681v-.001l-.002-.001c-.011-.013-.107-.123-.68-.2C16.372 6.004 15.47 6 14 6zM4.879 4.879C4 5.757 4 7.172 4 10v4c0 2.828 0 4.243.879 5.121C5.757 20 7.172 20 10 20h4c2.828 0 4.243 0 5.121-.879C20 18.243 20 16.828 20 14v-4c0-2.828 0-4.243-.879-5.121C18.243 4 16.828 4 14 4h-4c-2.828 0-4.243 0-5.121.879z"/></svg>
            <span class="filter-item__title">Meetings</span>
          </div>
        </div>
      </div>

      
      
      <div class="ps-wrapper">
        <perfect-scrollbar>
          <template v-for="date in notifyGroupList.notificationDate" :key="date">
            <NotificationGroup :date="date" :notifications="notifyGroupList.notificationObj[date]"/>
          </template>
        </perfect-scrollbar>
      </div>
    </div>
  </transition>
</template>

<script>

import NotificationGroup from '@component/Notification/NotificationGroup';
import { notificationList } from "@store/test/testNotification";
import { prepareNotificationData } from "@store/notification.store";

export default {
  name: 'NotificationPanel',
  components: {
    NotificationGroup,
  },
  props: {
    isShown: {
      type: Boolean,
      default: false
    }
  },
  watch: {},
  data() {
    return {
      isFiltersActive: false,
    };
  },
  computed: {
    notifyGroupList() {
      return prepareNotificationData(notificationList.value);
    }
  },
  methods: {
    closeNotifications() {
      this.$emit('closeNotifications');
    },

    toggleFilters() {
      this.isFiltersActive = !this.isFiltersActive;
    },
  
    // fetchNotifications() {
    //   RequestManager.Notification.getNotifications().then((res) => {
    //     this.notificationList = res;
    //   });
    // },
  }
};
</script>

<style lang="scss" scoped>

.notification-panel__action-list {
  display: flex;
  align-items: center;
}

.notification-panel__action {
  width: 28px;
  height: 28px;
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
    fill: var(--color-gray-04);
  }

  &:hover > svg {
    fill: var(--color-gray-04-hover);
  }

  &:first-of-type {
    margin-right: 10px;
  }

  &.active > svg {
    fill: var(--color-secondary);
  }

}

.sidebar-notification {
  height: 100%;
  background-color: var(--sidebar-notification-background);
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 365px;
  width: 100%;
  box-shadow: -4px 0px 12px rgba(233, 233, 243, 0.4), -1px 0px 0px #E9E9F3;
  border-left: 1px solid var(--common-border-color);

  .sidebar-notification__header {
    padding: 22px 24px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--common-border-color);
  }

  .sidebar-notification__title {
    margin: 2px 0;
    display: inline-block;
    line-height: 1.5;
    font-size: 16px;
  }

  .notification-panel__filters {
    padding: 16px 24px;
    background-color: var(--color-gray-02);
    border-bottom: 1px solid var(--common-border-color);
  }

  .notification-panel__filters-header {
    font-size: 14px;
    line-height: 1.5;
    color: var(--color-gray-05);
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  .notification-panel__filters-body {
    display: flex;
    flex-direction: column;
  }

}

.filters-discard {
  color: var(--color-gray-05);
  line-height: 1.5;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.filters-save {
  color: var(--color-secondary);
  text-decoration: underline;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
}

.filter-item {
  padding: 0 0 0 20px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  color: var(--color-gray-07);

  &:last-of-type {
    margin-bottom: 0;
  }

  & > svg {
    fill: var(--color-gray-04);
  }

}

.filter-item__title {
  display: inline-block;
  margin-left: 8px;
  color: var(--color-gray-07);
  font-size: 14px;
  line-height: 1.45;
}

.visible {
  display: flex !important;
}

.ps-wrapper {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden
}


</style>
