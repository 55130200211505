
// import RequestClass from "js-request-manager/src/Class/RequestClass";
import {MethodInfo} from '@service/RequestManagerSrc';

const prepareUserData = (data) => {
  return {
    id                   : data.id                   || '',
    //
    companyName          : data.companyName          || '',
    countryOfResidence   : data.countryOfResidence   || '',
    hobbiesAndInterests  : data.hobbiesAndInterests  || '',
    // TODO: day js
    birthday             : data.birthday ? DateTime(data.birthday) : null,
    communitiesEntity    : data.communitiesEntity    || [],
    familyStatus         : data.familyStatus         || '',
    firstName            : data.firstName            || '',
    gender               : data.gender               || '',
    lastName             : data.lastName             || '',
    login                : data.login                || '',
    mail                 : data.mail                 || '',
    additionalMail       : data.additionalMail       || '',
    // password             : data.password          || '',
    // TODO: fix id and empty image
    profilePhoto         : data.profilePhoto ? new FileClass({src: (data.profilePhoto || ''), id: 'backendImage' }) : null,
  };
};

export default {

  /**
   * pm.environment.set("authorization", jsonData.access_token);
   */
  registrationStep1GetKey: () => {
    return new MethodInfo({
      name  : 'registrationStep1GetKey',
      type  : 'POST',
      url   : 'auth://auth/realms/sparklingtide/protocol/openid-connect/token',
      provider: 'KEYCLOAK',
      post: {
        client_id : 'chat-mail-localhost',
        username  : 'user-management',
        password  : 'a76b13ad-5662-4c69-aef0-6976eaf62225',
        grant_type: 'password',
      },
      // errorMessage: 'Не удалось получить информацию',
    });
  },

  /**
{
  "firstName": "Leonardo",
  "lastName": "Dicaprio",
  "mail": "leo@mail.ru",
  "login": "leonardo.dicaprio",
  "password": "password",
  "birthday": "01.01.1985",
  "gender": "male",
  "familyStatus": "married",
  "hobbiesAndInterests": "n/a",
  "companyName": "Golden Mayer",
  "countryOfResidence": "United States",
  "profilePhoto": "/images/dicaprio"
}
   */
  registration: ({ user, token }) => {
    return new MethodInfo({
      name  : 'registration',
      type  : 'POST',
      url   : 'api://v1/cmm-api/user',
      params: {
        get: {},
        post: user,
      },
      header: {
        Authorization: `Bearer ${token}`,
      }
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
      // errorMessage: '',
    });
  },

  authorization: ({login, password }) => {
    return new MethodInfo({
      name  : 'authorization',
      type  : 'POST',
      url   : 'auth://auth/realms/sparklingtide/protocol/openid-connect/token',
      provider: 'KEYCLOAK',
      params: {
        get: {},
        post: {
          client_id : 'chat-mail-localhost',
          username  : login,
          password  : password,
          grant_type: 'password',
        },
      },
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
      // errorMessage: '',
    });
  },

  passwordRecovery: ({ email }) =>  {
    return new MethodInfo({
      name  : 'password_recovery',
      type  : 'POST',
      url   : 'auth://password_recovery',
      params: {
        get: {},
        post: { email },
      },
      responsePrepare: (data) => {
        return {token: data.jwt};
      },
      // errorMessage: '',
    });
  },

  // TODO: fix
  getInfo: ({ userId, token }) => {
    return new MethodInfo({
      name  : 'registration',
      type  : 'GET',
      url   : 'api://v1/cmm-api/user/' + userId,
      params: {
        get: {},
        post: {},
      },
      header: {
        Authorization: `Bearer ${token}`,
      },
      responsePrepare: prepareUserData
    });
  },

  updateProfileInfo: ({ userId, userProfileInfo }) => {
    return new MethodInfo({
      name  : 'updateProfileInfo',
      type  : 'PUT',
      url   : 'api://v1/cmm-api/user/' + userId,
      post: userProfileInfo,
      responsePrepare: prepareUserData
    });
  },


  changeProfilePassword: ({ oldPassword, newPassword }) => {
    const userId = USER.Auth.getUserId();
    return new MethodInfo({
      name  : 'updateProfileInfo',
      type  : 'POST',
      url   : 'api://v1/cmm-api/user/resetPassword',
      post: {
        id: userId,
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      // responsePrepare: (data) => {}
    });
  },
  
  
  passwordResetFix: ({ mail }) => {
    return new MethodInfo({
      name  : 'sendPassword',
      type  : 'POST',
      url   : 'api://v1/cmm-api/mail/sendPassword',
      params: {
        get: {},
        post: { mail: mail },
      },
    });
  },
  
};
