
// TODO: fix
// import RequestManager from 'js-request-manager';
import {RequestManager} from '@service/RequestManagerSrc';

// RequestSchema
import RequestSchema  from "./RequestSchema";
import Settings       from "./Settings";


const rm = RequestManager(RequestSchema,Settings);

export default rm;