<template>
  <div class="board-nav">
    <router-link
      v-if="$route.name === $routeName.BOARD_CREATE"
      :to="{ name: $routeName.BOARD }"
      class="board-nav__icon board-nav__back"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.707 4.293a1 1 0 010 1.414L9.414 12l6.293 6.293a1 1 0 01-1.414 1.414l-7-7a1 1 0 010-1.414l7-7a1 1 0 011.414 0z"/></svg>
    </router-link>
    
    <template v-else>
      <div style="cursor: pointer;" @click="createAndOpenNewBoard">
        <div class="board-nav__icon board-nav__add">
          <!-- prettier-ignore -->
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 5a1 1 0 10-2 0v5a1 1 0 01-1 1H5a1 1 0 100 2h5a1 1 0 011 1v5a1 1 0 102 0v-5a1 1 0 011-1h5a1 1 0 100-2h-5a1 1 0 01-1-1V5z"/></svg>
        </div>
      </div>
    </template>

    <template v-if="false && $route.name === $routeName.BOARD_CREATE">
      <div class="board-nav__header">
        <p class="board-nav__name">Meeting notes</p>
        <p class="board-nav__date">12/12/21 17:00</p>
      </div>
      <div class="board-nav__btn-group">
        <button class="btn btn--outline">Share</button>
        <button class="btn btn--primary">Save</button>
      </div>
    </template>
  </div>
</template>

<script>
import {communityGetSelected} from '@store/community.store';

export default {
  name: '',
  methods: {
    createAndOpenNewBoard() {
  
      const newBoard = { name: 'No name'}
      
      const communityObj = communityGetSelected();
      if(communityObj.value && communityObj.value.id) {
        newBoard.communityId = communityObj.value.id;
      }
      
      RequestManager.Board.add(newBoard).then(
          (board) => {
  
            if(communityObj.value && communityObj.value.id && communityObj.value.participants && communityObj.value.participants.length) {
              RequestManager.Board.addUserById({
                id: board.id,
                userIdList: communityObj.value.participants.map((item) => (item.id)),
              });
            }
            
            this.$router.push({ name: this.$routeName.BOARD_CREATE, params: { id: board.id }});
          },
          (boardCreateError) => {
            this.$dialog.notification('Failed to create a board');
            console.error(boardCreateError);
          }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.board-nav {
  display: flex;
  align-items: center;
  flex: 1;
}

.board-nav__date {
  color: #9696ab;
  font-size: 0.75rem;
}
.board-nav__icon {
  @include icon-size(2);
  margin-right: 14px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.board-nav__add {
  background: rgba(251, 112, 53, 0.4);
  svg {
    fill: var(--color-primary);
  }
}
.board-nav__back {
  background: var(--navback-background);
  svg {
    fill: var(--navback-color);
  }
}

.board-nav__btn-group {
  margin-left: auto;
  .btn {
    margin: 0 6px;
  }
}
</style>
