<template>
  <div>
    <template v-if="step === COMMUNITY_CREATE_STEPS.CREATE_COMMUNITY">
      <div class="th-px" style="margin: 0 0 24px">
        <CommunityCreateForm
          ref="communityCreateForm"
          @submit="submitCommunityCreateForm"
          :formData="communityData"
        />
      </div>
      <div class="btn-group th-px">
        <button class="btn btn--outline" @click.prevent="close">Discard</button>
        <button class="btn btn--primary" @click.prevent="submitCommunityCreate">
          Next
        </button>
      </div>
    </template>

    <template v-if="step === COMMUNITY_CREATE_STEPS.SELECT_COMMUNITY">
      <CommunitySelect
        v-model:selected="selectedContact"
        :communityList="communityList"
        @select="
          setStep(COMMUNITY_CREATE_STEPS.SELECT_COMMUNITY_USER, {
            participants: $event.participants,
            communityName: $event.name,
          })
        "
      />

      <div class="btn-group th-px">
        <button class="btn btn--outline" @click.prevent="close">Discard</button>
        <button class="btn btn--primary" @click.prevent="saveCommunity">
          Save
        </button>
      </div>
    </template>

    <template v-if="step === COMMUNITY_CREATE_STEPS.SELECT_COMMUNITY_USER">
      <CommunitySelectContact
        v-model:selected="selectedContact"
        :communityName="stepData.communityName"
        :participants="stepData.participants"
      />

      <div class="btn-group th-px">
        <button
          class="btn btn--outline"
          @click.prevent="setStep(COMMUNITY_CREATE_STEPS.SELECT_COMMUNITY)"
        >
          Back
        </button>
        <button class="btn btn--primary" @click.prevent="saveCommunity">
          Save
        </button>
      </div>
    </template>
  </div>
</template>

<script>
const COMMUNITY_CREATE_STEPS = {
  CREATE_COMMUNITY: "CREATE_COMMUNITY",
  SELECT_COMMUNITY: "SELECT_COMMUNITY",
  SELECT_COMMUNITY_USER: "SELECT_COMMUNITY_USER",
};

import CommunityCreateForm from "@component/Community/CommunityCreateForm";
import CommunitySelect from "@component/Community/CommunitySelect";
import CommunitySelectContact from "@component/Community/CommunitySelectContact";

export default {
  name: "CommunityCreate",
  components: {
    CommunitySelectContact,
    CommunitySelect,
    CommunityCreateForm,
  },
  props: {
    communityList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      COMMUNITY_CREATE_STEPS,
      //
      step: COMMUNITY_CREATE_STEPS.CREATE_COMMUNITY,
      stepData: {},
      //
      communityData: {
        // name: '123'
        // image: new FileClass({ id:1, src: ''}),
      },
      selectedContact: {},
    };
  },
  methods: {
    setStep(stepName, stepData = {}) {
      if (stepName === COMMUNITY_CREATE_STEPS.CREATE_COMMUNITY) {
        this.selectedContact = {};
      }
      this.step = stepName;
      this.stepData = stepData;
    },
    close() {
      this.$emit("close");
    },
    // step 1
    submitCommunityCreate() {
      this.$refs.communityCreateForm.submit();
    },
    submitCommunityCreateForm(data) {
      this.communityData = data;
      this.setStep(COMMUNITY_CREATE_STEPS.SELECT_COMMUNITY);
    },

    // step 2
    saveCommunity() {
      this.$emit("save", {
        community: this.communityData,
        participants: Object.values(this.selectedContact),
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
