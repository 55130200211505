<template>
  <div class="sidebar">
    <div class="sidebar-main-wrapper">
      <PopperDropdown :distance="0" class="fix-sidebar-header">
        <div class="sidebar-header">
          <!-- prettier-ignore -->
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sidebar-icon-menu'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 6a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zm0 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zm16-6a1 1 0 00-1-1H5a1 1 0 100 2h14a1 1 0 001-1z"/></svg>
        </div>
        <template #popper>
          <SidebarMenuBurger
              @contactAdd="openModalContactAdd"
              @communityAdd="openModalCommunityCreate"
              @toggleTheme="toggleTheme"
          />
        </template>
      </PopperDropdown>
      <div class="sidebar-content">
        <SidebarCommunity
          :communityList="communityList"
          :communityActive="communityActive"
          @communitySelect="communitySelect"
          @communityAdd="openModalCommunityCreate"
          @communityEdit="openModalCommunityEdit"
          @communityContactEdit="openModalCommunityContactEdit"
          @communityDelete="openModalCommunityDelete"
          @communityLeave="openModalCommunityLeave"
        />
      </div>
      <div class="sidebar-footer">
        <div class="sidebar-icon-file-storage__wrapper">
          <PopperDropdown
            placement="left-end"
            :distance="10"
            :autoHide="fileStorageAutoHide"
            @hide="fileStorageClearFilterState"
            v-model:shown="fileStorageShown"
          >
            <!-- prettier-ignore -->
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sidebar-icon-file-storage'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z"/></svg>
            <template #popper="{ hide }">
              <FileStorage @close="hide" class="sidebar__file-storage" />
            </template>
          </PopperDropdown>
        </div>
        <div @click="isCollapsed = !isCollapsed">
          <transition name="slide-fade" mode="out-in">
            <div v-if="isCollapsed" key="collapsed">
              <!-- prettier-ignore -->
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sidebar-icon-collapse'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.293 19.707a1 1 0 010-1.414L14.586 12 8.293 5.707a1 1 0 011.414-1.414l7 7a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0z"/></svg>
            </div>
            <div v-else key="expanded">
              <!-- prettier-ignore -->
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sidebar-icon-collapse'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.707 4.293a1 1 0 010 1.414L9.414 12l6.293 6.293a1 1 0 01-1.414 1.414l-7-7a1 1 0 010-1.414l7-7a1 1 0 011.414 0z"/></svg>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <transition name="sidebar-additional">
      <div v-if="!isCollapsed" class="sidebar-additional-wrapper">
        <div class="sidebar-additional-inner">
          <div class="sidebar-search-wrapper">
            <SidebarSearch v-model:value="searchValue" />
          </div>
          <template v-if="isSearchResultShown">
            <SidebarSearchResult
              :participants="userList"
              @userView="openModalUserView"
              @userAddToContact="contactAdd"


              @contactView="openModalContactView"
              @contactDelete="openModalContactDelete"
              @contactDeleteFromCommunity="openModalContactDeleteFromCommunity"
              @contactEdit="openModalContactEdit"
              @startChat="startChat"
              @emailSend="emailSend"
            />
          </template>
          <template v-else>
            <template v-if="componentName !== 'schedule'">
              <SidebarCommunityContact
                :communityActive="communityActive"
                :participants="
                  communityActive ? communityActive.participants : []
                "
                :communityOwner="1"
                :contactActive="contactActive"
                @selected="contactSelect"
                @contactView="openModalContactView"
                @contactDelete="openModalContactDelete"
                @contactDeleteFromCommunity="openModalContactDeleteFromCommunity"
                @contactEdit="openModalContactEdit"
                @startChat="startChat"
                @emailSend="emailSend"
              />
            </template>

            <template v-if="componentName === 'schedule'">
              <SidebarSchedule />
            </template>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SidebarCommunity from './SidebarCommunity';
import SidebarSearch from './SidebarSearch';
import SidebarSearchResult from './SidebarSearchResult';
import SidebarCommunityContact from './SidebarCommunityContact';
import SidebarCommunityContactChat from './SidebarCommunityContactChat';
import SidebarMenuBurger from './SidebarMenuBurger';
import PopperDropdown from '@component/Popper/PopperDropdown';
import { hideAllPoppers } from 'floating-vue';

import {
  communityFetchList,
  communityGetList,
  communityGetSelected,
  communitySelect,
  communityAdd,
  communityUpdateInfo,
  communityUpdateContactList,
  communityDelete,
  communityLeave, contactIsAddToFriends, communitySelectPersonal,
  //
} from '@store/community.store';

import {
  contactGetSelected,
  contactSelect,
  contactDeleteFromCommunity,
  contactDelete,
  contactAdd,
  contactUpdate,
} from '@store/communityContact.store';

import { debounce } from 'lodash';
import CommunityCreate from '@component/Community/CommunityCreate';
import CommunityEdit from '@component/Community/CommunityEdit';
import CommunityEditContact from '@component/Community/CommunityEditContact';
//
import ContactAdd from '@component/Contact/ContactAdd';
import ContactEdit from '@component/Contact/ContactEdit';
import ContactProfile from '@component/Contact/ContactProfile';

import FileStorage from '@component/FileStorage/FileStorage';
import {
  fileStorageAutoHide,
  fileStorageFilterState,
  fileStorageClearFilterState,
  fileStorageShown,
} from '@store/fileStorage.store';

import { userList, userGetList, userFetchList } from "@store/user.store";
import SidebarSchedule from "@component/Part/Sidebar/SidebarSchedule";
import {getTheme, setTheme} from "@store/theme.store";

export default {
  name: 'Sidebar',
  components: {
    SidebarSchedule,
    SidebarCommunity,
    SidebarCommunityContact,
    SidebarCommunityContactChat,
    SidebarSearch,
    SidebarSearchResult,
    SidebarMenuBurger,
    PopperDropdown,
    FileStorage,
  },
  data() {
    return {
      fileStorageShown,
      userList, // userGetList(),
      searchValue: '',
      // isCollapsed: true,
      isCollapsed: false,
    };
  },
  computed: {
    isSearchResultShown() {
      return this.searchValue.length >= 3;
    },
    componentName() {
      const arr = this.$route.fullPath.split('/');
      const str = arr[1] ? arr[1] : 'default';
      const arr1 = str.split('?');
      return arr1[0];
    },
  },
  watch: {
    //TODO: При изменинии роута скрывать все попперы
    '$route.path'() {
      hideAllPoppers();
    },
    searchValue: debounce(async function (currentValue) {
      if (this.isSearchResultShown) {
        await userFetchList({ pattern: currentValue });
      }
    }, 500),
  },
  // TODO: ? Может быть стоит сделать через setup?
  methods: {
    emailSend(contactObj) {
      this.$router.push({
        name: this.$routeName.EMAIL_CREATE,
        query: { email: contactObj.mail },
      });
    },

    startChat(contactObj) {
      const communitySelected = communityGetSelected();
      
      //  
      if (communitySelected.value.id !== null) {
        if(!contactIsAddToFriends(contactObj)){
          this.$dialog.notify.warning('Attention', 'This contact has not been added to friends!');
          return;
        }
        // console.log(communityList, contactObj);
        communitySelectPersonal();
        this.$router.push({
          name: this.$routeName.CHAT_PERSONAL,
          params: { chatId: contactObj.id },
        });
      }
      
      if (communitySelected.value.id === null) {
        contactSelect(contactObj);
        this.$router.push({
          name: this.$routeName.CHAT_PERSONAL,
          params: { chatId: contactObj.id },
        });
      }
    },

    communitySelect(communityObj) {
      if (this.componentName === 'chat') {
        if (communityObj.id !== null) {
          this.$router.push({
            name: this.$routeName.CHAT_COMMUNITY,
            params: { chatId: communityObj.id },
          });
        } else {
          this.$router.push({ name: this.$routeName.CHAT });
        }
      }
      communitySelect(communityObj);
    },
  
    toggleTheme() {
      if (getTheme() === 'dark') {
        setTheme('light');
      } else {
        setTheme('dark');
      }
    },
    
    contactAdd(contactObj) {
      contactAdd(contactObj);
    },
    async contactSelect(contactObj) {
      const communitySelected = communityGetSelected();

      // Снятие выделение с переходом на общий экран чатов
      if (
        this.componentName === 'chat' &&
        communitySelected.value.id === null
      ) {
        if (contactObj === null) {
          this.$router.push({ name: this.$routeName.CHAT });
        } else {
          this.$router.push({
            name: this.$routeName.CHAT_PERSONAL,
            params: { chatId: contactObj.id },
          });
        }
      }
      contactSelect(contactObj);
    },
    openModalContactDeleteFromCommunity(contactObj) {
      let modal = this.$dialog.prompt.delete(
        'Delete member from community?',
        'Are you sure you want to delete?'
      );
      modal.then((res) => {
        if (res && res.action === 'OK') {
          contactDeleteFromCommunity(contactObj);
        }
        modal = null;
      });
    },
    openModalUserView: async function (userObj) {
      try {
        let modal = this.$dialog.open(
          ContactProfile,
          {
            formData: userObj,
            editable: false,
          },
          { group: 'sidebar-right', theme: 'contact-sidebar', close: true }
        );
      } catch (error) {}
    },
    openModalContactView: async function (contactObj) {
      try {
        const userObj = await RequestManager.User.get({
          userId: contactObj.id,
        });

        let modal = this.$dialog.open(
          ContactProfile,
          {
            formData: userObj,
            onContactUpdate: () => {
              this.openModalContactEdit(userObj);
              modal?.close();
            },
          },
          { group: 'sidebar-right', theme: 'contact-sidebar', close: true }
        );
      } catch (error) {}
    },
    openModalContactDelete(contactObj) {
      let modal = this.$dialog.prompt.delete(
        'Delete contact?',
        'Are you sure you want to delete?'
      );
      modal.then((res) => {
        if (res && res.action === 'OK') {
          contactDelete(contactObj);
        }
        modal = null;
      });
    },
    //
    openModalContactAdd() {
      //
      let modal = null;
      const closeModal = () => {
        modal && modal.close();
      };
      const props = {
        // data
        // events
        onSave: (submitObj) => {
          contactAdd(submitObj);
          closeModal();
        },
      };
      //
      modal = this.$dialog.open(ContactAdd, props, {
        group: 'modal',
        theme: 'community',
        close: true,
      });
      modal.then(() => {
        modal = null;
      });
    },
    //
    openModalContactEdit(contactObj) {
      let modal = null;
      const closeModal = () => {
        modal && modal.close();
      };

      const props = {
        // data
        formData: {
          firstName: contactObj.firstName,
          lastName: contactObj.lastName,
          profilePhoto: contactObj.profilePhoto,
        },
        // events
        onSubmit: (submitObj) => {
          contactUpdate(
            contactObj.id,
            Object.assign({}, contactObj, submitObj)
          );
          closeModal();
        },
      };
      //
      modal = this.$dialog.open(ContactEdit, props, {
        group: 'modal',
        theme: 'community',
        close: true,
      });
      modal.then(() => {
        modal = null;
      });
    },
    //
    openModalCommunityCreate() {
      //
      let modal = null;
      const closeModal = () => {
        modal && modal.close();
      };
      const props = {
        // data
        communityList: this.communityList,
        // events
        onSave: (submitObj) => {
          communityAdd(submitObj.community, submitObj.participants);
          closeModal();
        },
      };
      //
      modal = this.$dialog.open(CommunityCreate, props, {
        group: 'modal',
        theme: 'community',
        close: true,
      });
      modal.then(() => {
        modal = null;
      });
    },
    //
    openModalCommunityEdit(communityObj) {
      //
      let modal = null;
      const closeModal = () => {
        modal && modal.close();
      };
      const props = {
        // data
        communityObj: communityObj,
        // events
        onSubmit: (submitObj) => {
          communityUpdateInfo({
            ...communityObj,
            ...submitObj, // name communityPhoto
          });
          closeModal();
        },
      };
      //
      modal = this.$dialog.open(CommunityEdit, props, {
        group: 'modal',
        theme: 'community',
        close: true,
      });
      modal.then(() => {
        modal = null;
      });
    },
    //
    openModalCommunityContactEdit(communityObj) {
      //
      let modal = null;
      const closeModal = () => {
        modal && modal.close();
      };

      let selectedContactObj = {};
      for (let i = 0; i < communityObj.participants.length; i++) {
        selectedContactObj[communityObj.participants[i].id] =
          communityObj.participants[i];
      }

      const props = {
        // data
        communityList: this.communityList,
        selectedContactObj: selectedContactObj,
        // events
        onSubmit: (submitObj) => {
          communityUpdateContactList(communityObj.id, submitObj.participants);
          closeModal();
        },
      };
      //
      modal = this.$dialog.open(CommunityEditContact, props, {
        group: 'modal',
        theme: 'community',
        close: true,
      });
      modal.then(() => {
        modal = null;
      });
    },
    //
    openModalCommunityDelete(communityObj) {
      //
      // let modal = null;
      // const closeModal = () => {
      //   modal && modal.close();
      // };

      let selectedContactObj = {};
      for (let i = 0; i < communityObj.participants.length; i++) {
        selectedContactObj[communityObj.participants[i].id] =
          communityObj.participants[i];
      }

      let modal = this.$dialog.prompt.delete('Delete community?');
      modal.then((res) => {
        if (res && res.action === 'OK') {
          communityDelete(communityObj.id);
        }
        modal = null;
      });
    },
    openModalCommunityLeave(communityObj) {
      //
      let selectedContactObj = {};
      for (let i = 0; i < communityObj.participants.length; i++) {
        selectedContactObj[communityObj.participants[i].id] =
          communityObj.participants[i];
      }

      let modal = this.$dialog.prompt.delete(
        'Leave community?',
        "You'll lose your access to community email, chat, boards and files. Are you sure you want to leave?",
        { okLabel: 'Leave' }
      );
      modal.then((res) => {
        if (res && res.action === 'OK') {
          communityLeave(communityObj.id);
        }
        modal = null;
      });
    },
  },
  setup() {
    const communityList = communityGetList();
    const communityActive = communityGetSelected();
    const contactActive = contactGetSelected();

    return {
      communityList,
      communityActive,
      contactActive,
      fileStorageClearFilterState,
      fileStorageAutoHide,
    };
  },
  mounted() {
    communityFetchList();
    // getContactList();
    // selectCommunity(communityList.value[0]);
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  height: 100%;
  background-color: var(--color-gray-01);
  display: flex;
  //> div {
  //  height: 100%;
  //}
}

.sidebar-main-wrapper {
  //flex-direction: column;
  flex: 0 0 92px;
  width: 92px;

  //width: 100px;
  height: 100%;
  // background-color: #454d5d;
  display: flex;
  flex-direction: column;

  ::v-deep(.v-popper) {
    width: 100%;
  }
}

.sidebar-main-wrapper,
.sidebar-additional-wrapper {
  border-right: 1px solid var(--common-border-color);
  display: flex;
}

.sidebar-additional-wrapper {
  width: 273px;
}

.sidebar-additional-inner {
  width: 272px;
  display: flex;
  flex-direction: column;
  .sidebar-additional-enter-active & {
    transition: opacity 0.5s 0.25s ease-in-out;
  }
  .sidebar-additional-leave-active & {
    transition: opacity 0.25s ease-in;
  }

  .sidebar-additional-enter-from &,
  .sidebar-additional-leave-to & {
    opacity: 0;
  }
}

.sidebar-search-wrapper {
  display: flex;
  width: inherit;
  height: 73px;
  padding: 12px 8px;
  border-bottom: 1px solid var(--common-border-color);
}

.sidebar-main-wrapper {
  ::v-deep(.fix-sidebar-header) {
    flex: 0 0 73px;
    // background-color: #dc472e;
  }

  .sidebar-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    // background-color: #2CA800;
    overflow: hidden;
  }

  .sidebar-footer {
    flex: 0 0 125px;
    // background-color: red;
  }
}

.sidebar-header {
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--common-border-color);
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: var(--color-gray-02);
  }
}

.sidebar-icon-menu,
.sidebar-icon-collapse {
  @include icon-size(1.5);
  cursor: pointer;
}
.sidebar-icon-menu {
  fill: var(--color-secondary-light);
  @include icon-size(2);
}

.sidebar-icon-collapse {
  fill: var(--color-secondary-light);
}

.sidebar-icon-file-storage {
  fill: var(--color-secondary-light);
  margin-bottom: 1rem;
  @include icon-size(1.5);
  cursor: pointer;
}
.sidebar__file-storage {
  width: 32rem;
}

.sidebar-footer {
  display: flex;
  margin-top: auto;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  flex-direction: column;
}

// Animations

.sidebar-additional-enter-active,
.sidebar-additional-leave-active {
  transition: all 0.5s;
}
.sidebar-additional-leave-active {
  transition-delay: 0.25s;
}
.sidebar-additional-enter-from,
.sidebar-additional-leave-to {
  transform: translateX(-10px);
  width: 0;
  opacity: 0;
}

.sidebar-content {
  flex: 1 1 100%;
}
</style>
