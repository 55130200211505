<template>
  <div>
    <FveSelectButtonValue
        v-model="scheduleView"
        :field="{ name: 'schedule-view', model: true }"
        :option-list="[
              { id: 'month',  name: 'Month' },
              { id: 'week',   name: 'Week' },
              { id: 'day',    name: 'Day' },
            ]"
    />
  </div>
</template>

<script>
import {getScheduleView, setScheduleView} from "@store/schedule.store";
import FveSelectButtonValue from "@fve/Element/Select/FveSelectButtonValue";
export default {
  name: "ScheduleNav",
  components: {
    FveSelectButtonValue,
  },
  computed: {
    scheduleView: {
      get() {
        return getScheduleView().value;
      },
      set(newVal) {
        setScheduleView(newVal);
      }
    },
  }
};
</script>

<style scoped>

</style>
