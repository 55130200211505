import { RouterView } from "vue-router";
import * as pageName from "@router/variable";
import LAYOUT from "@layout/layoutName";
import { ROLE_AUTH, ROLE_ANONYMOUS } from "@permission/roleName";

export default [
  {
    path: "/email",
    component: RouterView,
    children: [
      {
        path: "",
        name: pageName.EMAIL,
        component: () => import("@page/Email/Email"),
        props: (route) => ({ tag: route.query.tag || "Inbox" }),
        meta: {
          layout: LAYOUT.DEFAULT,
          permission: {
            [ROLE_AUTH]: true,
            // [ROLE_ANONYMOUS]: true,
          },
        },
      },
      {
        name: pageName.EMAIL_CREATE,
        path: "create",
        component: () => import("@page/Email/EmailCreate"),
        props: (route) => ({ id: route.query.id || "" }),
        meta: {
          layout: LAYOUT.DEFAULT,
          permission: {
            [ROLE_AUTH]: true,
            // [ROLE_ANONYMOUS]: true,
          },
        },
      },
      {
        name: pageName.EMAIL_READ,
        path: "read/:id",
        component: () => import("@page/Email/EmailRead"),
        meta: {
          layout: LAYOUT.DEFAULT,
          permission: {
            [ROLE_AUTH]: true,
            // [ROLE_ANONYMOUS]: true,
          },
        },
      },
    ],
  },
];
