import { ref } from 'vue';

const currentTheme = ref(localStorage.getItem('theme') || 'light');

export const getTheme = () => currentTheme.value;

global.$theme = getTheme();

export const setTheme = (theme) => {
  localStorage.setItem('theme', theme);
  document.body.classList.remove(`theme-${currentTheme.value}`);
  document.body.classList.add(`theme-${theme}`);
  currentTheme.value = theme;
};

setTheme(currentTheme.value);
