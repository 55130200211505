<template>
  <div class="chat-nav" v-if="setCurrentChat">
    <span class="chat-nav__title">Chat:</span>
    <span class="chat-nav__name">{{ setCurrentChat }}</span>
  </div>
</template>

<script>
import {communityGetSelected} from "@store/community.store";
import {contactGetSelected} from "@store/communityContact.store";

export default {
  name: 'ChatNav',
  computed: {
    setCurrentChat() {
      //
      const communityObj = communityGetSelected();
      if (communityObj && communityObj.value && communityObj.value.id) {
        return communityObj.value.name;
      }

      //
      const contactObj = contactGetSelected();
      if (contactObj && contactObj.value && contactObj.value.id) {
        if (contactObj.value.firstName && contactObj.value.lastName) {
          return `${contactObj.value.firstName} ${contactObj.value.lastName}`
        }
        return 'Anonymous user';
      }
      return '';
    },
  }
};
</script>

<style lang="scss" scoped>

.chat-nav {
  display: flex;
  align-items: center;
  .chat-nav__title {
    color: var(--color-gray-04);
    margin-right: 5px;
  }

  .chat-nav__name {
    color: var(--color-secondary)
  }

}

</style>
