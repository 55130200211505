<template>
  <ul class="dropdown__list">
    <template v-for="(item, index) in menuList" :key="index">
      <li @click="item.click(), hide()">
        <p :class="item.class">
          {{ item.name }}
        </p>
      </li>
    </template>
  </ul>
</template>
<script>
export default {
  props: {
    hide: {
      type: Function,
      default: () => ({}),
    },
    menuList: {
      type: Array,
      required: true,
      /*
      {
        name: "text",
        click(){}
      }
       */
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown__text--warning {
  color: var(--color-red-03);
}
</style>
