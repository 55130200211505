// import moment from 'moment';

export default {
  props: {
    activeDate: { type: DateTime,  required: true },
    minDate:    { type: DateTime,  default: () => { return null; } },
    maxDate:    { type: DateTime,  default: () => { return null; } },
    events:     { type: Array },
    use12:      { type: Boolean, required: true }
  },
  methods: {
    isDayDisabled(day) {
      if (!this.minDate && !this.maxDate)
        return false;

      // TODO: fix logic
      // if (this.minDate && this.minDate.isAfter(day, 'day')) return true;
      // if (this.maxDate && this.maxDate.isBefore(day, 'day')) return true;
      return false;
    }
  },
  watch: {
    activeDate() {
      this.buildCalendar();
    },
    events() {
      this.buildCalendar();
    }
  }
}
