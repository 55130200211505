<template>
  <div class="cctg create-community-tab-group">
    <div class="th-px cctg-user-title">
      Add user
      <span class="cctg-user-count"
        >{{ selectedContactCount }}/{{
          communityList[0].participants.length
        }}</span
      >
    </div>

    <div class="cctg-community-list">
      <div class="cctg-ps-wrapper">
        <perfect-scrollbar>
          <template v-for="(community, index) in communityList" :key="index">
            <div
              class="th-px cctg-group-item"
              @click="$emit('select', community)"
            >
              <div class="cctg-avatar">
                <Avatar
                  :image="community.communityPhoto"
                  :name="community.name"
                  :color="community.color"
                ></Avatar>
              </div>
              <p>{{ community.name }}</p>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.293 19.707a1 1 0 010-1.414L14.586 12 8.293 5.707a1 1 0 011.414-1.414l7 7a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0z"/></svg>
            </div>
          </template>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@component/Avatar';

export default {
  name: 'CommunitySelect',
  components: {
    Avatar,
  },
  props: {
    communityList: { type: Array, required: true },
    selected: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // selectedContact: Object.assign({}, this.selected),
    };
  },
  computed: {
    selectedContactCount() {
      return Object.keys(this.selected).length;
    },
  },
};
</script>

<style lang="scss" scoped>
//community create tab group
.cctg {
  padding-top: 5px;
  padding-bottom: 15px;

  .cctg-community-list {
    border-top: 1px solid var(--color-background);
    max-height: 208px;
  }

  .cctg-user-title {
    color: var(--color-gray-08);
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .cctg-user-count {
    color: var(--color-gray-05);
    margin-left: 0.25rem;
    font-weight: 400;
  }
  .cctg-ps-wrapper {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 208px;
    padding-top: 8px;
  }
  .cctg-group-item {
    color: var(--color-gray-07);
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    transition: all 0.25s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    &:hover {
      background-color: var(--color-gray-02);
    }
    p {
      position: absolute;
      padding-left: 40px;
    }
  }
  .cctg-avatar {
    @include icon-size(2); // 32*32px
  }
  svg {
    fill: var(--color-gray-04);
  }
}
</style>
