<template>
  <div class="sidebar-search-field">
    <input
      type="text"
      :placeholder="placeholder"
      @input="setSearchValue($event.target.value)"
      :value="value"
      v-bind="$attrs"
    />
    <transition name="fade" mode="out-in">
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-if="!value" v-bind:svg-inline="''" v-bind:class="'sidebar-search-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 11.52C6 8.512 8.505 6 11.69 6c3.184 0 5.688 2.513 5.688 5.52 0 3.006-2.504 5.519-5.689 5.519-3.184 0-5.689-2.513-5.689-5.52zM11.69 4C7.442 4 4 7.367 4 11.52c0 4.152 3.443 7.519 7.69 7.519 1.979 0 3.784-.732 5.147-1.933l1.886 1.883.1.087c.351.26.849.23 1.166-.089a.898.898 0 00-.001-1.269l-1.954-1.95a7.37 7.37 0 001.345-4.249c0-4.152-3.443-7.519-7.69-7.519z"/></svg>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" @click="clearSearchValue" v-else="" v-bind:svg-inline="''" v-bind:class="'sidebar-search-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.657 7.757a1 1 0 00-1.414-1.414L12.707 9.88a1 1 0 01-1.414 0L7.757 6.343a1 1 0 00-1.414 1.414l3.536 3.536a1 1 0 010 1.414l-3.536 3.536a1 1 0 101.414 1.414l3.536-3.536a1 1 0 011.414 0l3.536 3.536a1 1 0 101.414-1.414l-3.536-3.536a1 1 0 010-1.414l3.536-3.536z"/></svg>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SidebarSearch",
  inheritAttrs: false,
  emits: ['update:value'],
  props: {
    placeholder: {
      type: String,
      default: "Search",
    },
    value: {
      type: String
    }
  },
  methods: {
    setSearchValue(v) {
      this.$emit('update:value', v);
    },
    clearSearchValue() {
      this.setSearchValue('');
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-search-field {
  width: 100%;
  position: relative;
  input {
    width: inherit;
    height: 48px;
    border: 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    background: var(--search-background);
    border-radius: 8px;
    color: var(--color-gray-07);
    padding: 12px 44px 12px 16px;
    &::placeholder {
      color: var(--color-gray-04);
    }
  }
}
.sidebar-search-icon {
  @include icon-size(1.25);
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  path {
    fill: var(--color-gray-04);
  }
}
</style>
