<template>
  <div class="sidebar-schedule-filters__">
    <FveDateTimePickerView
        v-model="scheduleDateFilter"
        :field="{
        name: 'date',
        model: true,
      }"
    />
    <div class="sidebar-schedule-filters__type">
      <div class="sidebar-schedule-filters__title">My schedule</div>
        <div class="fs__filter__item">
          <FveCheckbox
              v-model="filter.meeting"
              :field="{
                name: 'meeting',
                model: true,
              }"
              label="Meeting"
              :badge="'#566BD6'"
              @update:modelValue="setTypeFilter('meeting', $event)"
          />
        </div>
      <div class="fs__filter__item">
        <FveCheckbox
            v-model="filter.task"
            :field="{
                name: 'task',
                model: true,
              }"
            label="Task"
            :badge="'#2AD2C9'"
            @update:modelValue="setTypeFilter('task', $event)"
        />
      </div>
      <div class="fs__filter__item">
        <FveCheckbox
            v-model="filter.reminder"
            :field="{
                name: 'reminder',
                model: true,
              }"
            label="Reminder"
            :badge="'#F2CD00'"
            @update:modelValue="setTypeFilter('reminder', $event)"
        />
      </div>
    </div>

  </div>
</template>

<script>

import FveCheckbox from "@fve/Switch/FveCheckbox";
import FveDateTimePickerView from "@fve/Element/Date/FveDateTimePickerView";
import { scheduleFilter } from "@store/schedule.store";

export default {
  name: "SidebarSchedule",
  components: {
    FveDateTimePickerView,
    FveCheckbox
  },
  computed: {
    scheduleDateFilter: {
      get() {
        return scheduleFilter.value.date;
      },
      set(newVal) {
        scheduleFilter.value.date = newVal;
      }
    },
    scheduleTypeFilter: {
      get() {
        return scheduleFilter.value.date;
      },
      set(newVal) {
        scheduleFilter.value.date = newVal;
      }
    },
  },
  data() {
    return {
      // prettier-ignore
      filter: {
        meeting: false,
        task: false,
        reminder: false,
      },
      scheduleFilter,
      filterList: [
        {name: "Meeting",  key: this.$enum.SCHEDULE_EVENT_TYPE.MEETING,},
        {name: "Task",     key: this.$enum.SCHEDULE_EVENT_TYPE.TASK,},
        {name: "Reminder", key: this.$enum.SCHEDULE_EVENT_TYPE.REMINDER,},
      ],
    };
  },
  methods: {
    setTypeFilter(filterName, val) {
      scheduleFilter.value.scheduleType[filterName] = val;
    }
  }
};
</script>

<style lang="scss" scoped>

.sidebar-schedule-filters__type {
  padding: 16px;
}

.sidebar-schedule-filters__title {
  color: var(--color-gray-07);
  margin-bottom: 16px;
  font-weight: 600;
}

</style>
