<template>
  <div class="notification-group">
    <div class="notification-group__header">{{ formatDate }}</div>
    <div class="notification-group__body">
      <template v-for="notify in notifications" :key="notify.id">
        <NotificationItem :notify="notify"/>
      </template>
    </div>
  </div>
</template>

<script>

import NotificationItem from "@component/Notification/NotificationItem";

export default {
  name: "NotificationGroup",
  components: {
    NotificationItem
  },
  props: {
    date: {
      type: String,
      required: true
    },
    notifications: {
      type: Array,
      default: () => { return [] }
    }
  },
  computed: {
    formatDate() {
      const date = DateTime(this.date);
      if (date.isToday()) {
        return 'Today';
      }

      if (date.isYesterday()) {
        return 'Yesterday';
      }

      return DateTime(this.date).format('MMM DD');
    }
  }
};
</script>

<style lang="scss" scoped>

.notification-group {
  display: flex;
  flex-direction: column;
  background-color: var(--sidebar-notification-background);

  .notification-group__header {
    background: var(--notification-header-background);
    padding: 12px 24px 8px;
    font-size: 14px;
    line-height: 1.45;
    color: var(--color-gray-06);
  }

  .notification-group__body {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
  }

}

</style>