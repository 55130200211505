<template>
  <template v-if="selected === true">
    <!-- prettier-ignore -->
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 10C4 7.17157 4 5.75736 4.87868 4.87868C5.75736 4 7.17157 4 10 4H14C16.8284 4 18.2426 4 19.1213 4.87868C20 5.75736 20 7.17157 20 10V14C20 16.8284 20 18.2426 19.1213 19.1213C18.2426 20 16.8284 20 14 20H10C7.17157 20 5.75736 20 4.87868 19.1213C4 18.2426 4 16.8284 4 14V10Z"
        fill="#566BD6"
      />
      <path
        d="M16.2809 10.1247C16.6259 9.69343 16.556 9.06414 16.1247 8.71913C15.6934 8.37412 15.0641 8.44404 14.7191 8.8753L16.2809 10.1247ZM11.5 14.5L10.7929 15.2071C10.994 15.4082 11.2713 15.5142 11.5553 15.4985C11.8393 15.4827 12.1032 15.3468 12.2809 15.1247L11.5 14.5ZM9.20711 10.7929C8.81658 10.4024 8.18342 10.4024 7.79289 10.7929C7.40237 11.1834 7.40237 11.8166 7.79289 12.2071L9.20711 10.7929ZM14.7191 8.8753L10.7191 13.8753L12.2809 15.1247L16.2809 10.1247L14.7191 8.8753ZM12.2071 13.7929L9.20711 10.7929L7.79289 12.2071L10.7929 15.2071L12.2071 13.7929Z"
        fill="white"
      />
    </svg>
  </template>
  <template v-else-if="selected === null">
    <!-- prettier-ignore -->
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14 6H10C8.52925 6 7.62738 6.00425 6.97468 6.092C6.40099 6.16913 6.3052 6.27883 6.29383 6.29185C6.29341 6.29233 6.2931 6.29268 6.29289 6.29289C6.29268 6.2931 6.29233 6.29341 6.29185 6.29383C6.27883 6.3052 6.16913 6.40099 6.092 6.97468C6.00425 7.62738 6 8.52925 6 10V14C6 15.4708 6.00425 16.3726 6.092 17.0253C6.16913 17.599 6.27883 17.6948 6.29185 17.7062C6.29233 17.7066 6.29268 17.7069 6.29289 17.7071C6.2931 17.7073 6.29341 17.7077 6.29383 17.7082C6.3052 17.7212 6.40099 17.8309 6.97468 17.908C7.62738 17.9958 8.52925 18 10 18H14C15.4708 18 16.3726 17.9958 17.0253 17.908C17.599 17.8309 17.6948 17.7212 17.7062 17.7082C17.7066 17.7077 17.7069 17.7073 17.7071 17.7071C17.7073 17.7069 17.7077 17.7066 17.7082 17.7062C17.7212 17.6948 17.8309 17.599 17.908 17.0253C17.9958 16.3726 18 15.4708 18 14V10C18 8.52925 17.9958 7.62738 17.908 6.97468C17.8309 6.40099 17.7212 6.3052 17.7082 6.29383C17.7077 6.29341 17.7073 6.2931 17.7071 6.29289C17.7069 6.29268 17.7066 6.29233 17.7062 6.29185C17.6948 6.27883 17.599 6.16913 17.0253 6.092C16.3726 6.00425 15.4708 6 14 6ZM4.87868 4.87868C4 5.75736 4 7.17157 4 10V14C4 16.8284 4 18.2426 4.87868 19.1213C5.75736 20 7.17157 20 10 20H14C16.8284 20 18.2426 20 19.1213 19.1213C20 18.2426 20 16.8284 20 14V10C20 7.17157 20 5.75736 19.1213 4.87868C18.2426 4 16.8284 4 14 4H10C7.17157 4 5.75736 4 4.87868 4.87868Z" fill="#566BD6"/>
      <path d="M8 11C8 9.58579 8 8.87868 8.43934 8.43934C8.87868 8 9.58579 8 11 8H13C14.4142 8 15.1213 8 15.5607 8.43934C16 8.87868 16 9.58579 16 11V13C16 14.4142 16 15.1213 15.5607 15.5607C15.1213 16 14.4142 16 13 16H11C9.58579 16 8.87868 16 8.43934 15.5607C8 15.1213 8 14.4142 8 13V11Z" fill="#566BD6"/>
    </svg>
  </template>
  <template v-else>
    <!-- prettier-ignore -->
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#babace"
        d="M14 6H10C8.52925 6 7.62738 6.00425 6.97468 6.092C6.40099 6.16913 6.3052 6.27883 6.29383 6.29185C6.29341 6.29233 6.2931 6.29268 6.29289 6.29289C6.29268 6.2931 6.29233 6.29341 6.29185 6.29383C6.27883 6.3052 6.16913 6.40099 6.092 6.97468C6.00425 7.62738 6 8.52925 6 10V14C6 15.4708 6.00425 16.3726 6.092 17.0253C6.16913 17.599 6.27883 17.6948 6.29185 17.7062C6.29233 17.7066 6.29268 17.7069 6.29289 17.7071C6.2931 17.7073 6.29341 17.7077 6.29383 17.7082C6.3052 17.7212 6.40099 17.8309 6.97468 17.908C7.62738 17.9958 8.52925 18 10 18H14C15.4708 18 16.3726 17.9958 17.0253 17.908C17.599 17.8309 17.6948 17.7212 17.7062 17.7082C17.7066 17.7077 17.7069 17.7073 17.7071 17.7071C17.7073 17.7069 17.7077 17.7066 17.7082 17.7062C17.7212 17.6948 17.8309 17.599 17.908 17.0253C17.9958 16.3726 18 15.4708 18 14V10C18 8.52925 17.9958 7.62738 17.908 6.97468C17.8309 6.40099 17.7212 6.3052 17.7082 6.29383C17.7077 6.29341 17.7073 6.2931 17.7071 6.29289C17.7069 6.29268 17.7066 6.29233 17.7062 6.29185C17.6948 6.27883 17.599 6.16913 17.0253 6.092C16.3726 6.00425 15.4708 6 14 6ZM4.87868 4.87868C4 5.75736 4 7.17157 4 10V14C4 16.8284 4 18.2426 4.87868 19.1213C5.75736 20 7.17157 20 10 20H14C16.8284 20 18.2426 20 19.1213 19.1213C20 18.2426 20 16.8284 20 14V10C20 7.17157 20 5.75736 19.1213 4.87868C18.2426 4 16.8284 4 14 4H10C7.17157 4 5.75736 4 4.87868 4.87868Z"
      />
    </svg>
  </template>
</template>
<script>
export default {
  name: "ViewSquare",
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
