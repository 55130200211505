
import $config from "@/core/config";

export default {
  auth : $config.AUTH_API_URL + '/',
  api  : $config.BASE_API_URL + '/',
  test : $config.MOCK_API_URL + '/',
  //
  // 'json-test': '/request/'
};
