
//
import hostAlias      from "./Settings/HostAlias";
import Hook           from "./Settings/Hook";
import PresetManager  from "./Settings/PresetManager";

export default {
  hostAlias       : hostAlias,
  hook            : Hook,
  presetManager   : new PresetManager(),
};
