import AuthRequest from './Request/AuthRequest';
import BoardRequest from './Request/BoardRequest';
import CommunityRequest from './Request/CommunityRequest';
import ContactRequest from './Request/ContactRequest';
import UserRequest from './Request/UserRequest';
import EmailRequest from '@service/RequestManager/Request/EmailRequest';
import FileStorageRequest from './Request/FileStorageRequest';
import ScheduleRequest from '@service/RequestManager/Request/ScheduleRequest';
import ChatRequest from '@service/RequestManager/Request/ChatRequest';
import NotificationRequest from '@service/RequestManager/Request/NotificationRequest';

// prettier-ignore
const RequestSchema = {
  Auth            : AuthRequest,
  Board           : BoardRequest,
  Community       : CommunityRequest,
  Email           : EmailRequest,
  FileStorage     : FileStorageRequest,
  Schedule        : ScheduleRequest,
  Contact         : ContactRequest,
  User            : UserRequest,
  Chat            : ChatRequest,
  Notification    : NotificationRequest,
};

export default RequestSchema;
