<template>
  <div class="user-panel">
    <Avatar
      class="user-panel__avatar"
      :image="form.profilePhoto"
      :name="form.fullname"
    />
    <div class="user-panel__username">{{ form.fullname }}</div>
    <div
      v-if="editable"
      class="user-panel__notify-click"
      @click="$emit('contactUpdate')"
    >
      Click to customize name or avatar
    </div>

    <div
      class="user-panel__origin"
      v-if="!form.isDataOrigin && !form.isExternal"
    >
      <Avatar
        class="user-panel__avatar"
        :name="form.fullnameOrigin"
        :image="form.origin.profilePhoto"
      />
      <span class="user-panel__label">Original name</span>
      <p class="user-panel__content">{{ form.fullnameOrigin }}</p>
    </div>

    <div class="user-panel__bar">
      <span class="user-panel__label">Email</span>
      <div style="display: flex; justify-content: center; flex-wrap: nowrap; flex-direction: row; align-items: center;">
        <div style="flex: 1 0 auto;text-align: left;max-width: 90%;overflow: hidden;" class="user-panel__content">{{ form.mail }}</div>
        <div style="flex: 0 0 40px; background: #E6EBF8; border-radius: 4px;" @click="copyText(form.mail)">
          <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:style="'display: block; cursor: pointer; fill: #566BD6;'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 12h4a5 5 0 015 5 4 4 0 014 4v3a4 4 0 01-4 4h-3a3.998 3.998 0 01-3.465-2H16a5 5 0 01-5-5v-4a5 5 0 015-5zm4 12a2 2 0 002 2h3a2 2 0 002-2v-3a2 2 0 00-2-2h-3a2 2 0 00-2 2v3zm3-7h-1a4 4 0 00-4 4v3h-2a3 3 0 01-3-3v-4a3 3 0 013-3h4a3 3 0 013 3z"/></svg>
        </div>
      </div>
    </div>

    <!--    <div class="user-panel__bar" v-if="form.companyName">-->
    <!--      <span class="user-panel__label">Company</span>-->
    <!--      <p class="user-panel__content">{{ form.companyName }}</p>-->
    <!--    </div>-->

    <!--    <div class="user-panel__bar" v-if="form.gender">-->
    <!--      <span class="user-panel__label">Gender</span>-->
    <!--      <p class="user-panel__content">{{ form.gender }}</p>-->
    <!--    </div>-->

    <!--    <div class="user-panel__bar" v-if="form.hobbiesAndInterests">-->
    <!--      <span class="user-panel__label">Hobbies and Interests</span>-->
    <!--      <p class="user-panel__content">{{ form.hobbiesAndInterests }}</p>-->
    <!--    </div>-->

    <!--    <div class="user-panel__bar" v-if="form.countryOfResidence">-->
    <!--      <span class="user-panel__label">Country of Residence</span>-->
    <!--      <p class="user-panel__content">{{ form.countryOfResidence }}</p>-->
    <!--    </div>-->

    <!--    <div class="user-panel__bar" v-if="form.familyStatus">-->
    <!--      <span class="user-panel__label">Family Status</span>-->
    <!--      <p class="user-panel__content">{{ form.familyStatus }}</p>-->
    <!--    </div>-->
  </div>
</template>

<script>
import Avatar from "@component/Avatar";
import FveMixinForm from "@fve/Mixin/FveMixinForm.vue";
import CopyText from '@service/CopyText';

export default {
  name: "UserProfilePanel",
  mixins: [FveMixinForm],
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Avatar,
  },
  methods: {
    copyText(text) {
      CopyText(text);
    },
    formSchema() {
      //prettier-ignore
      return {
        fullname:            { type: String, default: () => "" },
        fullnameOrigin:      { type: String, default: () => "" },
        mail:                { type: String, default: () => "" },
        companyName:         { type: String, default: () => "" },
        gender:              { type: String, default: () => "" },
        hobbiesAndInterests: { type: String, default: () => "" },
        countryOfResidence:  { type: String, default: () => "" },
        familyStatus:        { type: String, default: () => "" },
        origin:              { type: Object, default: () => ({})},
        profilePhoto:        { type: FileClass, default: () => new FileClass() },
        isDataOrigin:        { type: Boolean,   default: () => true },
        isExternal:          { type: Boolean, default: () => false}
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.user-panel {
  height: 100%;
  width: 100%;

  .user-panel__avatar {
    @include icon-size(6.25);
    margin: 0 auto 16px;
  }

  .user-panel__origin {
    display: grid;
    grid-template-columns: 3rem 1fr;
    grid-gap: 0 1.5rem;
    margin-bottom: 1.5rem;
    .user-panel__avatar {
      @include icon-size(3);
      margin-bottom: 0;
      grid-column: 1;
      grid-row: 1 / 3;
    }
    .user-panel__label,
    .user-panel__content {
      grid-column: 2;
    }
  }

  .user-panel__username {
    font-size: 1.375rem;
    line-height: 1.45;
    font-weight: 700;
    color: var(--color-gray-07);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-panel__notify-click,
  .user-panel__label {
    font-size: 0.875rem;
    line-height: 1.45;
    color: var(--color-gray-05);
  }

  .user-panel__notify-click {
    text-align: center;
    margin-bottom: 32px;
    cursor: pointer;
  }

  .user-panel__bar {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .user-panel__content {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 3px;
  }
}
</style>
