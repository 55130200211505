import * as pageName from "@router/variable";

const pushGroup1 = 'SCHEDULER::TASK::CREATE';
const pushGroup2 = ['SCHEDULER', 'TASK', 'CREATE'];
import {ref} from 'vue';

// 1. Закладываем, что у нотификейшена может быть несколько комьюнити. community: []


// // Переносим на фронт логику переходов (во всяком случае пока). Бэку не отправлять этот объек в пояснениях.
var action = {
  routeName: '', // ?
    type: 'ROUTER', // ?
    typeAction: 'Должен совпадать с pageName/$routeName (запросить у фронта параметром) если type ROUTER', // ?
    params: 'obj со специфическими параметрами для каждой ссылки (для досок, писем, ивентов и т.п.)', // ?
};

/**
 *
 * @typedef NotificationObj
 * @param {String} id
 * @param {Object} user
 * @param {String} type
 * @param {String} action
 * @param {String} title
 * @param {Object} data
 * @param {Array}  community
 * @param {String} dateTime
 * @param {Boolean} isRead
 *
 */
const pushNotifyObj = {
  id: '',
  user: {
    firstName: '',
    id: '',
    lastName: '',
    mail: '',
    profilePhoto: null,
  },
  // Имя пуш уведомлений, завязана на сущности общего характера (Чат, Доска).
  // Принцип следующий: [%_GROUP_%]::[%_SUB_GROUP_%]::[%_ACTION_%]
  // [%_SUB_GROUP_%] - опиционально, также может быть несколько субгрупп.
  // Использовать эту инфу будем в качестве служебной и для фильтрации
  // Из нее будут формироваться имена конкретного действия.
  type: 'SCHEDULER::TASK::CREATE',
  action: 'created a meeting', // текст события уведомления, например [%_USER_%] created a [%_type_%]
  title: 'Get some coffee', // заголовок уведомления
  data: {
    // obj со специфическими параметрами для каждого пуша, содержащего какие-либо вложения (для досок, писем, ивентов и т.п.)
    // см. Notion
  },
  community: [
    { id: 'e936442a-5e57-4687-846a-bea428e3f165', name: 'Community 123' },
  ],
  dateTime: '2022-04-20T11:35:50', // Дата и время
  isRead: false, // Статус прочитано/просмотрено. Механизм обработки со стороны фронта и бэка нужно заложить.
};

export const NOTIFY_TYPES = {
  SCHEDULE_MEETING_CREATED: 'SCHEDULE::MEETING::CREATED',
  SCHEDULE_TASK_CREATED: 'SCHEDULE::TASK::CREATED',
  SCHEDULE_REMINDER_CREATED: 'SCHEDULE::REMINDER::CREATED',
  //
  COMMUNITY_ADD_USER: 'COMMUNITY::ADD_USER',
  COMMUNITY_REMOVE_USER: 'COMMUNITY::REMOVE_USER',
  COMMUNITY_CREATE_BOARD: 'COMMUNITY::CREATE_BOARD',
  // COMMUNITY_CHAT_MESSAGE: 'COMMUNITY::CHAT_MESSAGE',
  //
  CHAT_COMMUNITY_MESSAGE: 'CHAT::COMMUNITY::MESSAGE',
  CHAT_PERSONAL_MESSAGE: 'CHAT::PERSONAL::MESSAGE',
  //
  PERSONAL_ADD_USER: 'PERSONAL::ADD_USER',
  // PERSONAL_CHAT_MESSAGE: 'PERSONAL::CHAT_MESSAGE',
  //
  EMAIL_DELAY_SENDING_COMPLETE: 'EMAIL::DELAY_SENDING::COMPLETE',
  EMAIL_RECEIVED: 'EMAIL::RECEIVED',
  EMAIL_RECEIVED_EXTERNAL: 'EMAIL::RECEIVED_EXTERNAL',
};

export const notificationList = ref([
  /** @type NotificationObj **/
  {
    id: 1000,
    user: {
      firstName: 'Alex',
      id: 'fa2aec05-37d0-418a-8479-94d56eb121b1',
      lastName: 'Grummer',
      mail: 'a.grummer@dev.chatmail.rtelekom.sparklingtide.dev',
      profilePhoto: null
    },
    action: 'created a meeting:',
    type: NOTIFY_TYPES.SCHEDULE_MEETING_CREATED,
    title: 'Get some coffee',
    community: [],
    datetime: '2022-04-20T14:53:40',
    isRead: false
  },
  {
    id: 1001,
    user: {
      firstName: 'Nick',
      id: '44866a05-22f6-4b85-88fa-12d9180fafb4',
      lastName: 'Parker',
      mail: 'n_parker@dev.chatmail.rtelekom.sparklingtide.dev',
      profilePhoto: null
    },
    action: 'created a task:',
    type: NOTIFY_TYPES.SCHEDULE_TASK_CREATED,
    title: 'Fix chat bugs on backend side',
    community: [
      {
        id: 1,
        name: 'Backend Team'
      },
      {
        id: 2,
        name: 'Project007'
      }
    ],
    datetime: '2022-04-20T10:31:22',
    isRead: false
  },
  {
    id: 1002,
    user: {
      firstName: 'Daniel',
      id: '704837c5-9876-482c-a260-f468d5e0afd3',
      lastName: 'Drake',
      mail: 'daniel_drake1@dev.chatmail.rtelekom.sparklingtide.dev',
      profilePhoto: null
    },
    action: 'created a reminder:',
    type: NOTIFY_TYPES.SCHEDULE_REMINDER_CREATED,
    title: 'Morning walking',
    community: [],
    datetime: '2022-04-19T14:41:12',
    isRead: false
  },
  {
    id: 1003,
    user: {
      firstName: 'Allie',
      id: 'a9a1cce3-8170-4370-abeb-8b077a9ae3e5',
      lastName: 'Watkins',
      mail: 'watkins911@dev.chatmail.rtelekom.sparklingtide.dev',
      profilePhoto: null
    },
    action: 'created a reminder:',
    type: NOTIFY_TYPES.SCHEDULE_REMINDER_CREATED,
    title: 'Date with Joey',
    community: [
      {
        id: 1,
        name: 'Frontend Team'
      }
    ],
    datetime: '2022-04-18T18:06:38',
    isRead: false
  },
]);