<template>
  <div class="sidebar-community-user-list sccl">
    <p class="sidebar-search-result-title ssr-title">
      <template v-if="!userListIsLoaded">Loading...</template>
      <template v-else-if="participants.length"> Search Result:</template>
      <template v-else>No results</template>
    </p>
    <PerfectScrollbar style="height: 100%">
      <template v-for="(user, i) in participants" :key="i">
        <!-- TODO: выделение при раскрытом дропдауне, не выбирая элемент -->
        <div
          class="sccl-item"
          :class="{
            'sccl-item--selected': userActive && userActive.id === user.id,
          }"
          @click="select(user)"
        >
          <Avatar
            class="sccl-item-avatar"
            :color="user.color"
            :image="user.profilePhoto ? user.profilePhoto.getSrc() : null"
            :name="user.fullname"
          />
          <p class="sccl-item-name">
            {{ user.fullname }}
          </p>

          <!-- TODO: fix - клик по раскрытию дропдауна выделяет весь элемент -->
          <!-- TODO: выяснить для чего была нужна логика отслеживания открытого дропдауна -->
          <div class="sccl-icon-menu-wrapper">
            <PopperDropdown :placement="'bottom-start'">
              <!-- prettier-ignore -->
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sccl-icon-menu'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 6a2 2 0 114 0 2 2 0 01-4 0zm0 6a2 2 0 114 0 2 2 0 01-4 0zm2 4a2 2 0 100 4 2 2 0 000-4z"/></svg>

              <!-- TODO: логика закрытия при клике по пункту меню, временный вариант hideAllPoppers -->
              <template #popper>
                <template v-if="!!myContactObj[user.id]">
                  <ContextMenu :menuList="menuListAddedUser" />
                </template>
                <template v-else>
                  <ContextMenu :menuList="menuList" />
                </template>
              </template>
            </PopperDropdown>
          </div>
        </div>
      </template>
    </PerfectScrollbar>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import vClickOutside from "click-outside-vue3";
import ContextMenu from "@component/UI/ContextMenu";
import PopperDropdown from "@component/Popper/PopperDropdown";
import Avatar from "@component/Avatar";
import { hideAllPoppers } from "floating-vue";
import { userSelected, userSelect, userListIsLoaded } from "@store/user.store";

// import SidebarMenuContact from "@component/Part/Sidebar/SidebarMenuContact";
// import SidebarMenuContactCommunity from "@component/Part/Sidebar/SidebarMenuContactCommunity";

import {myContactObj} from '@store/communityContact.store';

export default {
  name: "SidebarCommunityContact",
  components: {
    PerfectScrollbar,
    PopperDropdown,
    Avatar,
    ContextMenu,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    participants: {
      type: Array,
      required: true,
    },
    userActive: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      myContactObj,
      userListIsLoaded,
      userSelected,
    };
  },
  computed: {
  
    menuListAddedUser() {
      
      return [
        { name: 'Start chat',                 click: () => this.emitEvent('startChat') },
        { name: 'Send email',                 click: () => this.emitEvent('emailSend') },
        { name: 'View contact',               click: () => this.emitEvent('contactView') },
        { name: 'Edit contact',               click: () => this.emitEvent('contactEdit') },
        { name: 'Delete contact',             click: () => this.emitEvent('contactDelete'), class: 'text-red'},
      ];
    },
    
    menuList() {
      return [
        { name: 'Add to contact', click: () => this.emitEvent('userAddToContact')  },
        { name: 'View profile',   click: () => this.emitEvent('userView')          },
      ];
    },
  },
  methods: {
    select(userObj) {
      userSelect(userObj);
    },
    emitEvent(event) {
      this.$emit(event, this.userSelected);
      hideAllPoppers();
    },
  },
};
</script>

<style lang="scss" scoped>
.ssr-title {
  padding: 12px 16px;
}
.sccl {
  overflow: hidden;

  .sccl-item {
    padding: 12px 16px;
    display: grid;
    grid-gap: 0 8px;
    grid-template-columns: max-content 1fr;
    align-items: center;
    position: relative;
    cursor: pointer;
    &--selected,
    &:hover {
      background: var(--color-gray-02);
    }

    .sccl-icon-menu-wrapper {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      visibility: hidden;
      opacity: 0;
      transition: 0.25s ease-in-out;
      cursor: pointer;
    }
    &.sccl-item--selected .sccl-icon-menu-wrapper,
    &.sccl-item:hover .sccl-icon-menu-wrapper {
      visibility: visible;
      opacity: 1;
    }
  }
  .sccl-item-name {
    font-size: 1rem;
    line-height: 150%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sccl-item-avatar {
    @include icon-size(2);
  }

  .sccl-icon-menu {
    display: block;
    fill: var(--color-secondary-light);
  }
}
</style>
