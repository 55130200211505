<template>
  <div class="notification">

    <Avatar :name="fullName" class="notification__avatar"/>

    <div class="notification-content">
      <div class="notification-content__start">
        <div class="notification-content__author">{{ fullName }}</div>
        <div class="notification-content__action">{{ notify && notify.action }}</div>
        <div class="notification-content__title">{{ notify && notify.title }}</div>
      </div>

<!--      <div class="notification-attachments">-->
<!--        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z" fill="#E81D1D"/><path d="M8.522 14c.301 0 .563.046.785.137.221.089.393.217.513.386.12.166.18.361.18.587 0 .226-.06.421-.18.587-.12.166-.292.294-.513.386a2.113 2.113 0 01-.785.133h-.58V17H7v-3h1.522zm-.057 1.547c.193 0 .339-.037.437-.111a.39.39 0 00.148-.326.39.39 0 00-.148-.326c-.098-.077-.244-.115-.437-.115h-.523v.878h.523zM10.46 14h1.574c.365 0 .688.061.97.184a1.5 1.5 0 01.657.523c.155.226.233.49.233.793 0 .303-.078.567-.233.793a1.5 1.5 0 01-.657.523 2.408 2.408 0 01-.97.184H10.46v-3zm1.536 2.323c.285 0 .514-.073.685-.219.174-.145.262-.347.262-.604s-.088-.459-.262-.604c-.171-.146-.4-.219-.685-.219h-.594v1.646h.594zM15.33 14.656v.66h1.47v.655h-1.47V17h-.94v-3H17v.656h-1.67z" fill="#fff"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z" fill="#E81D1D"/></svg>-->
<!--        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z" fill="#E81D1D"/><path d="M8.522 14c.301 0 .563.046.785.137.221.089.393.217.513.386.12.166.18.361.18.587 0 .226-.06.421-.18.587-.12.166-.292.294-.513.386a2.113 2.113 0 01-.785.133h-.58V17H7v-3h1.522zm-.057 1.547c.193 0 .339-.037.437-.111a.39.39 0 00.148-.326.39.39 0 00-.148-.326c-.098-.077-.244-.115-.437-.115h-.523v.878h.523zM10.46 14h1.574c.365 0 .688.061.97.184a1.5 1.5 0 01.657.523c.155.226.233.49.233.793 0 .303-.078.567-.233.793a1.5 1.5 0 01-.657.523 2.408 2.408 0 01-.97.184H10.46v-3zm1.536 2.323c.285 0 .514-.073.685-.219.174-.145.262-.347.262-.604s-.088-.459-.262-.604c-.171-.146-.4-.219-.685-.219h-.594v1.646h.594zM15.33 14.656v.66h1.47v.655h-1.47V17h-.94v-3H17v.656h-1.67z" fill="#fff"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z" fill="#E81D1D"/></svg>-->
<!--        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z" fill="#505FE1"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z" fill="#505FE1"/><path d="M7 14.058h1.426c.33 0 .623.059.878.177.256.118.454.286.595.503.14.217.21.47.21.762 0 .291-.07.545-.21.762-.141.217-.34.385-.595.503a2.073 2.073 0 01-.878.177H7v-2.884zm1.391 2.233c.259 0 .465-.07.62-.21.158-.14.237-.334.237-.581 0-.247-.079-.44-.237-.581-.155-.14-.361-.21-.62-.21h-.538v1.582h.538zM12.079 17c-.32 0-.606-.065-.862-.194a1.515 1.515 0 01-.599-.535 1.416 1.416 0 01-.215-.771c0-.286.072-.543.215-.77.147-.229.346-.407.6-.536.255-.13.542-.194.86-.194.32 0 .605.065.858.194.255.129.455.307.598.535.147.228.22.485.22.771 0 .286-.073.543-.22.77a1.478 1.478 0 01-.598.536c-.253.13-.539.194-.857.194zm0-.676c.152 0 .29-.034.413-.103a.761.761 0 00.293-.288.861.861 0 00.107-.433.839.839 0 00-.107-.429.754.754 0 00-.293-.292.837.837 0 00-.414-.103.837.837 0 00-.413.103.754.754 0 00-.293.292.838.838 0 00-.107.429.86.86 0 00.107.433c.072.123.17.22.293.288a.837.837 0 00.414.103zM15.704 17c-.316 0-.6-.063-.853-.19a1.491 1.491 0 01-.59-.535 1.424 1.424 0 01-.215-.775 1.424 1.424 0 01.805-1.306c.252-.13.537-.194.853-.194.275 0 .524.047.745.14.22.093.405.228.551.404l-.543.47a.907.907 0 00-.71-.338.922.922 0 00-.44.103.746.746 0 00-.297.292.866.866 0 00-.103.429.89.89 0 00.103.433c.072.123.171.22.297.288.13.069.276.103.44.103a.907.907 0 00.71-.338l.543.47c-.146.176-.33.31-.551.404-.221.093-.47.14-.745.14z" fill="#fff"/></svg>-->
<!--        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z" fill="#505FE1"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z" fill="#505FE1"/><path d="M7 14.058h1.426c.33 0 .623.059.878.177.256.118.454.286.595.503.14.217.21.47.21.762 0 .291-.07.545-.21.762-.141.217-.34.385-.595.503a2.073 2.073 0 01-.878.177H7v-2.884zm1.391 2.233c.259 0 .465-.07.62-.21.158-.14.237-.334.237-.581 0-.247-.079-.44-.237-.581-.155-.14-.361-.21-.62-.21h-.538v1.582h.538zM12.079 17c-.32 0-.606-.065-.862-.194a1.515 1.515 0 01-.599-.535 1.416 1.416 0 01-.215-.771c0-.286.072-.543.215-.77.147-.229.346-.407.6-.536.255-.13.542-.194.86-.194.32 0 .605.065.858.194.255.129.455.307.598.535.147.228.22.485.22.771 0 .286-.073.543-.22.77a1.478 1.478 0 01-.598.536c-.253.13-.539.194-.857.194zm0-.676c.152 0 .29-.034.413-.103a.761.761 0 00.293-.288.861.861 0 00.107-.433.839.839 0 00-.107-.429.754.754 0 00-.293-.292.837.837 0 00-.414-.103.837.837 0 00-.413.103.754.754 0 00-.293.292.838.838 0 00-.107.429.86.86 0 00.107.433c.072.123.17.22.293.288a.837.837 0 00.414.103zM15.704 17c-.316 0-.6-.063-.853-.19a1.491 1.491 0 01-.59-.535 1.424 1.424 0 01-.215-.775 1.424 1.424 0 01.805-1.306c.252-.13.537-.194.853-.194.275 0 .524.047.745.14.22.093.405.228.551.404l-.543.47a.907.907 0 00-.71-.338.922.922 0 00-.44.103.746.746 0 00-.297.292.866.866 0 00-.103.429.89.89 0 00.103.433c.072.123.171.22.297.288.13.069.276.103.44.103a.907.907 0 00.71-.338l.543.47c-.146.176-.33.31-.551.404-.221.093-.47.14-.745.14z" fill="#fff"/></svg>-->
<!--      </div>-->

      <div class="notification-content__end">
        <span class="label role">{{ communityName }}</span>
        <span class="label time">{{ formatDate }}</span>
<!--        <span class="time">-->
<!--          Local time-->
<!--        </span>-->
      </div>
    </div>
  </div>
</template>

<script>

import Avatar from "@component/Avatar";

export default {
  name: "NotificationItem",
  components: {
    Avatar
  },
  props: {
    notify: {
      type: Object,
      required: true
    }
  },
  computed: {
    fullName() {
      if (this.notify && this.notify.user){
        return `${this.notify.user.firstName} ${this.notify.user.lastName}`;
      }
      return  'no_name';
    },
    formatDate() {
      if (this.notify && this.notify.datetime) {
        return DateTime(this.notify.datetime).format('DD/MM/YY A');
      }
      return 'no_date';
    },
    communityName() {
      if (this.notify && (!this.notify.community || !this.notify.community.length)) {
        return 'Personal';
      } else {
        let output = '';
        this.notify.community.forEach(item => {
          output += `${item.name}, `;
        });
        return output;
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.notification {
  padding: 16px 24px;
  display: flex;

  .notification__avatar {
    @include icon-size(2.5);
    flex: 0 0 2.5rem;
  }

  .notification-content {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    .notification-content__start {
      display: flex;
      flex: 1 1 auto;
      font-size: 14px;
      line-height: 1.45;
      flex-wrap: wrap;
    }

    .notification-content__end {
      font-size: 12px;
      line-height: 1.35;
      padding: 2px 0;
      display: flex;
      color: var(--color-gray-05);
    }

  }

  .notification-content__author {
    font-weight: 600;
    color: var(--color-gray-07);
  }

  .notification-content__action {
    margin: 0 3px;
  }

  .notification-content__title {
    font-weight: 600;
    color: var(--color-secondary);
  }

}

.notification-attachments {
  display: flex;

  & > svg {
    width: 20px;
    height: 20px;
  }
}


.label:not(:last-of-type) {
  &:after {
    content: '•';
    margin: 0 2px;
    color: var(--color-gray-05);
  }
}

.role {
  color: #1BC0B7;
  font-size: 12px;
  line-height: 1.35;
}

.time {
  color: var(--color-gray-05);
  font-size: 12px;
  line-height: 1.35;
  display: flex;

  & > svg {
    width: 14px;
    height: 14px;
    fill: var(--color-gray-05)
  }

}

</style>