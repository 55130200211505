<template>
  <div class="main">
    <Sidebar />
    <div class="page-wrapper">
      <HeaderSection
        @toggleNotifications="isNotificationShown = !isNotificationShown"
        :isShown="isNotificationShown"
      />
      <router-view class="page" />
      
      <JitsiBlock v-show="jitsiIsActive" class="jitsi" :class="{ 'jitsi-active': jitsiIsActive}" />
      
    </div>
    <NotificationPanel
      :isShown="isNotificationShown"
      @closeNotifications="isNotificationShown = false"
    />
  </div>
</template>

<script>

import HeaderSection from "@component/Part/HeaderSection";
import Sidebar from "@component/Part/Sidebar/Sidebar";
import NotificationPanel from "@component/Notification/NotificationPanel";
import JitsiBlock from '@component/Part/JitsiBlock';
//
import {jitsiIsShow} from '@store/jitsi.store';


export default {
  name: "layout-default",
  components: {
    HeaderSection,
    Sidebar,
    NotificationPanel,
    JitsiBlock,
  },
  data() {
    return {
      isNotificationShown: false,
    };
  },
  computed: {
    jitsiIsActive() {
      return jitsiIsShow();
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
}

.page-wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.page {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.page-row {
  flex-direction: row;
}

.page-column {
  flex-direction: column;
}

.jitsi {
  display: none;
}
.jitsi-active {
  display: block;
  height: 100%;
}

</style>
