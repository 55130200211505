<template>
  <div class="email-nav">
    <transition name="fade" mode="out-in">
      <router-link
        v-if="
          $route.name === $routeName.EMAIL_CREATE ||
          $route.name === $routeName.EMAIL_READ
        "
        :to="{ name: $routeName.EMAIL }"
        class="email-nav__icon-wrapper email-nav__icon--back"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'email-nav__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.707 4.293a1 1 0 010 1.414L9.414 12l6.293 6.293a1 1 0 01-1.414 1.414l-7-7a1 1 0 010-1.414l7-7a1 1 0 011.414 0z"/></svg>
      </router-link>
      <router-link
        v-else
        :to="{ name: $routeName.EMAIL_CREATE }"
        class="email-nav__icon-wrapper email-nav__icon--new"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'email-nav__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 5a1 1 0 10-2 0v5a1 1 0 01-1 1H5a1 1 0 100 2h5a1 1 0 011 1v5a1 1 0 102 0v-5a1 1 0 011-1h5a1 1 0 100-2h-5a1 1 0 01-1-1V5z"/></svg>
      </router-link>
    </transition>
    <div class="email-nav__menu">
      <router-link
          :to="{ name: $routeName.EMAIL, query: { tag: 'Inbox' } }"
          :active-class="($route.query.tag === 'Inbox' || typeof $route.query.tag === 'undefined') ? 'active' : ''"
      >Inbox</router-link>
      <template v-for="link in emailNavLink" :key="link">
        <router-link
            :to="{ name: $routeName.EMAIL, query: { tag: link  }}"
            :active-class="$route.query.tag === link ? 'active' : ''"
        >{{ link }}</router-link>
      </template>
<!--      <router-link :to="{ name: $routeName.EMAIL, query: { tag: 'Unread'  } }"> Unread</router-link>-->
<!--      <router-link :to="{ name: $routeName.EMAIL, query: { tag: 'Sent'    } }"> Sent</router-link>-->
<!--      <router-link :to="{ name: $routeName.EMAIL, query: { tag: 'Sending' } }"> Sending</router-link>-->
<!--      <router-link :to="{ name: $routeName.EMAIL, query: { tag: 'Spam'    } }"> Spam</router-link>-->
<!--      <router-link :to="{ name: $routeName.EMAIL, query: { tag: 'Draft'   } }"> Draft</router-link>-->
<!--      <router-link :to="{ name: $routeName.EMAIL, query: { tag: 'Trash'   } }"> Trash</router-link>-->
    </div>
  </div>
</template>

<script>

export default {
  name: "EmailNav",
  data() {
    return {
      emailNavLink: [
        // 'Inbox', - not included
        'Unread',
        'Sent',
        'Sending',
        'Spam',
        'Draft',
        'Trash',
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.email-nav {
  display: flex;
  align-items: center;

  .email-nav__icon-wrapper {
    @include icon-size(2);
    margin-right: 14px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .email-nav__icon {
    &--new {
      background: rgba(251, 112, 53, 0.4);

      path {
        fill: var(--color-primary);
      }
    }

    &--back {
      background: var(--navback-background);
      fill: var(--navback-color);
    }
  }

  .email-nav__menu {
    display: flex;

    a {
      display: inline-flex;
      padding: 0 6px;
      line-height: 24px;
      font-size: 16px;
      color: var(--color-gray-07);
      align-items: center;
      margin: 0;
    }

    a:hover {
      color: var(--color-secondary);
    }

    a.active.router-link-exact-active {
      color: var(--color-secondary);
    }

  }
}



</style>
