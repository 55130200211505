import { MethodInfo } from "@service/RequestManagerSrc";
import ContactClass from '@entity/class/ContactClass';
import $enum from "@enum";

const setRepeat = (recurrent) => {
  if (recurrent && recurrent.cycleType) {
    switch (recurrent.cycleType) {
      case 'EVERY_DAY':   return 2;
      case 'DAYS_OF_WEEK': return 3;
      case 'EVERY_MONTH': return 4;
      default: return  1;
    }
  }
  return 1; // Just in case
};

const setRecurrent = (repeat, payload, startTime, endTime) => {
  if (!startTime) {
    return;
  }

  /*
  const recurrentObj = {
    cycleType: 'EVERY_DAY',
    startDate: "2025-12-01",
    endDate: "2025-12-10",
    startTime: "08:00:00",
    endTime: "09:00:00",
  };
  */

  const recurrentObj = {
    cycleType: "",
    startDate: DateTime(startTime).format("YYYY-MM-DD"),
    startTime: DateTime(startTime).format("HH:mm:ss"),
    endDate: endTime ? DateTime(endTime).format("YYYY-MM-DD") : null,
    endTime: endTime ? DateTime(endTime).format("HH:mm:ss") : null,
  };

  switch (repeat) {
    case 2:
      recurrentObj.cycleType = "EVERY_DAY";
      break;
    case 3:
      recurrentObj.cycleType = "DAYS_OF_WEEK";
      if (payload && payload.dayOfWeek) {
        recurrentObj.dayOfWeek = payload.dayOfWeek;
      }
      break;
    case 4:
      recurrentObj.cycleType = "EVERY_MONTH";
      if (payload && payload.dayOfMonth) {
        recurrentObj.dayOfMonth = payload.dayOfMonth;
      }
      break;
    case 5:
      recurrentObj.cycleType = "EVERY_YEAR";
      if (payload && payload.dayOfYear) {
        recurrentObj.dayOfYear = payload.dayOfYear;
      }
      break;

    default:
      return;
  }

  return recurrentObj;
};

const prepareScheduleItemRequest = (scheduleItemObj) => {
  /*
    const meetingTestObj = {
        "type": "MEETING",
        "startTime": "2022-05-09T12:30:00",
        "endTime": "2022-05-09T13:30:00",
        "description": "Meeting for sprint planning",
        "community": { "id": "{{community-id}}" },
        "paricipants": [ { "id": "{{accept-guest-id}}" }, { "id": "{{decline-guest-id}}" } ]
      }
    };
  */

  let scheduleItem = Object.assign({}, scheduleItemObj);
  
  console.log('scheduleItem before save: ', scheduleItem);
  
  scheduleItem.type = scheduleItemObj.type.toUpperCase();
  scheduleItem.participants = [];
  scheduleItem.assignee = [];

  // TODO: Переписать по-людски, без такого кол-ва delete
  delete scheduleItem.id;
  delete scheduleItem.dateItem;
  delete scheduleItem.datePeriod;
  delete scheduleItem.repeat;
  delete scheduleItem.to;
  delete scheduleItem.period;
  delete scheduleItem.payloadObj;
  delete scheduleItem.permissions;
  delete scheduleItem.permission;
  delete scheduleItem.dayOfMonth;
  delete scheduleItem.status;

  let startTime = null;
  let endTime = null;

  if (scheduleItemObj.type === "reminder" || scheduleItemObj.type === "task") {
    startTime = scheduleItemObj.dateItem.format("YYYY-MM-DDTHH:mm:ss");
  } else if (scheduleItemObj.type === "meeting") {
    startTime = scheduleItemObj.datePeriod.start.format("YYYY-MM-DDTHH:mm:ss");
    endTime = scheduleItemObj.datePeriod.end.format("YYYY-MM-DDTHH:mm:ss");
  }

  if (scheduleItemObj.repeat === 1) {
    scheduleItem.startTime = startTime;
    if (endTime) {
      scheduleItem.endTime = endTime;
    }
  } else {
    if (
      scheduleItemObj.period &&
      scheduleItemObj.period.startDate &&
      scheduleItemObj.period.endDate
    ) {
      scheduleItem.recurrent = setRecurrent(
        scheduleItemObj.repeat,
        scheduleItemObj.payloadObj,
        scheduleItemObj.period.startDate,
        scheduleItemObj.period.endDate
      );
    }
  }

  if (scheduleItemObj.to.length) {
    for (let i = 0; i < scheduleItemObj.to.length; i++) {
      let id = null;
      if (typeof scheduleItemObj.to[i] === "object") {
        id = scheduleItemObj.to[i].id;
      } else {
        id = scheduleItemObj.to[i];
      }

      if (
        (scheduleItemObj.community == null ||
          scheduleItemObj.community.adminId !== id) &&
        id !== USER.Auth.getUserId()
      ) {
        scheduleItem.participants.push({ id: id });
      }
    }
  }
  
  if (scheduleItemObj.assignee.length) {
    for (let i = 0; i < scheduleItemObj.assignee.length; i++) {
      let id = null;
      if (typeof scheduleItemObj.assignee[i] === "object") {
        id = scheduleItemObj.assignee[i].id;
      } else {
        id = scheduleItemObj.assignee[i];
      }
      
      if (
        (scheduleItemObj.community == null ||
          scheduleItemObj.community.adminId !== id) &&
        id !== USER.Auth.getUserId()
      ) {
        scheduleItem.assignee.push({ id: id });
      }
    }
  }

  return scheduleItem;
};

const prepareScheduleItemResponse = (scheduleItemObj) => {
  //1. Добавить подготовку ответа

  let scheduleItem = {
    id:           scheduleItemObj.id,
    idOfSeries:   scheduleItemObj.idOfSeries,
    title:        scheduleItemObj.title ? scheduleItemObj.title : '',
    description:  scheduleItemObj.description ? scheduleItemObj.description : '',
    type:         scheduleItemObj.type.toLowerCase(),
    participants: scheduleItemObj.participants, // TODO: This is participiants or assignee?
    to:           scheduleItemObj.participants.map(item => new ContactClass(item)) || [],
    files:        scheduleItemObj.files || [],
    repeat:       scheduleItemObj.recurrent ? setRepeat(scheduleItemObj.recurrent) : 1, // TODO: Test this param
    permission:   {},
    community:    scheduleItemObj.community ? scheduleItemObj.community : null,
    dayOfMonth:   1,
    status:       scheduleItemObj.status ? scheduleItemObj.status : $enum.SCHEDULE_EVENT_STATUS.INVITED
  };
  
  if (scheduleItemObj.assignee) {
    scheduleItem.assignee = scheduleItemObj.assignee.map((item) => new ContactClass(item));
  }

  if (scheduleItemObj.recurrent) {
    scheduleItem.period = {
      startDate: DateTime(scheduleItemObj.recurrent.startDate + scheduleItemObj.recurrent.startTime),
      endDate:   DateTime(scheduleItemObj.recurrent.endDate + scheduleItemObj.recurrent.endTime),
    };
  }

  if (scheduleItem.type === "reminder" || scheduleItem.type === "task") {
    scheduleItem.dateItem = DateTime(scheduleItemObj.startTime);
  }

  if (scheduleItem.type === "meeting") {
    scheduleItem.datePeriod = {
      start: DateTime(scheduleItemObj.startTime),
      end: DateTime(scheduleItemObj.endTime),
    };
  }

  if (scheduleItemObj.idOfSeries) {
    scheduleItem.idOfSeries = scheduleItemObj.idOfSeries;
  }

  return scheduleItem;
};

export default {
  addScheduleItem: ({ scheduleItem }) => {
    const postData = prepareScheduleItemRequest(scheduleItem);
    return new MethodInfo({
      type: "POST",
      url: "api://v1/cmm-api/appointment",
      post: postData,
      // responsePrepare: (data) => {
      //   return prepareScheduleItemResponse(data)
      // });
    });
  },

  getScheduleList: () => {
    return new MethodInfo({
      type: "GET",
      url: "api://v1/cmm-api/appointment",
      // get: {
      //   communityId
      // },
      responsePrepare: (res) => {
        // console.log('getScheduleList: ', res);
        return res.map(prepareScheduleItemResponse);
      },
    });
  },

  getScheduleItem: ({ scheduleId }) => {
    return new MethodInfo({
      type: "GET",
      url: `api://v1/cmm-api/appointment/${scheduleId}`,
      responsePrepare: prepareScheduleItemResponse,
    });
  },

  deleteScheduleItem: ({ scheduleId }) => {
    return new MethodInfo({
      type: "DELETE",
      url: `api://v1/cmm-api/appointment/${scheduleId}`,
    });
  },

  editScheduleItem: ({ scheduleId, scheduleItem }) => {
    return new MethodInfo({
      type: "PUT",
      url: `api://v1/cmm-api/appointment/${scheduleId}`,
      post: prepareScheduleItemRequest(scheduleItem),
    });
  },

  setStatusScheduleItem: ({ scheduleId, status }) => {
    return new MethodInfo({
      type: "POST",
      url: `api://v1/cmm-api/appointment/${scheduleId}/makeDescision`,
      post: {
        status: status, // "status": "ACCEPTED" || "DECLINED" || "WAITING",
      },
    });
  },
};
