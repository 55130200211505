
import { reactive } from 'vue';
import RoleClass from "@user/class/RoleClass";
import {ROLE_AUTH} from "@permission/roleName";

const getDefault = () =>  {
  return {
    //
    companyName          :  '',
    countryOfResidence   :  '',
    hobbiesAndInterests  :  '',
    birthday             :  null,
    communitiesEntity    :  [],
    familyStatus         :  '',
    firstName            :  '',
    gender               :  '',
    lastName             :  '',
    login                :  '',
    mail                 :  '',
    additionalMail       :  '',
    //password             :  '',
    profilePhoto         : null
  };
};

const profile = reactive(getDefault());

export default {
  store: profile,
  getDefault: getDefault,
  methods: {
    //
    getName()       { return profile.name; },
    getEmail()      { return profile.mail; },
    getProfileObj() { return profile; },

    setProfileObj(data) {
      profile.companyName          = data.companyName ? data.companyName : '';
      profile.countryOfResidence   = data.countryOfResidence ? data.countryOfResidence : '';
      profile.hobbiesAndInterests  = data.hobbiesAndInterests ? data.hobbiesAndInterests : '';
      profile.birthday             = data.birthday ? data.birthday : null;
      profile.communitiesEntity    = data.communitiesEntity ? data.communitiesEntity : [];
      profile.familyStatus         = data.familyStatus ? data.familyStatus : '';
      profile.firstName            = data.firstName ? data.firstName : '';
      profile.gender               = data.gender ? data.gender : '';
      profile.lastName             = data.lastName ? data.lastName : '';
      profile.login                = data.login ? data.login : '';
      profile.mail                 = data.mail ? data.mail : '';
      profile.additionalMail       = data.additionalMail ? data.additionalMail : '';
      // profile.password             = data.password ? data.password : '';
      profile.profilePhoto         = data.profilePhoto ? data.profilePhoto : null;

    }
  }
};
