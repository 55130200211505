
// базовые
export const ROLE_ANONYMOUS         = 'ROLE_ANONYMOUS';
export const ROLE_AUTH              = 'ROLE_AUTH';
export const ROLE_PRE_AUTH          = 'ROLE_PRE_AUTH';


// пользовательские
export const ROLE_ADMIN             = 'admin';

