<template>
  <div class="sidebar-community-contact-list sccl">
    <perfect-scrollbar>
      <template v-for="(contact, i) in participants" :key="i">
      <!-- TODO: выделение при раскрытом дропдауне, не выбирая элемент -->
      <div
        class="sccl-item"
        :class="{
          'sccl-item--selected':
            contactActive?.id === contact.id ||
            contactSelectedWithContexMenu?.id === contact.id,
        }"
        @click="toggleContactSelected(contact)"
      >
        <Avatar
          class="sccl-item-avatar"
          :color="contact.color"
          :image="contact.profilePhoto"
          :name="contact.fullname"
        />
        <p class="sccl-item-name">
          {{ contact.fullname }}
        </p>
        <template v-if="contact.isExternal">
          <!-- prettier-ignore -->
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sccl-icon-external'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.235 17.012A7.972 7.972 0 0020 12a7.971 7.971 0 00-1.765-5.012A8 8 0 004.701 15.28l.006.011a8 8 0 001.058 1.72A7.985 7.985 0 0012 20a7.985 7.985 0 006.235-2.988zM12.011 18a.05.05 0 01-.022 0 .211.211 0 01-.038-.012.853.853 0 01-.216-.143c-.216-.182-.496-.524-.775-1.082a8.229 8.229 0 01-.595-1.657C10.885 15.037 11.433 15 12 15s1.114.037 1.635.105a8.229 8.229 0 01-.595 1.657c-.279.558-.56.9-.775 1.082a.853.853 0 01-.216.142.211.211 0 01-.038.013zm1.944-4.869C13.325 13.045 12.67 13 12 13s-1.325.045-1.955.13a13.956 13.956 0 010-2.26c.63.085 1.284.13 1.955.13s1.325-.045 1.955-.13a13.96 13.96 0 010 2.26zm1.633 2.409a10.882 10.882 0 01-.533 1.626 6.027 6.027 0 001.496-1.254c-.3-.137-.621-.262-.963-.372zm2-1.35a10.98 10.98 0 00-1.663-.64 15.907 15.907 0 000-3.098 10.965 10.965 0 001.663-.64c.266.679.412 1.417.412 2.189s-.146 1.51-.412 2.188zM7.45 15.91c.426.495.931.92 1.496 1.254a10.88 10.88 0 01-.533-1.626c-.342.11-.664.235-.963.372zm.626-2.362a15.881 15.881 0 010-3.098 10.963 10.963 0 01-1.663-.64A5.985 5.985 0 006 12c0 .772.146 1.51.412 2.188.512-.248 1.07-.463 1.663-.639zm-.626-5.46c.3.137.621.262.963.372a10.88 10.88 0 01.533-1.626A6.03 6.03 0 007.45 8.09zm2.916.806c.16-.636.364-1.195.595-1.657.279-.558.56-.9.775-1.082a.852.852 0 01.216-.142A.215.215 0 0112 6l.01.001a.215.215 0 01.039.013c.04.016.113.054.216.142.216.182.496.524.775 1.082.23.462.435 1.02.595 1.657C13.115 8.963 12.567 9 12 9s-1.114-.037-1.635-.105zm5.223-.434c.342-.11.664-.235.963-.371a6.029 6.029 0 00-1.496-1.255c.209.494.389 1.04.533 1.626z"/></svg>
        </template>
        <template v-if="contact.role === 'ADMIN'">
          <!-- prettier-ignore -->
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sccl-icon-external'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 6.286l-1.058 2.006A3 3 0 018.8 9.848l-2.234.386 1.58 1.626a3 3 0 01.818 2.518l-.323 2.245 2.035-1a3 3 0 012.648 0l2.035 1-.323-2.245a3 3 0 01.818-2.518l1.58-1.626-2.234-.386a3 3 0 01-2.142-1.556L12 6.286zm.884-2.61a1 1 0 00-1.768 0L9.173 7.358a1 1 0 01-.714.519l-4.102.71a1 1 0 00-.547 1.682l2.902 2.985a1 1 0 01.273.84l-.593 4.12a1 1 0 001.431 1.04l3.736-1.837a1 1 0 01.882 0l3.736 1.837a1 1 0 001.431-1.04l-.593-4.12a1 1 0 01.273-.84l2.902-2.985a1 1 0 00-.547-1.682l-4.102-.71a1 1 0 01-.714-.519l-1.943-3.682z"/><path d="M11.116 3.676a1 1 0 011.768 0l1.943 3.682a1 1 0 00.714.519l4.102.71a1 1 0 01.547 1.682l-2.902 2.985a1 1 0 00-.273.84l.593 4.12a1 1 0 01-1.431 1.04l-3.736-1.837a1 1 0 00-.882 0l-3.736 1.837a1 1 0 01-1.431-1.04l.593-4.12a1 1 0 00-.273-.84L3.81 10.27a1 1 0 01.547-1.682l4.102-.71a1 1 0 00.714-.519l1.943-3.682z" class="filled"/></svg>
        </template>

        <!-- TODO: fix - клик по раскрытию дропдауна выделяет весь элемент -->
        <!-- TODO: выяснить для чего была нужна логика отслеживания открытого дропдауна -->
        <div class="sccl-icon-menu-wrapper">
          <PopperDropdown
            :placement="'bottom-start'"
            @update:shown="(v) => v || resetContactSelectedWithContextMenu()"
          >
            <!-- prettier-ignore -->
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" @click.stop="contactSelectedWithContexMenu = contact" v-bind:svg-inline="''" v-bind:class="'sccl-icon-menu'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 6a2 2 0 114 0 2 2 0 01-4 0zm0 6a2 2 0 114 0 2 2 0 01-4 0zm2 4a2 2 0 100 4 2 2 0 000-4z"/></svg>

            <!-- TODO: логика закрытия при клике по пункту меню, временный вариант hideAllPoppers -->
            <template #popper="{ hide }">
              <!-- TODO использовать общий компонент с параметрами (они похожи) -->
              <!-- <component
                v-if="communityActive"
                :is="
                  communityActive.id === 1
                    ? 'SidebarMenuContact'
                    : 'SidebarMenuContactCommunity'
                "
              /> -->
              <ContextMenu
                :menuList="prepareMenuList(contact.isExternal)"
                :hide="hide"
              />
            </template>
          </PopperDropdown>
        </div>
      </div>
    </template>
    </perfect-scrollbar>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import ContextMenu from '@component/UI/ContextMenu';
import PopperDropdown from '@component/Popper/PopperDropdown';
import SidebarMenuContact from '@component/Part/Sidebar/SidebarMenuContact';
import SidebarMenuContactCommunity from '@component/Part/Sidebar/SidebarMenuContactCommunity';
import Avatar from '@component/Avatar';
import { hideAllPoppers } from 'floating-vue';

import { communityGetSelected } from '@store/community.store';
import {
  contactDeleteFromCommunity,
  contactGetSelected,
} from '@store/communityContact.store';

export default {
  name: 'SidebarCommunityContact',
  components: {
    PopperDropdown,
    Avatar,
    ContextMenu,

    // TODO: использовать один универсальный компонент
    SidebarMenuContact,
    SidebarMenuContactCommunity,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    communityActive: {
      type: Object,
      required: false, // TODO: не всегда есть, проверить
    },
    participants: {
      type: Array,
      required: true,
    },
    contactActive: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      community: communityGetSelected(),
      contactSelected: contactGetSelected(),
      // Получаем контакт, котором открыли контекстное меню
      contactSelectedWithContexMenu: null,
    };
  },
  methods: {
    // contactDeleteFromCommunity(this.contactSelected.id)
    prepareMenuList(isExternal = false) {
      const emitEvent = (event) => {
        this.$emit(event, this.contactSelectedWithContexMenu);
      };

      let menuList = [];

      if (this.communityActive.name === 'Personal') {
        // Основное контекстное меню для контакта

        // console.log(isExternal);

        if (!isExternal) {
          menuList.push({
            name: 'Start chat',
            click: () => emitEvent('startChat'),
          });
        }

        // prettier-ignore
        menuList = menuList.concat([
          // { name: "Turn off user notification", click: () => {} },
          { name: "Send email",                 click: () => emitEvent('emailSend') },
          // { name: "Send file",                  click: () => {} },
          // { name: "Create meeting",             click: () => {} },
          // { name: "Assign task",                click: () => {} },
          // { name: "Attach board",               click: () => {} },
          { name: "View contact",               click: () => emitEvent('contactView') },
          { name: "Edit contact",               click: () => emitEvent('contactEdit') },
          { name: "Delete contact",             click: () => emitEvent('contactDelete'), class: 'text-red'}
        ]);
      } else {
        // Контекстное меню для контакта в рамках комьюнити
        // prettier-ignore
        menuList = menuList.concat([
          // { name: "Add to community",      click: () => {} },
          // { name: "Add to schedule",       click: () => {} },
          { name: "Start chat",            click: () => emitEvent('startChat') },
          { name: "Send email",            click: () => emitEvent('emailSend') },
          // { name: "Share board",           click: () => {} },
          // { name: "Share file",            click: () => {} },
        ]);

        if (this.community?.adminId === USER.Auth.getUserId()) {
          menuList.push({
            name: 'Delete from community',
            click: () => emitEvent('contactDeleteFromCommunity'),
            class: 'text-red',
          });
        }
      }
      return menuList;
    },
    resetContactSelectedWithContextMenu() {
      this.contactSelectedWithContexMenu = null;
    },
    toggleContactSelected(contactObj) {
      this.$emit(
        'selected',
        contactObj.id !== this.contactSelected?.id ? contactObj : null
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.sccl {
  cursor: pointer;
  max-height: calc(100% - 73px);
  
  .ps {
    height: 100%;
  }

  .sccl-item {
    padding: 12px 16px;
    display: grid;
    grid-gap: 0 8px;
    grid-template-columns: max-content 1fr min-content;
    align-items: center;
    position: relative;
    &--selected,
    &:hover {
      background: var(--color-gray-04-hover);
      .sccl-icon-external {
        opacity: 0;
      }
    }

    .sccl-icon-menu-wrapper {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      visibility: hidden;
      opacity: 0;
      transition: 0.25s ease-in-out;
      cursor: pointer;
    }
    &.sccl-item--selected .sccl-icon-menu-wrapper,
    &.sccl-item:hover .sccl-icon-menu-wrapper {
      visibility: visible;
      opacity: 1;
    }
  }
  .sccl-item-name {
    font-size: 1rem;
    line-height: 150%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sccl-icon-external {
    fill: var(--color-gray-03);
    transition: 0.25s ease-in-out;
  }
  .sccl-item-avatar {
    @include icon-size(2);
  }

  .sccl-icon-menu {
    display: block;
    fill: var(--color-secondary-light);
  }
}
</style>
