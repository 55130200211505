<template>
  <div class="main-nav">
    <!-- <router-link
      v-for="item in menuNavList"
      :key="item.key"
      :to="{ name: $routeName[item.key] }"
      :class="[
        'menu-item',
        {
          'menu-item--active': componentName.toUpperCase() === item.key,
          'jitsi-run': jitsiIsRun,
        },
      ]"
      @click="item.onClick"
    >
      <img svg-inline class="menu-icon" :src="item.icon" :alt="item.name" />
      {{ item.name }}
    </router-link> -->

    <div
      class="menu-item"
      :class="{ 'menu-item--active': componentName === 'chat' }"
      @click="openChatPage"
    >
      <div class="menu-icon__wrapper">
        <template v-if="notifyObj['CHAT']">
          <span class="menu-item__notify-indicator"></span>
        </template>
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'menu-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 12c0-2.809 0-4.213.674-5.222a4 4 0 011.104-1.104C6.787 5 8.19 5 11 5h2c2.809 0 4.213 0 5.222.674.437.292.812.667 1.104 1.104C20 7.787 20 9.19 20 12c0 2.809 0 4.213-.674 5.222a4.003 4.003 0 01-1.104 1.104c-.563.377-1.25.543-2.237.616L14.5 21l-1.443-2H11c-2.809 0-4.213 0-5.222-.674a4.002 4.002 0 01-1.104-1.104C4 16.213 4 14.81 4 12zm13 0a1 1 0 10-2 0 1 1 0 002 0zm-4 0a1 1 0 10-2 0 1 1 0 002 0zm-5-1a1 1 0 110 2 1 1 0 010-2z"/></svg>
      </div>
      Chat
    </div>

    <div
      class="menu-item"
      :class="{
        'menu-item--active': componentName === 'meeting',
        'jitsi-run': jitsiIsRun,
      }"
      @click="openJitsiPage"
    >
      <!-- prettier-ignore -->
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'menu-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 6H2v10a3 3 0 003 3h10V9a3 3 0 00-3-3zm10 1l-6 2.75v5.5L22 18V7z"/></svg>
      Meeting
    </div>

    <router-link
      class="menu-item"
      :class="{ 'menu-item--active': componentName === 'board' }"
      :to="{ name: $routeName.BOARD }"
      @click="readPart('BOARD')"
    >
      <div class="menu-icon__wrapper">
        <template v-if="notifyObj['BOARD']">
          <span class="menu-item__notify-indicator"></span>
        </template>
        <!-- prettier-ignore -->
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'menu-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7 5a3 3 0 00-3 3v6.5h7a1 1 0 110 2H4v.5a3 3 0 003 3h10a3 3 0 003-3v-7a3 3 0 00-3-3h-2.172a2 2 0 01-1.414-.586l-.828-.828A2 2 0 0011.172 5H7z"/></svg>
      </div>
      Board
    </router-link>

    <router-link
      class="menu-item"
      :class="{ 'menu-item--active': componentName === 'schedule' }"
      :to="{ name: $routeName.SCHEDULE }"
      @click="readPart('SCHEDULE')"
    >
      <div class="menu-icon__wrapper">
        <template v-if="notifyObj['SCHEDULE']">
          <span class="menu-item__notify-indicator"></span>
        </template>
        <!-- prettier-ignore -->
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'menu-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.92 3.692l.002.674c2.448.195 4.066 1.884 4.068 4.475l.01 7.583c.003 2.825-1.75 4.563-4.558 4.567L8.579 21c-2.791.004-4.566-1.776-4.57-4.608L4 8.897c-.003-2.607 1.557-4.292 4.005-4.52v-.674c-.001-.396.288-.694.675-.694.386 0 .675.296.676.692l.001.63 5.213-.008v-.63c-.002-.395.288-.692.674-.693.377 0 .676.296.677.692zM5.353 9.176l13.288-.018v-.315c-.038-1.934-.996-2.949-2.716-3.1v.693a.68.68 0 01-.675.693.673.673 0 01-.676-.692l-.001-.728-5.213.007v.728c0 .387-.288.693-.674.693a.672.672 0 01-.677-.692v-.692c-1.712.173-2.659 1.192-2.656 3.143v.28zm9.528 4.088v.01a.727.727 0 00.747.719c.395-.01.71-.353.701-.767a.742.742 0 00-.73-.718.741.741 0 00-.718.756zm.725 4.039a.759.759 0 01-.73-.764.746.746 0 01.718-.766h.01c.412 0 .746.34.746.763a.755.755 0 01-.744.767zm-4.34-4.025a.735.735 0 00.755.719.737.737 0 00.692-.775.73.73 0 00-.73-.718.764.764 0 00-.718.774zm.758 3.984c-.403.018-.737-.305-.755-.718 0-.414.315-.756.718-.775.395 0 .72.314.73.718a.737.737 0 01-.693.775zM7.648 13.31c.018.414.352.737.756.718a.735.735 0 00.692-.774.728.728 0 00-.73-.719.763.763 0 00-.718.775zm.76 3.957c-.404.019-.738-.305-.756-.719-.001-.414.315-.756.719-.774.395-.001.72.313.729.718a.736.736 0 01-.693.775z"/></svg>
      </div>
      Schedule
    </router-link>

    <router-link
      class="menu-item"
      :class="{ 'menu-item--active': componentName === 'email' }"
      :to="{ name: $routeName.EMAIL }"
      @click="readPart('EMAIL')"
    >
      <div class="menu-icon__wrapper">
        <template v-if="notifyObj['EMAIL']">
          <span class="menu-item__notify-indicator"></span>
        </template>
        <!-- prettier-ignore -->
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'menu-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.586 6.586C4 7.172 4 8.114 4 10v4c0 1.886 0 2.828.586 3.414C5.172 18 6.114 18 8 18h8c1.886 0 2.828 0 3.414-.586C20 16.828 20 15.886 20 14v-4c0-1.886 0-2.828-.586-3.414C18.828 6 17.886 6 16 6H8c-1.886 0-2.828 0-3.414.586zm7.062 7.066a.5.5 0 00.704 0l4.846-4.797A.5.5 0 0016.846 8H7.154a.5.5 0 00-.352.855l4.846 4.797z"/></svg>
      </div>
      E-mail
    </router-link>

    <!--    <div class="notify" :disabled="isShown" @click="toggleNotificationsState">-->
    <!--      <img-->
    <!--        svg-inline-->
    <!--        class="notify-active"-->
    <!--        src="@img/icon/notification.svg"-->
    <!--        alt="notify"-->
    <!--      />-->
    <!--    </div>-->
  </div>
</template>

<script>
import { jitsiIsRun, jitsiGetRoomName } from '@store/jitsi.store';
import { communityGetSelected } from '@store/community.store';
import { contactGetSelected } from '@store/communityContact.store';

import timerRepeat from '@service/timerRepeat';

export default {
  name: 'MainNav',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //
      notifyObj: { BOARD: false, EMAIL: false, SCHEDULE: false, CHAT: false },
      timerRun: null,
    };
  },
  methods: {
    toggleNotificationsState() {
      this.$emit('toggleNotifications');
    },
    async openChatPage() {
      this.readPart('CHAT');
      //
      const communityObj = communityGetSelected();
      if (communityObj && communityObj.value && communityObj.value.id) {
        this.$router.push({
          name: this.$routeName.CHAT_COMMUNITY,
          params: { chatId: communityObj.value.id },
        });
        return;
      }

      //
      const contactObj = contactGetSelected();
      if (contactObj && contactObj.value && contactObj.value.id) {
        // Устанавливаем выделение с переходом на экран чата с пользователем
        this.$router.push({
          name: this.$routeName.CHAT_PERSONAL,
          params: { chatId: contactObj.value.id },
        });
        return;
      }

      this.$router.push({ name: this.$routeName.CHAT });
    },

    openJitsiPage() {
      if (this.jitsiIsRun) {
        this.$router.push({
          name: this.$routeName.MEETING,
          params: { id: jitsiGetRoomName() },
        });
      } else {
        const getRandomStr = () => {
          const getRnd8 = () => {
            return (Math.random() + 1).toString(36).substring(7);
          };
          return getRnd8() + '-' + getRnd8() + getRnd8() + '-' + getRnd8() + getRnd8() + '-' + getRnd8();
        };
        /*
        const getRandomStrV2 = () => {
          const chars = 'abcdefghijklmnopqrstuvwxyz';
          const string_length = 32;
          let randomstring = '';
          for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
          }
          return randomstring;
        };
        */
        let str = getRandomStr();
        this.$router.push({
          name: this.$routeName.MEETING,
          params: { id: str },
        });
      }
    },

    readPart(partName) {
      RequestManager.Notification.readShortNotification({
        part: partName,
      }).then((success) => {
        this.notifyObj[partName] = false;
      });
    },
  },
  computed: {
    jitsiIsRun() {
      return jitsiIsRun();
    },
    componentName() {
      const arr = this.$route.fullPath.split('/');
      const str = arr[1] ? arr[1] : 'default';
      const arr1 = str.split('?');
      return arr1[0];
    },
    // menuNavList() {
    //   return [
    //     {
    //       name: 'Chat',
    //       onClick: () => openChatPage(),
    //       icon: require('@img/icon/chat.svg'),
    //       key: 'CHAT',
    //     },
    //     {
    //       name: 'Meeting',
    //       onClick: () => openJitsiPage(),
    //       icon: require('@img/icon/meeting.svg'),
    //       key: 'MEETING',
    //     },
    //     {
    //       name: 'Board',
    //       onClick: () => readPart('BOARD'),
    //       icon: require('@img/icon/board.svg'),
    //       key: 'BOARD',
    //     },
    //     {
    //       name: 'Schedule',
    //       onClick: () => readPart('SCHEDULE'),
    //       icon: require('@img/icon/calendar.svg'),
    //       key: 'SCHEDULE',
    //     },
    //     {
    //       name: 'E-mail',
    //       onClick: () => readPart('EMAIL'),
    //       icon: require('@img/icon/email.svg'),
    //       key: 'EMIAL',
    //     },
    //   ];
    // },
  },

  mounted() {
    this.timerRun = timerRepeat();
    this.timerRun.setFunction(() => {
      return new Promise(
        (resolveTimeoutFunc) => {
          RequestManager.Notification.getShortNotification().then(
            (notifyObj) => {

              this.notifyObj.BOARD = !!notifyObj.BOARD;
              this.notifyObj.EMAIL = !!notifyObj.EMAIL;
              this.notifyObj.SCHEDULE = !!notifyObj.SCHEDULE;
              this.notifyObj.CHAT = !!notifyObj.CHAT;

              resolveTimeoutFunc({});
            }
          );
        },
        (error) => {
          resolveTimeoutFunc({});
        }
      );
    });

    // reload disable
    setTimeout(() => {
      this.timerRun.run();
    }, 100);
  },
  unmounted() {
    // reload disable
    this.timerRun.stop();
    setTimeout(() => {
      this.timerRun.stop();
    }, 6000);
  },
};
</script>

<style lang="scss" scoped>
svg {
  fill: var(--color-gray-04);
}

.main-nav {
  display: flex;
  margin-left: auto;
  .menu-item__notify-indicator {
    width: 6px;
    height: 6px;
    top: 3px;
    left: 14px;
    border-radius: 100%;
    display: block;
    position: absolute;
    background-color: var(--color-red-04);
  }
  .menu-item {
    position: relative;
    display: inline-flex;
    padding: 0 8px;
    line-height: 24px;
    font-size: 16px;
    color: var(--color-gray-04);
    align-items: center;
    margin: 0;
    cursor: pointer;
    // & > svg {
    //   fill: var(--color-gray-04);
    // }

    &.menu-item--active {
      margin: 0 10px;
      border-radius: 8px;
      border: 1px solid #babace;
      color: var(--color-secondary);
      background-color: var(--color-gray-04-hover);
      & svg {
        fill: var(--color-secondary);
      }
    }

    &.jitsi-run {
      border: 1px solid var(--color-primary);
      background-color: var(--color-primary);
      color: var(--color-gray-02);
      margin: 0 10px; // temp visual fix
      border-radius: 8px;

      & svg {
        fill: var(--color-secondary);
      }
      //color: var(--color-gray-03);
    }

    &:hover {
      color: var(--color-secondary);
      & svg {
        fill: var(--color-secondary);
      }
    }
  }

  .main-nav__caret {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }
}

.menu-icon {
  margin-right: 4px;
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
}

.notify {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  margin-left: 12px;
  & > svg {
    width: 28px;
    height: 28px;
  }
}

.notify-active {
  & > .new-notify {
    fill: var(--color-primary);
  }
}
</style>
