import * as pageName from "@router/variable";
import LAYOUT from "@layout/layoutName";
import {ROLE_ANONYMOUS, ROLE_AUTH} from "@permission/roleName";

export default [
  {
    path: "/",
    name: pageName.HOME,
    component: () => import("@page/Home"),
    meta: {
      layout: LAYOUT.DEFAULT,
      permission: {
        [ROLE_AUTH]: true,
      },
    },
  },
  {
    path: '/start',
    name: pageName.START,
    component: () => import('@page/Start'),
    meta: {
      layout: LAYOUT.FULLSCREEN,
      permission: {
        [ROLE_ANONYMOUS]: true,
        // [ROLE_AUTH]: true,
      },
    },
  },
  {
    path: "/about",
    name: pageName.ABOUT,
    component: () => import("@page/About"),
    meta: {
      layout: LAYOUT.DEFAULT,
      permission: {
        [ROLE_AUTH]: true,
      },
    },
  },
  {
    path: "/schedule",
    name: pageName.SCHEDULE,
    component: () => import("@page/Schedule/Schedule"),
    meta: {
      layout: LAYOUT.DEFAULT,
      permission: {
        [ROLE_AUTH]: true,
      },
    },
  },
  {
    path: "/meeting/:id",
    name: pageName.MEETING,
    component: () => import("@page/Meeting/Meeting"),
    meta: {
      layout: LAYOUT.DEFAULT,
      permission: {
        [ROLE_AUTH]: true,
      },
    },
    props: (route) => {
      return { id: route.params.id };
    },
  },
];
