
import FileClass              from '@entity/class/FileClass';
import ContactClass           from '@entity/class/ContactClass';
import CommunityContactClass  from '@entity/class/CommunityContactClass';

/** @type {FileClass} */
global.FileClass = FileClass;
global.ContactClass = ContactClass;
//
global.CommunityContactClass = CommunityContactClass;

export default {};
