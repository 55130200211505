
<script>
import { defineComponent } from 'vue'
import { PopperWrapper } from 'floating-vue'

// import PopperWrapper from './PopperWrapper.vue'
export default defineComponent({
  ...PopperWrapper,
  vPopperTheme: 'dropdown',
  inheritAttrs: true
})
</script>
