<template>
  <div>
    <div class="th-px" style="margin: 0 0 24px">
      <FveForm class="mdl-community__form">
        <FveImagePreview
          :field="field.profilePhoto"
          name="profilePhoto"
          class="mdl-community__image"
        />
        <FveText
          :field="field.firstName"
          class="mdl-community__field"
          label="First Name"
          name="firstName"
          placeholder="Type First Name"
        />
        <FveText
          :field="field.lastName"
          label="Last Name"
          class="mdl-community__field"
          name="lastName"
          placeholder="Type Last Name"
        />
      </FveForm>
    </div>
    <div class="btn-group th-px">
      <!-- prettier-ignore -->
      <button type="button" class="btn btn--outline" @click="close">Discard</button>
      <!-- prettier-ignore -->
      <button type="button" class="btn btn--primary" @click="submit">Save</button>
    </div>
  </div>
</template>

<script>
import FveMixinForm from "@fve/Mixin/FveMixinForm";
import FveText from "@fve/Element/Text/FveText";
import FveImagePreview from "@fve/Element/File/FveImagePreview";

export default {
  name: "ContactAddForm",
  components: {
    FveText,
    FveImagePreview,
  },
  mixins: [FveMixinForm],
  methods: {
    formSchema() {
      return {
        profilePhoto: {
          type: FileClass,
          default: () => {
            return new FileClass();
          },
        },
        firstName: {
          type: String,
          default: () => { return ''; },
          field: {
            required: true,
          },
        },
        lastName: {
          type: String,
          default: () => { return ''; },
          field: {
            required: true,
          },
        },
      };
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.mdl-community__form {
  display: grid;
  grid-gap: 0.875rem 1rem;
  align-items: flex-start;
  grid-template-columns: 3rem 1fr;

  .mdl-community__field {
    grid-column: 2 / 3;
  }

  .mdl-community__image {
    @include icon-size(3);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
}
</style>
