<template>
  <div class="file-storage fs" ref="fileStorage">
    <!-- prettier-ignore -->
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="$emit('close')" v-bind:class="'fs__close'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.657 7.757a1 1 0 00-1.414-1.414L12.707 9.88a1 1 0 01-1.414 0L7.757 6.343a1 1 0 00-1.414 1.414l3.536 3.536a1 1 0 010 1.414l-3.536 3.536a1 1 0 101.414 1.414l3.536-3.536a1 1 0 011.414 0l3.536 3.536a1 1 0 101.414-1.414l-3.536-3.536a1 1 0 010-1.414l3.536-3.536z"/></svg>
    <div class="fs__header">
      <p class="fs__title">File Storage</p>
      <PopperDropdown @update:shown="(v) => fileStorageToggleAutoHide(!v)">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'fs__filter'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.449 17.426L10.105 19v-5h3.344v3.426zM14.733 12H8.924L5.278 7.98A1.08 1.08 0 015 7.253v-1.19C5 5.474 5.46 5 6.03 5h11.94c.57 0 1.03.475 1.03 1.062v1.164c0 .287-.11.56-.308.76L14.732 12z"/></svg>
        <template #popper>
          <FileStorageFilter />
        </template>
      </PopperDropdown>
    </div>
    <FveText
      :field="{
        name: 'search',
        model: true,
      }"
      v-model="searchValue"
      label="Search"
      placeholder="Type file name"
      class="fs__search"
    />
    <div class="fs__tab__list">
      <template v-for="tab in tabList" :key="tab">
        <div
          @click="selectTab(tab)"
          :class="[
            'fs__tab__item',
            { 'fs__tab__item--active': selectedTab === tab },
          ]"
        >
          {{ tab }}
        </div>
      </template>
    </div>
    <Dropzone @onDrop="selectFileList">
      <div class="fs__list__wrapper">
        <template v-if="!fileStoragePreloaderShown">
          <template v-if="currentFiles.length">
            <perfectScrollbar class="fs__list__ps">
              <div class="fs__list">
                <DropzoneDraggable
                  v-model:items="currentFiles"
                  behaviour="copy"
                  orientation="vertical"
                >
                  <template #item="{ item }">
                    <div class="fs__item">
                      <FileIcon :file="item" class="fs__item__file" />
                      <p class="fs__item__name">{{ item.filename }}</p>
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="fileStorageGetPreview(item)" v-bind:class="'fs__item__icon fs__item__show'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.89 9.89C10.485 9.298 11.188 9 12 9s1.516.297 2.11.89c.593.594.89 1.297.89 2.11s-.297 1.516-.89 2.11c-.594.593-1.297.89-2.11.89s-1.516-.297-2.11-.89C9.298 13.515 9 12.812 9 12s.297-1.516.89-2.11zm-1.453 5.672c1 .97 2.188 1.454 3.563 1.454s2.547-.485 3.516-1.453c1-1 1.5-2.188 1.5-3.563s-.5-2.547-1.5-3.516c-.97-1-2.141-1.5-3.516-1.5s-2.563.5-3.563 1.5c-.968.97-1.453 2.141-1.453 3.516s.485 2.563 1.454 3.563zm-3.14-9C7.297 5.188 9.53 4.5 12 4.5c2.469 0 4.703.688 6.703 2.063 2 1.375 3.438 3.187 4.313 5.437-.875 2.25-2.313 4.063-4.313 5.438-2 1.375-4.234 2.062-6.703 2.062-2.469 0-4.703-.688-6.703-2.063-2-1.375-3.438-3.187-4.313-5.437.875-2.25 2.313-4.063 4.313-5.438z"/></svg>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="download(item)" v-bind:class="'fs__item__icon fs__item__download'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.064 4a1 1 0 011 1v7.425l.676-.678a1 1 0 111.417 1.41l-2.384 2.395a.994.994 0 01-.944.267.993.993 0 01-.473-.267L8.97 13.158a1 1 0 011.417-1.411l.676.678V5a1 1 0 011-1z" fill="#BABACE"/><path d="M16.616 9.87c1.111 0 2.012.9 2.012 2.014v3.993a2.005 2.005 0 01-2.005 2.005h-9.11A2.013 2.013 0 015.5 15.87v-3.994c0-1.107.898-2.005 2.005-2.005h.77a1 1 0 000-2h-.77A4.005 4.005 0 003.5 11.875v3.994a4.013 4.013 0 004.013 4.014h9.11a4.005 4.005 0 004.005-4.006v-3.993a4.012 4.012 0 00-4.012-4.014h-.764a1 1 0 000 2h.764z" fill="#BABACE"/></svg>
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="remove(item)" v-bind:class="'fs__item__icon fs__item__trash'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18.906 6.757c.324 0 .594.274.594.623v.323c0 .341-.27.624-.594.624H5.095c-.325 0-.595-.283-.595-.624V7.38c0-.349.27-.623.595-.623h2.43c.493 0 .923-.358 1.034-.863l.127-.58C8.884 4.524 9.535 4 10.279 4h3.442c.736 0 1.394.524 1.585 1.272l.136.62c.11.507.54.865 1.034.865h2.43z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6 10.61a.602.602 0 01.15-.43.559.559 0 01.41-.18h10.885c.156 0 .298.067.41.18.105.121.158.271.142.43 0 .053-.394 4.996-.633 7.182-.15 1.341-1.03 2.156-2.352 2.178-1.015.022-2.008.03-2.986.03-1.038 0-2.053-.008-3.038-.03-1.277-.03-2.158-.859-2.3-2.178-.197-1.76-.515-5.284-.637-6.636L6 10.61zm9.777 2.622c.038-.441.073-.86.103-1.232H8.135l.112 1.214c.14 1.517.308 3.28.429 4.355v.009c.028.257.107.33.11.333a.136.136 0 00.047.027c.03.012.094.03.202.033.964.021 1.963.029 2.991.029.967 0 1.945-.008 2.943-.03h.01a.742.742 0 00.241-.036c.034-.013.046-.023.048-.026.003-.003.02-.017.04-.061a.89.89 0 00.068-.275c.116-1.066.273-2.824.401-4.34z"/></svg>
                    </div>
                  </template>
                </DropzoneDraggable>
              </div>
            </perfectScrollbar>
          </template>
          <template v-else>
            <p style="color: var(--color-secondary-light)">Not found</p>
          </template>
        </template>
        <template v-else>
          <p>Loading...</p>
        </template>
      </div>
    </Dropzone>
    <input
      :id="`input-file-${_.uid}`"
      type="file"
      @change="(event) => selectFileList(event.target.files)"
      ref="inputFile"
      hidden
    />
    <div class="fs__btn">
      <button
        class="btn btn--primary"
        v-if="selectedTab === 'Personal'"
        @click="$refs.inputFile.click()"
      >
        Upload
      </button>
    </div>
  </div>
</template>

<script>
import DropzoneDraggable from '@component/DropzoneDraggable';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import FveText from '@fve/Element/Text/FveText';
import FileIcon from '@component/FileIcon';
import FileStorageFilter from '@component/FileStorage/FileStorageFilter';
import PopperDropdown from '@component/Popper/PopperDropdown';
import { debounce } from 'lodash';
import Dropzone from '@component/Dropzone';
import {
  fileStorageFilterState,
  fileStoragePreloaderShown,
  fileStoragePreloaderToggle,
  fileStorageToggleAutoHide,
  fileStorageClearFilterState,
  fileStorageRemoveByName,
  fileStorageAddToList,
  fileStorageGetList,
  fileStorageSetList,
  fileStorageList,
  fileStorageGetPreview,
} from '@store/fileStorage.store';
export default {
  components: {
    PerfectScrollbar,
    FveText,
    FileIcon,
    PopperDropdown,
    FileStorageFilter,
    Dropzone,
    DropzoneDraggable,
  },
  setup() {
    const fileStorageList = fileStorageGetList();
    return {
      fileStorageGetPreview,
      fileStoragePreloaderShown,
      fileStoragePreloaderToggle,
      fileStorageFilterState,
      fileStorageList,
      fileStorageToggleAutoHide,
      fileStorageClearFilterState,
    };
  },
  data() {
    return {
      searchValue: '',
      selectedTab: 'Personal',
      tabList: ['Personal', 'Shared'],
      currentFiles: fileStorageList.value || [],
    };
  },
  watch: {
    fileStorageFilterState: {
      handler: function () {
        this.getFileList();
      },
      deep: true,
    },
    selectedTab(val) {
      fileStoragePreloaderToggle(true);
      if (val === 'Shared') {
        this.getSharedFileList();
      } else {
        this.getFileList();
      }
    },
    searchValue() {
      fileStoragePreloaderToggle(true);
      if (this.selectedTab === 'Shared') {
        this.getSharedFileList();
      } else {
        if (this.selectedTab === 'Shared') {
          this.getSharedFileList();
        } else {
          this.getFileList();
        }
      }
    },
  },
  mounted() {
    this.getFileList();

    const dataURItoFile = (dataURI, filename) => {
      let arr = dataURI.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    };

    const onPaste = (e) => {
      let items;
      if (e.clipboardData && e.clipboardData.items) {
        items = e.clipboardData.items;

        [].forEach.call(items, (item) => {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onloadend = function (event) {
            console.log(event.target);
            const base64 = event.target.result;
            const dataURI = base64;
            const file = dataURItoFile(dataURI, blob.name);
            this.upload(file);
          }.bind(this);
          reader.readAsDataURL(blob);
        });
      }
    };

    this.$refs.fileStorage.addEventListener('paste', onPaste.bind(this), false);
  },
  methods: {
    async selectFileList(files) {
      const fileList = Array.from(files);

      try {
        for (let i = 0; i < fileList.length; i++) {
          await RequestManager.FileStorage.add({
            file: fileList[i],
          });
        }
        this.getFileList();
      } catch (err) {}
    },
    remove(file) {
      if (!file || !file.filename) {
        return;
      }
      return RequestManager.FileStorage.remove({
        filename: file.filename,
      }).then(() => {
        fileStorageRemoveByName(file.filename);
        this.getFileList();
      });
    },
    upload(file) {
      return RequestManager.FileStorage.add({ file }).then(() => {
        this.getFileList();
      });
    },
    download({ filename, bucketname }) {
      return RequestManager.FileStorage.getFile({ filename, bucketname });
    },
    getFileList: debounce(async function () {
      const filterList = Object.values(fileStorageFilterState.value).flat();
      this.currentFiles = [];

      try {
        const response = await RequestManager.FileStorage.getList({
          extension: filterList.join(',') || null,
          filename: this.searchValue || null,
        });

        this.currentFiles = response;
      } catch (err) {
      } finally {
        fileStoragePreloaderToggle(false);
      }
    }, 250),

    getSharedFileList: debounce(async function () {
      const filterList = Object.values(fileStorageFilterState.value).flat();
      this.currentFiles = [];

      try {
        const response = await RequestManager.FileStorage.getSharedFiles({
          extension: filterList.join(',') || null,
          filename: this.searchValue || null,
        });
        this.currentFiles = response.filter(
          ({ bucketname }) => USER.Auth.getUserId() !== bucketname
        );
      } catch (err) {
      } finally {
        fileStoragePreloaderToggle(false);
      }
    }, 250),

    selectTab($tab) {
      this.selectedTab = $tab;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep() {
  .drop-preview {
    margin: 0.5rem 0;
  }
}
.fs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.5rem;
}
.fs__header {
  display: flex;
  justify-content: space-between;
}

.fs__tab__list {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  border-bottom: 1px solid var(--color-gray-03);
}
.fs__tab__item {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  transition: 0.25s ease-in;
  border: 0;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  position: relative;
  color: var(--color-gray-10);
  &::after {
    content: '';
    bottom: 0;
    height: 2px;
    width: 0%;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    background: var(--color-secondary);
    border-radius: 1px 1px 0 0;
    position: absolute;
  }
  &--active {
    &::after {
      width: 100%;
      transition: 0.25s ease-in-out;
    }
  }
  &:first-child {
    border-radius: 8px 0 0 8px;
    border-right: 0;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    border-left: 0;
  }
}
.fs__close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  fill: var(--color-secondary-light);
  @include icon-size(1);
}

.fs__close,
.fs__filter {
  &:hover {
    fill: var(--color-secondary);
    transition: 0.25s ease-in-out;
    cursor: pointer;
  }
}

.fs__filter {
  fill: var(--color-secondary-light);
  @include icon-size(1.5);
}
.fs__title {
  font-weight: 600;
  color: var(--color-secondary-light);
}
.fs__btn {
  margin-top: 1.5rem;
}

.fs__list__wrapper {
  height: 12rem;
}

.fs__list__ps {
  height: 100%;
}

.fs__list {
  display: flex;
  margin: -0.25rem 0;
  flex-direction: column;
}
.fs__item {
  height: 2.5rem;
  padding: 0 1rem;
  margin: 0.5rem 0;
  border: 1px solid #e0e0ec;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
}

.fs__item__name {
  margin-right: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 70%;
}
.fs__item__icon {
  margin: 0 0.25rem;
  cursor: pointer;
  @include icon-size(1);
}

.fs__search {
  margin: 1rem 0;
}

.fs__item__file {
  margin-right: 0.75rem;
  fill: var(--color-gray-04);
}

.fs__item__show,
.fs__item__download {
  fill: var(--color-secondary-light);
}
.fs__item__trash {
  fill: var(--color-red-03);
}

.fs__btn {
  margin-left: auto;
}
</style>
