<template>
  <div class="sidebar-community-list scl">
    <perfect-scrollbar class="ps-scl">
      <template
        v-for="community in communityList"
        :key="`community-${community.id}`"
      >
        <div
          :class="{
            'scl-item': true,
            'scl-item--active':
              communityActive && communityActive.id === community.id,
          }"
          @click="communitySelect(community)"
          @contextmenu.prevent="communityContextMenu(community)"
        >
          <Avatar
            class="scl-item-img"
            :image="community.communityPhoto"
            :name="community.name"
            :color="community.color"
          />

          <!-- TODO: v-html="community.name" - точно тут нужно? -->
          <p class="scl-item-title">{{ community.name }}</p>
        </div>
      </template>
    </perfect-scrollbar>
    <div class="scl-item scl-item-add" @click="communityAdd">
      <div class="scl-item-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 5a1 1 0 10-2 0v5a1 1 0 01-1 1H5a1 1 0 100 2h5a1 1 0 011 1v5a1 1 0 102 0v-5a1 1 0 011-1h5a1 1 0 100-2h-5a1 1 0 01-1-1V5z"/></svg>
      </div>
      <p class="scl-item-title">Add community</p>
    </div>
    <div class="scl-item-fix"></div>
  </div>
</template>

<script>
import Avatar from '@component/Avatar';
import ContextMenu from '@component/UI/ContextMenu';

export default {
  name: 'SidebarCommunity',
  components: {
    Avatar,
  },
  props: {
    communityList: {
      type: Array,
      default: () => [],
    },
    communityActive: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  methods: {
    communitySelect(communityObj) {
      this.$emit('communitySelect', communityObj);
    },
    communityAdd() {
      this.$emit('communityAdd');
    },
    //

    /**
     * @param {CommunityTypedef} communityObj
     */
    communityContextMenu(communityObj) {
      let dialog = null;
      const dClose = () => {
        dialog && dialog.close();
      };
      // prettier-ignore
      const menuList = [
        // {name: 'Turn off community notification', click: () => { dClose(); } },
        // {name: 'Start group chat',                click: () => { dClose(); } },
        // {name: 'Send email',                      click: () => { dClose(); } },
        // {name: 'Create meeting',                  click: () => { dClose(); } },
        // {name: 'Share file',                      click: () => { dClose(); } },
        // {name: 'Attach board',                    click: () => { dClose(); } },
      ];

      if (communityObj.id !== null && communityObj.myRole !== 'ADMIN') {
        const menuItemLeaveCommunity = {
          name: 'Leave community',
          click: () => {
            dClose();
            this.$emit('communityLeave', communityObj);
          },
          class: 'text-red',
        };
        menuList.push(menuItemLeaveCommunity);
      }

      if (communityObj.myRole === 'ADMIN') {
        // prettier-ignore
        menuList.splice( 1, 0,
          { name: "Edit members",   click: () => { dClose(); this.$emit('communityContactEdit', communityObj); } },
          { name: "Edit community", click: () => { dClose(); this.$emit('communityEdit', communityObj);        } }
        );

        // prettier-ignore
        menuList.push({ name: "Delete community", click: () => { dClose(); this.$emit("communityDelete", communityObj); }, class: "text-red",});
      }

      if (!menuList.length) {
        return;
      }

      const ContextMenuProps = {
        menuList: menuList,
      };
      dialog = this.$dialog.open(ContextMenu, ContextMenuProps, {
        theme: 'menu',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scl {
  display: flex;
  flex-direction: column;
  height: 100%;

  .scl-item {
    position: relative;
    min-height: 92px;
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    &::after {
      content: '';
      top: 50%;
      transform: translateY(-50%);
      height: 0;
      width: 2px;
      z-index: 1;
      right: 0;
      background: var(--color-secondary);
      border-radius: 4px 0px 0px 4px;
      position: absolute;
    }
    &--active,
    &:hover {
      background-color: var(--color-gray-04-hover);
    }
    &--active {
      &::after {
        height: 100%;
        transition: 0.25s ease-in-out;
      }
    }
  }
  .scl-item-title {
    word-break: break-word;
    margin-top: 4px;
    font-size: 12px;
    line-height: 135%;
    text-align: center;
    font-weight: 400;
    .sidebar-community-item__icon + & {
      margin-top: 8px;
    }
  }

  .scl-item-icon {
    background-color: transparentize($color-secondary, 0.6);
    border-radius: 8px;
    @include icon-size(2);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      @include icon-size(1.5);
      display: block;
      path {
        fill: var(--color-secondary);
      }
    }
  }

  .scl-item-img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
  }
  .ps-scl {
    // background-color: #9C27B0;
    max-height: calc(100% - 92px);
  }
  .scl-item-add {
    width: 100%;
    flex: 0 0 92px;
    & > .scl-item-icon {
      flex: 0 0 2rem;
    }
    // background-color: #1BC0B7;
  }
  .scl-item-fix {
    flex: 0 0 0px;
    margin-top: auto;
  }
}
</style>
