<template>
  <section class="v-calendar-schedule__content">
    <div class="v-calendar-schedule__weekdays">
      <div class="v-calendar-schedule__empty"></div>
      <div class="v-calendar-schedule__weekday-item">
        <div>{{ activeDate.format('ddd DD') }}</div>
      </div>
    </div>
    <div class="ps-wrapper">
      <perfect-scrollbar>
        <div class="v-calendar-schedule__days">
          <div class="v-calendar-schedule__day" :class="{ 'is-today': day.isToday }" v-if="day !== null">
            <div class="v-calendar-schedule__day-hour-block"
                 :class="[ time.isSame(now, 'hour') ? 'is-now' : '', hourClass ]" v-for="time in day.availableTimes">
              <span class="v-calendar-schedule__day-hour-block-fill">{{ time.format('h A') }}</span>
                <div class="v-calendar-schedule__hour-content"
                     @click="timeClicked({ date: day.d.toDate(), time: time.hour() })">
                      <template v-if="day.events.length">
                        <template v-for="event in day.events" :key="event.id">
                          <event-item
                              @click.stop="$emit('eventClicked', event)"
                              v-if="event.startTime && time.hour() === event.startTime.hour()"
                              :event="event"/>
                        </template>
                      </template>
                </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </section>
</template>

<script>

import dayjs from 'dayjs';
import EventItem from '../EventItem';
import IsView from '../mixins/IsView';
import ShowsTimes from '../mixins/ShowsTimes';

export default {
  name: "day",
  mixins: [IsView, ShowsTimes],
  components: {EventItem},
  props: {
    events: {
      type: Array
    }
  },
  data() {
    return {
      day: null
    };
  },
  mounted() {
    this.buildCalendar();
  },
  methods: {
    timeClicked(data) {
      this.$emit('timeClicked', data);
    },

    buildCalendar() {
      let now = dayjs();

      const today = dayjs(this.activeDate);

      const dayEvents = this.events.filter(e => dayjs(e.date).isSame(today, 'day'))
          .sort((a, b) => {
            if (!a.startTime) return -1;
            if (!b.startTime) return 1;
            return dayjs(a.startTime, 'HH:mm').format('HH') - dayjs(b.startTime, 'HH:mm').format('HH');
          });

      const mappedEvents = dayEvents.map(event => {
        event.overlaps = dayEvents.filter(e => dayjs(event.startTime, 'HH:mm').isBetween(dayjs(e.startTime, 'HH:mm'), dayjs(e.endTime, 'HH:mm')) && e !== event).length;
        return event;
      });

      this.day = {
        d: today,
        isPast: today.isBefore(now, 'day'),
        isToday: today.isSame(now, 'day'),
        availableTimes: this.times,
        events: mappedEvents
      };

    }
  }
};
</script>

<style lang="scss" scoped>

.ps-wrapper-cell {
  height: 100%;
  & > .ps {
    height: 100%;
  }

}

.ps-wrapper {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 32px;
}

.v-calendar-schedule__weekdays {
  display: flex;
}

.v-calendar-schedule__empty {
  min-width: 44px;
  margin-right: 8px;
}

.v-calendar-schedule__weekday-item {
  width: 100%;
  background-color: var(--schedule-background);
  padding: 8px 0;
  text-align: center;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid var(--schedule-border-color);

  & > div {
    line-height: 1.45;
    font-size: 0.875rem;
    color: var(--color-gray-04);
  }
}

.v-calendar-schedule__content {
  height: 100%;
  padding-bottom: 32px;
}

v-calendar-schedule__day {
  display: flex;
}

.v-calendar-schedule__day-hour-block {
  display: flex;
  min-height: 96px;

  &:last-of-type {
    & > .v-calendar-schedule__hour-content {
      border-radius: 0 0 8px 8px;
    }
  }
}

.v-calendar-schedule__day-hour-block-fill {
  min-width: 44px;
  margin-right: 8px;
  text-align: right;
  font-size: 0.875rem;
  color: var(--color-gray-05);
}

.v-calendar-schedule__hour-content {
  position: relative;
  width: 100%;
  background-color: var(--schedule-background);
  border-bottom: 1px solid var(--schedule-border-color);
  padding-right: 1rem;
}

.v-calendar-schedule__remove-event {
  display: flex;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background-color: var(--color-gray-03);
  }
}

</style>
