<template>
  <div class="v-calendar-schedule">
<!--    <pre>{{ activeViewProps }}</pre>-->
    <template v-if="activeView === 'day'">
      <Day
          :class="'v-cal-content--' + activeView"
          v-bind="activeViewProps"
          @timeClicked="timeClickedEvent"
          @eventClicked="eventClicked"
      />
    </template>
    <template v-else-if="activeView === 'week'">
      <Week
          :class="'v-cal-content--' + activeView"
          v-bind="activeViewProps"
          @timeClicked="timeClickedEvent"
          @eventClicked="eventClicked"
      />
    </template>
    <template v-else-if="activeView === 'month'">
      <Month
          :class="'v-cal-content--' + activeView"
          v-bind="activeViewProps"
          @timeClicked="timeClickedEvent"
          @eventClicked="eventClicked"
      />
    </template>
    <template v-else>
      Unsupported active view type
    </template>

  </div>
</template>

<script>

import Month from './views/Month';
import Week from './views/Week';
import Day from './views/Day';

export default {
  name: "VueScheduler",
  components: {
    Month,
    Week,
    Day,
  },
  props: {
    activeView: { type: String,    default: () => { return 'month'; } },
    minDate:    { type: DateTime,  default: () => { return null; } },
    maxDate:    { type: DateTime,  default: () => { return null; } },

    initialDate: { type: DateTime,  default: () => { return new DateTime(); } },
    initialView: { type: String,    default: () => { return 'month'; } },
    use12:       { type: Boolean,   default: () => { return false; } },
    //
    events:     { type: Array, default: () => [] },
  },
  data() {
    return {
      today: DateTime(),
      activeDate: this.initialDate,
    };
  },
  methods: {
    // клик по пустой ячейке периода (день, час) с исправлением для месяца
    timeClickedEvent(data) {
      let timeClickedGlobal = data;
      if (data instanceof Date) {
        timeClickedGlobal = { date: data, time: DateTime().hour(), };
      }
      this.$emit('timeClickedGlobal', timeClickedGlobal);
    },
    // выбрасываем на редактирование при клике на евент
    eventClicked(data) {
      this.$emit('eventClickedGlobal', data);
    },
    //
    eventRemove(data) {
      this.$emit('eventRemoveClicked', data);
    },
    // action
    goToToday() {
      this.activeDate = this.today.clone();
    },
    goToDate(date) {
      this.activeDate = date.clone();
    },
    prev() {
      switch (this.activeView) {
        case 'day':   this.activeDate = this.activeDate.subtract(1, 'd'); break;
        case 'week':  this.activeDate = this.activeDate.subtract(1, 'w'); break;
        case 'month': this.activeDate = this.activeDate.subtract(1, 'M'); break;
        default:      console.error('un support activeView value'); break;
      }
    },
    next() {
      switch (this.activeView) {
        case 'day':   this.activeDate = this.activeDate.add(1, 'd'); break;
        case 'week':  this.activeDate = this.activeDate.add(1, 'w'); break;
        case 'month': this.activeDate = this.activeDate.add(1, 'M'); break;
        default:      console.error('un support activeView value'); break;
      }
    },
  },
  // TODO: use refs => next prev ???
  // watch: {
  //   activeDate() {
  //     this.$emit(this.activeView + '-changed', this.activeDate.toDate());
  //   },
  // },
  computed: {

    activeViewProps() {
      let props = {
        activeDate: this.activeDate,
        minDate: this.minDate,
        maxDate: this.maxDate,
        use12: this.use12,
        events: this.events,
      };

      if (this.activeView === 'week' || this.activeView === 'day') {
        props.timeRange = [0, 23];
        props.showTimeMarker = true;
      }
      return props;
    },

  }
}
</script>

<style lang="scss" scoped>

.v-calendar-schedule {
  width: 100%;
}

.v-cal-button {
  cursor: pointer;
  background: #fff;
  padding: 8px 18px;
  border: 1px solid #D7DAE2;
  box-shadow: #D7DAE2;
  font-size: 13px;

  &.is-rounded {
    border-radius: 4px;
  }

  &.v-cal-button--is-active {
    background-color: #D7DAE2;
    color: #3B86FF;
  }

  &:hover {
    color: #3B86FF;
    background-color: #fcfcfc;
  }

  &.is-primary {
    border-color: #3B86FF;
    background-color: #3B86FF;
    color: #fff;
  }

  &:disabled,
  &:disabled:hover {
    background-color: #f0f0f0;
    color: #d0d0d0;
    cursor: not-allowed;
    border-color: #D7DAE2;
  }
}

</style>
