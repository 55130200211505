import dayjs from "dayjs";

export default {
  props: {
    timeRange: {
      type: Array,
      required: true,
    },
    showTimeMarker: {
      type: Boolean
    },
    allDayLabel: {
      type: String,
      default: () => 'all day'
    }
  },
  data() {
    return {
      now: dayjs()
    };
  },
  computed: {
    times() {
      let times = [];

      for ( let i = this.timeRange[0]; i < this.timeRange[1] + 1; i++ ) {
        const momentTime = dayjs().hour(i);
        times.push(momentTime);
      }

      return times;
    },
    hourClass() {
      const minutes = Math.round(this.now.minute() / 10) * 10;
      return this.showTimeMarker ? 'has-marker is-' + minutes : '';
    }
  }
};
