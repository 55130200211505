<template>
  <div
    class="dropzone-wrapper"
    ref="dropzone"
    @drop.prevent="onDrop"
    @dragover.prevent
    @dragleave.prevent
  >
    <div v-if="shown" class="dropzone-overlay">
      <p>Drag & drop file here</p>
    </div>
    <div class="dropzone-inner" :style="{ opacity: shown ? 0 : 1 }">
      <slot :hide="hide" :show="show" :shown="shown"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropzone',
  props: {
    // activator: {
    //   typy: String,
    //   default: 'parent',
    // },
  },
  data() {
    return {
      shown: false,
    };
  },
  mounted() {
    const dragenter = (e) => {
      this.shown = true;
      e.stopPropagation();
      e.preventDefault();
    };
    const drop = (e) => {
      this.shown = false;
      e.stopPropagation();
      e.preventDefault();
    };
    const dragleave = (e) => {
      const body = document.body;
      if (
        e.clientX > 0 &&
        e.clientX <= body.clientWidth &&
        e.clientY > 0 &&
        e.clientY <= body.clientHeight
      ) {
        // this.shown = true;
      } else {
        this.shown = false;
      }
      e.stopPropagation();
      e.preventDefault();
    };

    document.addEventListener('dragenter', dragenter);
    document.addEventListener('dragleave', dragleave);
    document.addEventListener('drop', drop);
  },
  // unmounted() {
  //   document.removeEventListener('dragenter', this.dragenter);
  // },
  methods: {
    hide() {
      this.shown = false;
    },
    show({ isSource }) {
      if (isSource) return;
      this.shown = true;
    },
    onDrop(event) {
      // event.preventDefault();
      this.$emit('onDrop', event.dataTransfer.files);
      this.shown = false;
      // console.log(event.dataTransfer.files);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone-wrapper {
  position: relative;
}
.dropzone-overlay {
  position: absolute;
  background-color: var(--color-gray-02);
  border: 1px dashed var(--color-gray-03);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray-04);
  font-size: 1.175rem;
  padding: 10px;
  border-radius: 8px;
  width: -webkit-fill-available;
  min-height: -webkit-fill-available;
  z-index: 999;
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}
</style>
