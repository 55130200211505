<template>
  <section class="v-calendar-schedule__content">
    <div class="v-calendar-schedule__weekdays">
      <div class="v-calendar-schedule__empty"></div>
      <div class="v-calendar-schedule__weekday-wrapper">
        <template v-for="(day, index) in days" :key="index">
          <div
            class="v-calendar-schedule__weekday-item"
            :class="[{ 'is-today': day.d.isToday() }, { 'is-active': day.d.format('ddd DD') === activeDate.format('ddd DD') }]"
          >{{ day.d.format('ddd DD') }}</div>
        </template>
      </div>
    </div>

    <div class="ps-wrapper">
      <perfect-scrollbar>
      <div class="v-calendar-schedule__days">
        <div class="v-calendar-schedule__times">
          <template v-for="(time, index) in times" :key="index">
            <div
              class="v-calendar-schedule__hour"
              :class="{ 'is-now': time.isSame(now, 'hour') }"
            >{{ time.format('h A') }}</div>
          </template>
        </div>

      <div class="v-calendar-schedule__days-wrapper">
        <div class="v-calendar-schedule__day v-cal-day--week" v-for="day in days" :class="{ 'is-today': day.isToday, 'is-disabled': day.isDisabled, 'is-active': day.d.format('ddd DD') === activeDate.format('ddd DD') }">


          <div class="v-calendar-schedule__hour-block"
               @click="timeClicked({ date: day.d.toDate(), time: time.hour() })"
               :class="[ time.hour() === now.hour() ? 'is-now' : '', hourClass ]" v-for="time in day.availableTimes">
<!--            <span class="v-cal-day__hour-block-fill">{{ time.format('h A') }}</span>-->
              <perfect-scrollbar>
                <template v-if="day.events.length">
                  <template v-for="(event, index) in day.events" :key="index">
                    <event-item
                      v-if="event.startTime && time.hour() === event.startTime.hour()"
                      @click.stop="$emit('eventClicked', event)"
                      :event="event"/>
                  </template>
                </template>
              </perfect-scrollbar>
            </div>
          </div>

      </div>
    </div>
      </perfect-scrollbar>
    </div>
  </section>
</template>

<script>

import dayjs from "dayjs";

import EventItem from '../EventItem';
import IsView from '../mixins/IsView';
import ShowsTimes from '../mixins/ShowsTimes';
export default {
  name: "week",
  mixins: [ IsView, ShowsTimes ],
  components: { EventItem },
  props: {
    events: {
      type: Array
    }
  },
  data() {
    return {
      days: [],
    };
  },
  mounted() {
    this.buildCalendar();
  },
  methods: {
    timeClicked(data) {
      this.$emit('timeClicked', data);
    },
    buildCalendar() {
      //  Reset events
      // this.newEvents = JSON.parse(JSON.stringify(this.events));
      this.days = [];
      let now = dayjs();
      let temp = dayjs(this.activeDate).day(dayjs().localeData().firstDayOfWeek());
      // let temp1 = moment( this.activeDate ).day(moment.localeData().firstDayOfWeek());
      let w = temp.week();
      this.days = [];
      do {
        const day = dayjs(temp);
        const dayEvents = this.events.filter( e => e.date.isSame(day, 'day') )
            .sort( (a, b) => {
              if ( !a.startTime ) return -1;
              if ( !b.startTime ) return 1;
              return dayjs(a.startTime).format('HH') - dayjs(b.startTime).format('HH');
            });
        const mappedEvents = dayEvents.map( event => {
          event.overlaps = dayEvents.filter( e => dayjs(event.startTime).isBetween( dayjs(e.startTime), dayjs(e.endTime) ) && e !== event ).length;
          return event;
        });
        let newDay = {
          d: day,
          isPast: temp.isBefore( now, 'day' ),
          isToday: temp.isSame( now, 'day' ),
          isDisabled: this.isDayDisabled(temp),
          availableTimes: this.times.map( time => dayjs(time).dayOfYear( day.dayOfYear() ) ),
          events: mappedEvents
        };
        this.days.push(newDay);
        temp = temp.add( 1, 'day' );
      } while ( temp.week() === w );
    }
  },
  watch: {
    timeRange() {
      this.buildCalendar();
    },
  },
}
</script>

<style lang="scss" scoped>

.ps-wrapper {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 32px;
}

.v-calendar-schedule__content {
  height: 100%;
  padding-bottom: 32px;
  font-size: 0.875rem;
}

.v-calendar-schedule__weekdays {
  display: flex;
}

.v-calendar-schedule__weekday-wrapper {
  width: 100%;
  text-align: center;
  display: flex;
  border-radius: 8px 8px 0 0;
  background-color: var(--schedule-background);
  border-bottom: 1px solid var(--schedule-border-color);
}

.v-calendar-schedule__empty {
  min-width: 44px;
  margin-right: 8px;
}

.v-calendar-schedule__weekday-item {
  width: calc(100% / 7);
  line-height: 1.45;
  font-size: 0.875rem;
  padding: 8px 0;
  color: var(--color-gray-04);
  &.is-today {
    color: var(--color-secondary) !important;
    background-color: transparent;
  }
}

.v-calendar-schedule__days {
  display: flex;
}

.v-calendar-schedule__hour {
  min-height: 96px;
}

.v-calendar-schedule__days-wrapper {
  display: flex;
  width: 100%;
}

.v-calendar-schedule__times {
  min-width: 44px;
  margin-right: 8px;
}

.v-calendar-schedule__hour {
  text-align: right;
  color: var(--color-gray-05);
}

.v-calendar-schedule__day {
  width: calc(100% / 7);
}

.v-calendar-schedule__hour-block {
  position: relative;
  min-height: 96px;
  height: 96px;
  padding-right: 16px;
  background-color: var(--schedule-background);
  border-left: 1px solid var(--schedule-border-color);
  border-bottom: 1px solid var(--schedule-border-color);

  & > .ps {
    height: 100%;
  }

}

.v-calendar-schedule__remove-event {
  display: flex;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background-color: var(--color-gray-03);
  }
}

.is-active {
  color: #2ad2c9;
}

</style>
