import $dialog from "@plugin/vue-dlg/install";
import $fve from "@plugin/vue-form-element/install";
import $floatingVue from "@plugin/floating-vue/install";
// custom scrollbar
import $vueSmoothScrollbar from "@plugin/vue3-perfect-scrollbar/install";
import $vueSmoothScrollbarFix from "@plugin/vue3-perfect-scrollbar/installFix";

//multiselect
// import $multiselect from '@plugin/vue-multiselect/install';

import $vueCalendarSchedule from '@plugin/vue-calendar-schedule/install';

export default function (app) {
  if (process.env.NODE_ENV === "development") {
    // fix for serve mode - page reload
    // app.use($vueSmoothScrollbar);
    app.use($vueSmoothScrollbarFix);
  } else {
    app.use($vueSmoothScrollbar);
  }

  app.use($dialog);
  app.use($fve);
  app.use($floatingVue);
  // app.use($multiselect);
  app.use($vueCalendarSchedule);
}
