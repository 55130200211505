<template>
  <Container
    @drop="onElementDrop"
    :orientation="orientation"
    :behaviour="behaviour"
    group-name="file"
    :get-child-payload="getElementPayload"
    :drop-placeholder="dropPlaceholderOptions"
    :get-ghost-parent="getGhostParent"
    drop-class="element-ghost-drop"
    drag-class="element-ghost"
    @drag-start="dragStart($event)"
    @drag-end="dragEnd($event)"
    ref="dropzone"
  >
    <Draggable v-for="(item, index) in items" :key="`attached-item-${index}`">
      <slot name="item" :item="item" :index="index" :remove="remove"></slot>
    </Draggable>
  </Container>
</template>

<script>
import { Container, Draggable } from 'vue3-smooth-dnd';
export default {
  name: 'DropzoneDraggable',
  components: {
    Container,
    Draggable,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    orientation: {
      type: String,
      default: 'horizontal',
    },
    behaviour: {
      type: String,
      default: 'drop-zone',
    },
  },
  data() {
    return {
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true,
      },
    };
  },
  methods: {
    getGhostParent() {
      return document.body;
    },
    remove(i) {
      this.$emit(
        'update:items',
        this.items.filter((_, index) => index !== i)
      );
    },
    dragStart($event) {
      this.$emit('drag-start', $event);
      this.$refs.dropzone.$el.classList.add('smooth-dnd-shown');
      this.log('drop-start', $event);
    },
    dragEnd($event) {
      this.$emit('drag-end', $event);
      this.$refs.dropzone.$el.classList.remove('smooth-dnd-shown');
      this.log('drop-end', $event);
    },
    log(name = 'event', event) {
      console.log(`Key: ${this._.uid}`, name, event);
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },
    getElementPayload(index) {
      return this.items[index];
    },
    onElementDrop(dropResult) {
      this.$emit('update:items', this.applyDrag(this.items, dropResult));
    },
  },
};
</script>
