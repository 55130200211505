<template>
  <header class="header">
    <template v-if="componentName === 'email'">
      <EmailNav />
    </template>
    <template v-if="componentName === 'board'">
      <BoardNav />
    </template>
    <template v-if="componentName === 'schedule'">
      <ScheduleNav />
    </template>
    <template v-if="componentName === 'chat'">
      <ChatNav />
    </template>
    <!--    <component v-if="secondNavName" :is="secondNavName" />-->
    <MainNav @toggleNotifications="$emit('toggleNotifications')" />
  </header>
</template>

<script>
import EmailNav from "@component/Email/EmailNav";
import ScheduleNav from "@component/Schedule/ScheduleNav";
import MainNav from "@component/Part/MainNav";
import BoardNav from "@component/Board/BoardNav.vue";
import ChatNav from "@component/Chat/ChatNav";

export default {
  name: 'HeaderSection',
  components: {
    ChatNav,
    EmailNav,
    ScheduleNav,
    MainNav,
    BoardNav,
  },
  computed: {
    secondNavName() {
      return this.$route?.name?.includes(this.$routeName.EMAIL)
        ? 'EmailNav'
        : null;
    },
    componentName() {
      const arr = this.$route.fullPath.split("/");
      const str = arr[1] ? arr[1] : "default";
      const arr1 = str.split("?");
      return arr1[0];
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  height: 72px;
  padding: 22px 32px;
  justify-content: space-between;
}
</style>
