
// TODO: fix

// import Vue from 'vue';
//
// import * as permissionName from "@permission/permissionName";
// import PermissionLink from "@widgetPermission/PermissionLink";

export default async function PermissionInit() {
  // Vue.prototype.$permission = permissionName;
  // Vue.component('PermissionLink', PermissionLink);
}
