import { MethodInfo } from "@service/RequestManagerSrc";

export default {

  // UUID - id чата для комьюнити
  // firstUuid_secondUuid - id для чата one-one (чат с пользователем)

  // Параметр со значением id второго юзера.
  // var obj = {
  //   secondUserId: '' // Required
  // };

  createRoom: ({ firstUserId, secondUserId }) => {
    return new MethodInfo({
      type: "POST",
      url: "api://v1/cmm-api/chat/room/create",
      get: {
        firstUserId,
        secondUserId,
      },
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
    });
  },

  // В response получаем ChatRoom.class
  getChat: ({ chatId }) => {
    return new MethodInfo({
      type: "GET",
      url: `api://v1/cmm-api/chat/${chatId}`,
    });
  },

  // В response получаем массив ChatMessage.class
  getChatHistory: ({ chatId, page, pageSize }) => {
    return new MethodInfo({
      type: "GET",
      url: `api://v1/cmm-api/chat/${chatId}/history`,
      get: {
        page,
        pageSize
      }
    });
  },

  // В response получаем массив ChatRoom.class
  getChatRooms: () => {
    return new MethodInfo({
      type: "GET",
      url: `api://v1/cmm-api/chat/rooms`,
    });
  },

  sendChatMessage: ({ chatId, chatMessage }) => {
    return new MethodInfo({
      type: "POST",
      url: `api://v1/cmm-api/chat/${chatId}/message`,
      post: { body: chatMessage },
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
    });
  },

  chatLogin: () => {
    return new MethodInfo({
      type: "POST",
      url: 'api://v1/cmm-api/user/chat/login',
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
    });
  }

};
