<template>
  <div style="padding: 15px;" id="jitsi-block"></div>
</template>


<script>

export default {
  name: 'JitsiBlock',
};
</script>

<style lang="scss"></style>
