import { ref } from 'vue';

export const fileStoragePreloaderShown = ref(true);
export const fileStorageList = ref([]);
export const fileStorageSharedList = ref([]);
export const fileStorageFilterState = ref({});
export const fileStorageAutoHide = ref(true);
export const fileStorageShown = ref(false);

export const fileStoragePreloaderToggle = (value) => {
  fileStoragePreloaderShown.value = value ?? !fileStoragePreloaderShown.value;
};

export const fileStorageGetList = () => {
  return fileStorageList;
};

export const fileStorageSetList = (fileList) => {
  fileStorageList.value = fileList;
};

export const fileStorageAddToList = (filename) => {
  fileStorageList.value.push(filename);
};

export const fileStorageRemoveByName = (filename) => {
  return (fileStorageList.value = fileStorageList.value.filter(
    (name) => name !== filename
  ));
};

export const fileStorageToggleAutoHide = (value) => {
  fileStorageAutoHide.value = value ?? !fileStorageAutoHide.value;
};

export const fileStorageClearFilterState = () => {
  for (var key in fileStorageFilterState.value) {
    delete fileStorageFilterState.value[key];
  }
};

export const fileStorageGetPreview = async ({ filename, bucketname }) => {
  try {
    // prettier-ignore
    const extension = /[^\.]*$/.exec(filename)[0];
    let type = '';

    switch (true) {
      case /(jpe?g|png|gif)$/i.test(extension):
        type = `image/`;
        break;
      case /(mov|mp4|avi|amv)$/i.test(extension):
        type = `video/`;
        break;
      case /(pdf)$/i.test(extension):
        type = `application/`;
        break;
      // case /(xls)$/i.test(extension):
      //   type = 'application/vnd.ms-excel';
      //   break;
      // case /(doc)$/i.test(extension):
      //   type = 'application/vnd.msword';
      //   break;
      // case /(xlsx)$/i.test(extension):
      //   type =
      //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      //   break;
      // case /(docx)$/i.test(extension):
      //   type =
      //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      //   break;
      // default:
      //   type = 'application/octet-stream';
    }

    let response = null;
    try {
      response = await RequestManager.FileStorage.getFile({
        filename,
        bucketname,
        fileDownload: !type,
      });
    } catch (e) {
      DIALOG.notify.error('Error', 'Sorry, this file already deleted by owner');
      return;
    }
  
    if (type) {
      const file = new File([response], filename, { type: type + extension });
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');

      link.href = url;
      // link.download = filename;
      link.style = 'display: none';
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
    }
    // window.location.assign(url);

    // setTimeout(() => {
    //   URL.revokeObjectURL(url);
    //   document.body.removeChild(link);
    // }, 250);
  } catch (err) {}
};


export const fileStorageGetPreviewByUrl = async ({ filename, url }) => {
  try {
    // prettier-ignore
    const extension = /[^\.]*$/.exec(filename)[0];
    let type = '';
    
    switch (true) {
      case /(jpe?g|png|gif)$/i.test(extension):
        type = `image/`;
        break;
      case /(mov|mp4|avi|amv)$/i.test(extension):
        type = `video/`;
        break;
      case /(pdf)$/i.test(extension):
        type = `application/`;
        break;
      // case /(xls)$/i.test(extension):
      //   type = 'application/vnd.ms-excel';
      //   break;
      // case /(doc)$/i.test(extension):
      //   type = 'application/vnd.msword';
      //   break;
      // case /(xlsx)$/i.test(extension):
      //   type =
      //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      //   break;
      // case /(docx)$/i.test(extension):
      //   type =
      //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      //   break;
      // default:
      //   type = 'application/octet-stream';
    }
  
    let response = null;
    try {
      response = await RequestManager.FileStorage.getFileByUrl({
        filename,
        url,
        fileDownload: !type,
      });
    } catch (e) {
      DIALOG.notify.error('Error', 'Sorry, this file already deleted by owner');
      return;
    }
    
    if (type) {
      const file = new File([response], filename, { type: type + extension });
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');
      
      link.href = url;
      // link.download = filename;
      link.style = 'display: none';
      link.target = '_blank';
      
      document.body.appendChild(link);
      link.click();
    }
    // window.location.assign(url);
    
    // setTimeout(() => {
    //   URL.revokeObjectURL(url);
    //   document.body.removeChild(link);
    // }, 250);
  } catch (err) {}
};
