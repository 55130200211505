const DAY_NAME_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function getWeekday(date) {
  return date ? DAY_NAME_OF_WEEK[DateTime(date).local().day()] : "";
}
