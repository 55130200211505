
export default class CommunityContactClass {

  constructor(data) {
    this.id             = data.id;
    this.mail           = data.mail;
    this.firstName      = data.firstName;
    this.lastName       = data.lastName;
    this.profilePhoto   = data.profilePhoto;
    this.role           = data.role;
    this.status         = data.status;
  
    // TODO: не используется (или добавить или удалить полностью)
    // this.login = '',
  
  }
  
  get fullname() {
    let name = '';
    if (this.firstName) { name += this.firstName; }
    if (this.lastName)  { name += " " + this.lastName; }
    return name ? name : this.mail;
  }
  
  get isExternal() {
    return this.id.includes("external");
  }
}
