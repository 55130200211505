import { MethodInfo } from '@service/RequestManagerSrc';
import ContactClass from '../../../entity/class/ContactClass';
import CommunityContactClass from '@entity/class/CommunityContactClass';

const prepareCommunityList = (clBack) => {
  if (!clBack) {
    return [];
  }
  return clBack.map(prepareCommunity);
};

/**
 * @return {CommunityTypedef}
 */
const prepareCommunity = (communityBackend) => {
  const userId = USER.Auth.getUserId();
  return {
    id: communityBackend.id || '',
    name: communityBackend.name || '',
    description: communityBackend.description || '',
    // adminId: communityBackend.adminId || '',
    communityPhoto: communityBackend.communityPhoto
      ? new FileClass({
          id: 'BACKEND_IMAGE',
          src: communityBackend.communityPhoto,
        })
      : null,
    participants: prepareCommunityParticipantList(
      communityBackend.participants
    ),
    // TODO: use permission
    myRole: userId === communityBackend.adminId ? 'ADMIN' : 'USER',
  };
};

const prepareCommunityParticipantList = (cpBack) => {
  if (!cpBack) {
    return [];
  }
  const userId = USER.Auth.getUserId();
  const newParticipantList = [];
  for (let i = 0; i < cpBack.length; i++) {
    const participant = cpBack[i];
    if (participant.id === userId) {
      continue;
    }
    newParticipantList.push(prepareCommunityParticipant(participant));
  }
  return newParticipantList;
};

/**
 *
 * @returns {CommunityParticipantTypedef}
 */
const prepareCommunityParticipant = (cpBackend) => {
  return new CommunityContactClass({
    id: cpBackend.id || '',
    firstName: cpBackend.firstName || '',
    lastName: cpBackend.lastName || '',
    mail: cpBackend.mail || '',
    profilePhoto: cpBackend.profilePhoto
      ? new FileClass({ id: 'BACKEND_IMAGE', src: cpBackend.profilePhoto })
      : null,
    role: cpBackend.role || 'USER',
    status: cpBackend.status || 'ACTIVE',
    // не используемое поле
    // login: cpBackend.login || '',
  });
};

export default {
  getCommunityList: ({ offset, limit }) => {
    return new MethodInfo({
      name: 'Community::getList',
      type: 'GET',
      url: 'api://v1/cmm-api/community',
      get: {
        offset,
        limit,
      },
      responsePrepare: (communityList) => {
        return prepareCommunityList(communityList);
      },
      // errorMessage: `Не удалось получить список постов`
    });
  },

  /**
   *
   * @param community {Object} {id:String, name: String, participants: Array}
   * @returns {RequestClass}
   */
  addCommunity: (community) => {
    return new MethodInfo({
      name: 'Community::add',
      type: 'POST',
      url: 'api://v1/cmm-api/community',
      post: community,
      responsePrepare: (data) => {
        return prepareCommunity(data);
      },
    });
  },
  getCommunityById: ({ id }) => {
    return new MethodInfo({
      name: 'Community::getById',
      type: 'GET',
      url: `api://v1/cmm-api/community/${id}`,
      // errorMessage: `Не удалось получить список постов`
    });
  },
  /**
   *
   * @param community {Object} {name: String}
   * @returns {RequestClass}
   */
  updateCommunityById: (communityObj) => {
    return new MethodInfo({
      name: 'Community::getById',
      type: 'PUT',
      url: `api://v1/cmm-api/community/${communityObj.id}`,
      post: {
        ...communityObj,
      },
      responsePrepare: (data) => {
        // TODO: что за participantEntities
        return prepareCommunity(data);
      },
    });
  },
  deleteCommunityById: ({ id }) => {
    return new MethodInfo({
      name: 'Community::deleteById',
      type: 'DELETE',
      url: `api://v1/cmm-api/community/${id}`,
    });
  },

  addContactToCommunityById: ({ id, usersId }) => {
    return new MethodInfo({
      name: 'Commmunity::addUserById',
      type: 'POST',
      url: `api://v1/cmm-api/community/${id}/addUsers`,
      post: {
        usersId,
      },
    });
  },

  deleteContactFromCommunityById: ({ id, usersId }) => {
    return new MethodInfo({
      name: 'Commmunity::deleteUserById',
      type: 'POST',
      url: `api://v1/cmm-api/community/${id}/deleteUsers`,
      post: {
        usersId,
      },
    });
  },
};
