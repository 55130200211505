<script>

import FveText from "@fve/Element/Text/FveText";

export default {
  name: 'FveEmail',
  mixins: [
    FveText
  ],
  data(){
    return {
      type: 'email',
    };
  },
  methods: {
    validate(valueObj) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if( !re.test(valueObj.input) ) {
        return {
          status: 'ERROR',
          code : 'VALIDATE',
          message: 'Проверьте корректность E-mail адреса',
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>

@import "~@fve/style/inputText.scss";

</style>
