// import * as roleName from '@permission/roleName';

import { RouterView } from 'vue-router';
import LAYOUT from '@layout/layoutName';
import { ROLE_ANONYMOUS } from '@permission/roleName';

export default [
  {
    path: '/test',
    component: RouterView,
    // component: {
    //   // render: h => h(resolveComponent('router-view')),
    //   // template: `<router-view></router-view>`
    // },
    children: [
      {
        path: 'sidebar',
        name: 'SidebarTest',
        component: () => import('@test/page/SidebarTest'),
        meta: {
          layout: LAYOUT.DEFAULT,
        },
      },
      {
        path: 'notify',
        name: 'TestNotification',
        component: () => import('@test/page/TestNotification'),
        meta: {
          layout: LAYOUT.DEFAULT,
        },
      },
      {
        path: 'file-storage',
        name: 'FileStorage',
        component: () => import('@test/page/TestFileStorage'),
        meta: {
          layout: LAYOUT.DEFAULT,
        },
      },
      {
        path: 'schedule-event-create',
        name: 'SheduleEventCreate',
        component: () => import('@test/page/TestScheduleEventCreate'),
        meta: {
          layout: LAYOUT.DEFAULT,
        },
      },
      {
        path: 'schedule-event-view',
        name: 'SheduleEventView',
        component: () => import('@test/page/TestScheduleEventView'),
        meta: {
          layout: LAYOUT.DEFAULT,
        },
      },
      {
        path: '',
        name: 'test-1',
        component: () => import('@test/page/Test'),
        meta: {
          layout: LAYOUT.DEFAULT,
        },
      },
      {
        path: 'test2',
        name: 'test-2',
        component: () => import('@test/page/Test2'),
        meta: {
          layout: LAYOUT.DEFAULT,
        },
      },
      //
      {
        path: 'form',
        name: 'test-form',
        component: () => import('@test/page/form/TestForm'),
        meta: {
          layout: LAYOUT.BLOCK_CENTER,
          // permission: {
          //   [ROLE_ANONYMOUS]: true,
          // },
        },
      },
    ],
  },
];

// Form validate element
// TODO UI Test Validate if needed
/*
{
  path: 'fve',
  component: { render: h => h('router-view') },
  children: [
    {
      path: 'uikit',
      name: 'test-fve-uikit',
      component: () => import('@widgetFormValidate/test/page/UiKit'),
    },
    {
      path: 'uikit-text',
      name: 'test-fve-uikit-element-text',
      component: () => import('@widgetFormValidate/test/page/UiKitElementText'),
    },
    {
      path: 'uikit-select',
      name: 'test-fve-uikit-select',
      component: () => import('@widgetFormValidate/test/page/UiKitElementSelect'),
    },
    {
      path: 'uikit-switch',
      name: 'test-fve-switch',
      component: () => import('@widgetFormValidate/test/page/UiKitSwitch.vue'),
    },
  ],
},
 */
