export default class ContactClass {
  /**
   * @param {Object} data
   * @param {String} data.id
   * @param {String} data.firstName
   * @param {String} data.lastName
   * @param {String} data.login
   * @param {String} data.mail
   * @param {String} data.profilePhoto
   * @param {String} data.role
   * @param {String} data.status
   */
  constructor(data) {
    this.id = data.id;
    this.login = data.login;
    // TODO: Исправить когда бэк поправить на своей стороне
    this.mail = data.mail || data.email || data?.contact?.mail;
    this.profilePhoto = new FileClass({
      id: "",
      src: data?.contact?.profilePhoto || data.profilePhoto,
    });
    this.role = data.role;
    this.status = data.status;
    this.firstName = data?.contact?.firstName || data.firstName;
    this.lastName = data?.contact?.lastName || data.lastName;
    this.origin = {
      firstName: data.firstName,
      lastName: data.lastName,
      profilePhoto: new FileClass({ id: "", src: data.profilePhoto }),
    };
  }

  get fullname() {
    let name = "";
    if (this.firstName) name += this.firstName;
    if (this.lastName) name += " " + this.lastName;
    return name ? name : this.mail;
  }

  get fullnameOrigin() {
    let name = "";
    if (this.origin.firstName) name += this.origin.firstName;
    if (this.origin.lastName) name += " " + this.origin.lastName;
    return name ? name : this.mail;
  }

  get isDataOrigin() {
    return (
      this.fullnameOrigin === this.fullname &&
      this.profilePhoto.getSrc() === this.origin.profilePhoto.getSrc()
    );
  }

  get isExternal() {
    return this.id.includes("external");
  }
}
