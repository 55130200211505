//
import {
  communityList,
  selectedCommunity,
  selectedContact,
} from './reactiveObject';
//
import { contactSelect } from '@store/communityContact.store';
//
import { diffList, getFileClassSrc } from '@store/utils';

let communityListLoadedResolve = null;
const communityListLoaded = new Promise((res) => {
  communityListLoadedResolve = res;
});

export const contactIsAddToFriends = (contact) => {
  const personalComm = communityList.value[0];
  const res = personalComm.participants.find(c => c.id === contact.id);
  return !!res;
};

// getters
export const communityGetList = () => {
  // это вызывается 1 раз, а дальше работает как ссылка (возможно стоит углубиться в работу setup )
  return communityList;
};

export const communityGetStatus = () => {
  return communityListLoaded;
};

export const communityGetSelected = () => {
  return selectedCommunity;
};

// setters
export const communitySelect = (communityObj) => {
  contactSelect(null);
  selectedCommunity.value = communityObj;
};
export const communitySelectPersonal = () => {
  contactSelect(null);
  selectedCommunity.value = communityList.value[0];
  
};

/**
 *
 * @param {Object} community
 * @param {String} community.name
 * @param {FileClass} community.communityPhoto
 * @param {CommunityContactClass[]} participants
 * @returns {Promise<void>}
 */
export const communityAdd = async (community = {}, participants = []) => {
  const communityPhotoSrc = await getFileClassSrc(community.communityPhoto);
  try {
    const result = await RequestManager.Community.addCommunity({
      name: community.name,
      description: community.description || '', // TODO: fix (нет дескрипшена)
      communityPhoto: communityPhotoSrc,
      participants: participants.map((item) => ({ id: item.id })),
    });

    communityList.value.push(result);
  } catch (error) {}
};

export const communityUpdateInfo = async (communityObj) => {
  if (!communityList.value) {
    return;
  }

  const communityPhotoSrc = await getFileClassSrc(communityObj.communityPhoto);

  try {
    // prettier-ignore
    const updatedCommunity = await RequestManager.Community.updateCommunityById({
      // adminId:        communityObj.adminId,
      id:             communityObj.id,
      name:           communityObj.name,
      description:    communityObj.description || '', // TODO: fix (нет дескрипшена)
      communityPhoto: communityPhotoSrc,
      // TODO: Не передаем? или передаем
      participants: null,
      // participants: communityObj.participants.map((item) => ({ id: item.id })),
    });

    for (let i = 0; i < communityList.value.length; i++) {
      if (communityList.value[i].id !== communityObj.id) {
        continue;
      }

      // Вроде id не меняется
      communityList.value[i] = updatedCommunity;
      break;
    }
  } catch (error) {
    console.error(error);
  }
};

export const communityUpdateContactList = async (communityId, participants) => {
  if (!communityList.value) {
    return;
  }

  let currentCommunity = null;
  let currentCommunityIndex = null;
  for (let i = 0; i < communityList.value.length; i++) {
    if (communityList.value[i].id !== communityId) {
      continue;
    }

    currentCommunity = communityList.value[i];
    currentCommunityIndex = i;
    break;
  }

  if (!currentCommunity) {
    console.error('Undefined current community');
    return;
  }

  const diff = diffList(currentCommunity.participants, participants);

  if (diff.add.length) {
    await RequestManager.Community.addContactToCommunityById({
      id: communityId,
      usersId: diff.add.map((item) => item.id),
    });
  }
  if (diff.delete.length) {
    await RequestManager.Community.deleteContactFromCommunityById({
      id: communityId,
      usersId: diff.delete.map((item) => item.id),
    });
  }

  communityList.value[currentCommunityIndex].participants = participants;
};

export const communityDelete = async (communityId) => {
  if (!communityList.value) {
    return;
  }

  try {
    await RequestManager.Community.deleteCommunityById({ id: communityId });

    for (let i = 0; i < communityList.value.length; i++) {
      if (communityList.value[i].id !== communityId) {
        continue;
      }

      communityList.value.splice(i, 1);
      break;
    }
  } catch (error) {}
};

export const communityLeave = async (communityId) => {
  try {
    const userId = USER.Auth.getUserId();

    await RequestManager.Community.deleteContactFromCommunityById({
      id: communityId,
      usersId: [userId],
    });

    for (let i = 0; i < communityList.value.length; i++) {
      if (communityList.value[i].id !== communityId) {
        continue;
      }

      communityList.value.splice(i, 1);
      break;
    }
  } catch (error) {}
  // TODO
  // contactDeleteFromCommunity(userId);
};

// Инициализация
const communitySet = (newCommunityList) => {
  communityList.value = newCommunityList;
  selectedCommunity.value = communityList.value[0];
  selectedContact.value = null;
};

export const communityFetchList = async () => {
  try {
    /** @type {ContactTypedef[]} */
    let allContacts = [];
    /** @type {CommunityTypedef[]} */
    let communityList = [];
    try {
      allContacts = await RequestManager.Contact.getAll();
      communityList = await RequestManager.Community.getCommunityList();
    } catch (e) {
      console.error(e);
    }

    communitySet([
      {
        id: null,
        name: 'Personal',
        communityPhoto: null,
        // дополняем модель пользователей до partisipant
        participants: allContacts.map((item) => {
          return new CommunityContactClass({
            ...item,
            role: 'USER',
            status: 'ACTIVE',
          });
        }),
      },
      ...communityList,
    ]);
    communityListLoadedResolve();
  } catch (err) {}
};
