<template>
  <div class="cctgu create-community-tag-group-user">
    <div class="th-px cctgu-user-title">
      Add user
      <span class="cctgu-user-count"
        >{{ selectedContactCount }}/{{ prepareParticipantList.length }}</span
      >

      <FveText
        :field="{
          name: 'search',
          model: true,
        }"
        v-model="searchValue"
        label="Search"
        placeholder="Type name or personal ID"
      />
    </div>

    <div class="th-px cctgu-user-group">
      {{ communityName }}
      <p v-if="!show" class="cctgu-user-choose-all" @click="contactClickAll()">
        Choose all
      </p>
      <p v-if="show" class="cctgu-user-choose-all" @click="contactUnClickAll()">
        Unchoose all
      </p>
    </div>

    <div class="cctgu-contact-list">
      <div class="cctgu-ps-wrapper">
        <perfect-scrollbar>
          <div style="min-height: 208px">
            <template v-if="!filteredList.length">
              <div class="th-px no-results">No results found</div>
            </template>

            <template v-for="(contact, index) in filteredList" :key="index">
              <template v-if="!contact.isExternal">
                <div
                  class="th-px cctgu-group-item"
                  @click="contactClick(contact)"
                >
                  <div class="cctgu-avatar">
                    <Avatar
                      :selected="!!selectedContact[contact.id]"
                      :image="
                        contact.profilePhoto
                          ? contact.profilePhoto.getSrc()
                          : null
                      "
                      :name="contact.fullname"
                      :color="contact.color"
                    />
                  </div>
                  <p>{{ contact.fullname }}</p>
                  <!-- prettier-ignore -->
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-if="selectedContact[contact.id]" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.657 7.757a1 1 0 00-1.414-1.414L12.707 9.88a1 1 0 01-1.414 0L7.757 6.343a1 1 0 00-1.414 1.414l3.536 3.536a1 1 0 010 1.414l-3.536 3.536a1 1 0 101.414 1.414l3.536-3.536a1 1 0 011.414 0l3.536 3.536a1 1 0 101.414-1.414l-3.536-3.536a1 1 0 010-1.414l3.536-3.536z"/></svg>
                </div>
              </template>
            </template>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@component/Avatar';
import FveText from '@fve/Element/Text/FveText';

export default {
  name: 'CommunitySelectContact',
  components: {
    FveText,
    Avatar,
  },
  props: {
    communityName: { type: String, required: true },
    participants: { type: Array, required: true },
    selected: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      searchValue: '',
      selectedContact: Object.assign({}, this.selected),
    };
  },
  methods: {
    // step 3
    contactClick(contactObj) {
      if (this.selectedContact[contactObj.id]) {
        delete this.selectedContact[contactObj.id];
      } else {
        this.selectedContact[contactObj.id] = contactObj;
      }
      this.$emit('update:selected', this.selectedContact);
    },
    contactClickAll() {
      for (let i = 0; i < this.filteredList.length; i++) {
        let contactObj = this.filteredList[i];
        this.selectedContact[contactObj.id] = contactObj;
      }
      this.$emit('update:selected', this.selectedContact);
    },
    contactUnClickAll() {
      for (let i = 0; i < this.filteredList.length; i++) {
        let contactObj = this.filteredList[i];
        delete this.selectedContact[contactObj.id];
      }
      this.$emit('update:selected', this.selectedContact);
    },
  },
  computed: {
    prepareParticipantList() {
      return this.participants
        .map((contact) => new ContactClass(contact))
        .filter((contact) => !contact.isExternal);
    },

    filteredList() {
      return this.prepareParticipantList.filter((contact) => {
        return contact.fullname
          .toLowerCase()
          .includes(this.searchValue.toLowerCase());
      });
    },
    selectedContactCount() {
      return Object.keys(this.selectedContact).length;
    },
    show() {
      if (!this.filteredList.length) {
        return false;
      }
      for (let i = 0; i < this.filteredList.length; i++) {
        let contactObj = this.filteredList[i];
        if (!this.selectedContact[contactObj.id]) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cctgu {
  padding-bottom: 15px;

  .cctgu-contact-list {
    border-top: 1px solid var(--color-background);
    max-height: 208px;
  }
  
  .cctgu-user-title {
    color: var(--color-gray-08);
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 12px;
    ::v-deep(.fve-control > input) {
      background-color: transparent;
      border: 1px solid var(--schedule-event-input-border-color);
    }
  }
  .cctgu-user-count {
    color: var(--color-gray-05);
    margin-left: 0.25rem;
    font-weight: 400;
  }
  .cctgu-ps-wrapper {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 208px;
  }
  .cctgu-user-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 12px 40px 8px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 145%;
  }
  .cctgu-user-choose-all {
    margin-left: auto;
    cursor: pointer;
  }
  .cctgu-group-item {
    color: var(--color-gray-07);
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    transition: all 0.25s ease;
    display: flex;
    width: 100%;
    align-items: center;
    &:hover {
      background-color: var(--color-gray-02);
    }
    p {
      padding-left: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }
  }
  .cctgu-avatar {
    @include icon-size(2); // 32*32px
  }
  svg {
    margin-left: auto;
    fill: var(--color-gray-04);
  }
}

.no-results {
  text-align: center;
  color: var(--color-gray-04);
  padding-top: 90px;
}
</style>
