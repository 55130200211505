<template>
  <ul class="dropdown__list">
    <!--<li>-->
    <!--  <p>Turn off user notification</p>-->
    <!--</li>-->
    <!--<li>-->
    <!--  <p>Start chat</p>-->
    <!--</li>-->
    <!--<li>-->
    <!--  <p>Send email</p>-->
    <!--</li>-->
    <!--<li>-->
    <!--  <p>Send file</p>-->
    <!--</li>-->
    <!--<li>-->
    <!--  <p>Create meeting</p>-->
    <!--</li>-->
    <!--<li>-->
    <!--  <p>Assign task</p>-->
    <!--</li>-->
    <!--<li>-->
    <!--  <p>Attach board</p>-->
    <!--</li>-->
    <li class="text-red" v-close-popper @click="removeContact">
      <p>Delete contact</p>
    </li>
  </ul>
</template>
<script>

import { VClosePopper } from "floating-vue";


export default {
  directives: {
    ClosePopper: VClosePopper,
  },
  methods: {
    removeContact() {
      // import { removeContact } from "@store/contact.store";
      console.warn('removeContact add logic to emit or use sidebar menu array')
    }
  },
};
</script>
<style lang="scss" scoped></style>
