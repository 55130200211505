<template>
  <div>
    <div class="th-px" style="margin: 0 0 24px">
      <CommunityCreateForm
          ref="communityCreateForm"
          @submit="submitCommunityCreateForm"
          :formData="communityObj"
      />
    </div>
    <div class="btn-group th-px">
      <button class="btn btn--outline" @click.prevent="close">Discard</button>
      <button class="btn btn--primary" @click.prevent="save">Save</button>
    </div>
  </div>
</template>

<script>


import CommunityCreateForm from "@component/Community/CommunityCreateForm";

export default {
  name: 'CommunityEdit',
  components: {
    CommunityCreateForm,
  },
  props: {
    communityObj: {
      type: Object, // CommunityObject
      default: () => { return {}; },
    },
  },
  methods: {
    close(){
      this.$emit('close');
    },
    save() {
      this.$refs.communityCreateForm.submit();
    },
    //
    submitCommunityCreateForm(data) {
      this.$emit('submit', data);
    },
    //
  },
};
</script>

<style lang="scss" scoped>

</style>
