<template>
  <FveForm class="mdl-community__form mdl-community__form--step-1">
    <FveImagePreview
        :field="field.communityPhoto"
        name="image"
        class="mdl-community__image"
    />
    <FveText
        :field="field.name"
        label="Community name"
        name="name"
        placeholder="Type name"
    />
  </FveForm>
</template>

<script>
import FveMixinForm from "@fve/Mixin/FveMixinForm";
import FveText from "@fve/Element/Text/FveText";
import FveImagePreview from "@fve/Element/File/FveImagePreview";

export default {
  name: 'CommunityCreateForm',
  components: {
    FveText,
    FveImagePreview,
  },
  mixins: [FveMixinForm],
  methods: {
    formSchema() {
      return {
        communityPhoto: {
          type: FileClass,
          default: () => {
            return new FileClass();
          },
        },
        name: {
          type: String,
          default: () => { return ''; },
          field: {
            required: true,
          }
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect--custom {
  min-height: 48px;
  --ms-ring-width: 0;
  --ms-ring-color: none;
  --ms-border-color: #babace;
  --ms-radius: 8px;
  --ms-dropdown-radius: 0;
  --ms-max-height: 13rem;
  --ms-dropdown-my: 1rem;
  margin-bottom: calc(var(--ms-max-height) + var(--ms-dropdown-my) * 2);
  // flex-direction: column;
  &.is-open {
    border-radius: var(--ms-radius);
  }
  ::v-deep(.multiselect-dropdown) {
    // position: static;
    transform: unset;
    margin-top: var(--ms-dropdown-my);
    top: 100%;
    bottom: unset;
    left: calc(var(--mdl-community-px) * -1 - 2px);
    width: calc(100% + var(--mdl-community-px) * 2 + 4px);
    border: 1px solid #f8f8fa;
    border-bottom: 0;
    max-height: var(--ms-max-height);
    &.is-hidden {
      display: block;
    }
  }
}
.multiselect-tag {
  --ms-tag-bg: #e0e0ec;
  --ms-tag-font-weight: 400;
  --ms-tag-py: 0.25rem;
  --ms-tag-px: 1rem;
  --ms-tag-line-height: normal;
  --ms-tag-color: #22222d;
  padding: var(--ms-tag-py, 0.125rem) var(--ms-tag-px, 0.5rem);
}
.multiselect-tag-remove {
  --ms-tag-remove-py: 0;
  --ms-tag-remove-px: 0;
  svg {
    @include icon-size(1.25);
    fill: #c4c4c4;
  }
}

.fve-label {
  display: var(--fve-label--display);
  margin: var(--fve-label--margin);
  padding: var(--fve-label--padding);
  width: var(--fve-label--width);
  height: var(--fve-label--height);
  font-family: var(--fve-label--font-family);
  font-size: var(--fve-label--font-size);
  font-weight: var(--fve-label--font-weight);
  color: var(--fve-label--font-color);
  line-height: var(--fve-label--line-height);
  border: var(--fve-label--border);
  border-color: var(--fve-label--border-color);
  border-radius: var(--fve-label--border-radius);
  background: var(--fve-label--background);
  background-color: var(--fve-label--background-color);
  transition: color 0.15s ease-in-out;
}

.mdl-community__search {
  .btn-group {
    margin-top: 1rem;
  }
}

.mdl-community {
  --mdl-community-py: 2.5rem;
  --mdl-community-px: 2.5rem;
  padding: var(--mdl-community-py) var(--mdl-community-px);
  margin: 0 auto;
  position: relative;
  &.no-padding {
    padding: 0!important;
  }
}

.mdl-community__form {
  display: grid;
  grid-gap: 0.875rem 1rem;
  align-items: flex-end;
  
  ::v-deep(.fve-control > input) {
    background-color: transparent;
    border: 1px solid var(--schedule-event-input-border-color);
  }

  &--step-1 {
    grid-template-columns: 3rem 1fr;
    .btn-group {
      grid-column: 1 / 3;
    }
  }

  &--step-2 {
    grid-template-columns: 1fr;
  }
}

.mdl-community__add-contact {
  grid-column: 1 / 3;
  display: grid;
  grid-gap: 0 16px;
  grid-template-columns: max-content 1fr;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
  svg {
    fill: var(--color-secondary-light);
    @include icon-size(1.75);
  }
}

.mdl-community__image {
  @include icon-size(3);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mdl-community__close {
  position: absolute;
  top: 8px;
  right: 8px;
  @include icon-size(1.25);
  fill: #c4c4c4;
  cursor: pointer;
}

.mdl-communtity-create__link {
}

</style>
