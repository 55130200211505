import { MethodInfo } from '@service/RequestManagerSrc';
import ContactClass from '../../../entity/class/ContactClass';

export default {
  getAll({ offset, limit, pattern }) {
    return new MethodInfo({
      type: 'GET',
      url: 'api://v1/cmm-api/user',
      get: {
        offset,
        limit,
        // Без этого флага пока не работает
        withContact: true,
        pattern,
        withExternalUsers: true,
      },
      responsePrepare: (userList) => {
        // Удаляем себя из общего списка юзеров
        let meIndex = null;

        const userListPrepare = userList.map((contact, index) => {
          if (contact.id === USER.Auth.getUserId()) {
            meIndex = index;
          }
          return new ContactClass(contact);
        });

        if (meIndex !== null) userListPrepare.splice(meIndex, 1);

        return userListPrepare;
      },
    });
  },

  get({ offset, limit, userId }) {
    return new MethodInfo({
      type: 'GET',
      url: `api://v1/cmm-api/user/${userId}`,
      responsePrepare: (userData) => {
        return new ContactClass(userData);
      },
    });
  },

  add({ userId }) {
    return new MethodInfo({
      type: 'POST',
      url: 'api://v1/cmm-api/user',
      post: {
        // firstName: "string",
        // lastName: "string",
        // login: "string",
        // mail: "string",
        // password: "string",
        // birthday: "string",
        // gender: "string",
        // familyStatus: "string",
        // hobbiesAndInterests: "string",
        // companyName: "string",
        // countryOfResidence: "string",
        // profilePhoto: "string",
        // contact: {
        //   id: "string",
        //   firstName: "string",
        //   lastName: "string",
        //   profilePhoto: "string",
        // },
        id: userId,
      },
    });
  },

  delete({ id }) {
    return new MethodInfo({
      type: 'DELETE',
      url: `api://v1/cmm-api/user/${id}`,
    });
  },

  update({ id, user }) {
    return new MethodInfo({
      type: 'PUT',
      url: `api://v1/cmm-api/user/${id}`,
      post: {
        // firstName: "string",
        // lastName: "string",
        // login: "string",
        // mail: "string",
        // password: "string",
        // birthday: "string",
        // gender: "string",
        // familyStatus: "string",
        // hobbiesAndInterests: "string",
        // companyName: "string",
        // countryOfResidence: "string",
        // profilePhoto: "string",
        // contact: {
        //   id: "string",
        //   firstName: "string",
        //   lastName: "string",
        //   profilePhoto: "string",
        // },
        ...user,
      },
    });
  },
};
