import {
  communityList,
  selectedCommunity,
  selectedContact,
} from "./reactiveObject";
import { getFileClassSrc } from "@store/utils";
import { computed } from 'vue';
import {communityGetStatus} from "@store/community.store";

let contactListLoadedResolve = null;
const contactListLoaded = new Promise((res) => {
  contactListLoadedResolve = res;
});

export const participantObj = computed(() => {
  const participantObj = {};
  for (let i = 0; i < communityList.value.length; i++) {
    const participantList = communityList.value[i].participants;
    for (let y = 0; y < participantList.length; y++) {
      const participant = participantList[y];
      if (!participantObj[participant.id]) {
        participantObj[participant.id] = participant;
      }
    }
  }
  return participantObj;
});


export const myContactObj = computed(() => {
  const myContactObj = {};
  if( !communityList.value || !communityList.value[0] ) {
    return myContactObj;
  }
  
  const participantList = communityList.value[0].participants;
  for (let y = 0; y < participantList.length; y++) {
    const participant = participantList[y];
    if (!myContactObj[participant.id]) {
      myContactObj[participant.id] = participant;
    }
  }
  return myContactObj;
});

// Contact
export const contactGetSelected = () => {
  return selectedContact;
};

export const contactSelect = (contactObj) => {
  selectedContact.value = contactObj;
};

export const contactGetStatus = () => {
  return contactListLoaded;
};

export const contactAdd = async (contactObj) => {
  try {
    const imageSrc = await getFileClassSrc(contactObj.profilePhoto);
    const contact = await RequestManager.Contact.add({
      ...contactObj,
      profilePhoto: imageSrc,
    });
    communityList.value[0].participants.push(
      new CommunityContactClass({
        ...contact,
        role: "USER",
        status: "ACTIVE",
      })
    );
  } catch (error) {
    console.error(error);
  }
  // communityList.value[0].participants.push({
  //   id: fixNextId++,
  //   //
  //   name: contactObj.name,
  //   img: contactObj.image ? contactObj.image.getSrc() : null,
  // });
};

export const contactUpdate = async (id, contactObj) => {
  try {
    const imageSrc = await getFileClassSrc(contactObj.profilePhoto);

    await RequestManager.Contact.update({
      id: id,
      contact: {
        ...contactObj,
        profilePhoto: imageSrc,
      },
    });
    for (let i = 0; i < communityList.value.length; i++) {
      const participants = communityList.value[i].participants;
      for (let y = 0; y < participants.length; y++) {
        if (participants[y].id === id) {
          participants[y] = Object.assign(participants[y], contactObj);
        }
      }
    }
  } catch (error) {}
};

export const contactAddToCommunity = async (usersId, communityId = null) => {
  try {
    const arr = [].concat(usersId);

    const community = !communityId
      ? selectedCommunity.value
      : communityList.value.find((item) => item.id === communityId);

    await RequestManager.Community.addContactToCommunityById({
      id: community.id,
      usersId: arr,
    });

    const participants = communityList.value[0].participants.filter(
      (participant) => arr.includes(participant.id)
    );

    community.participants = community.participants.concat(participants);
  } catch (error) {}
};

export const contactDelete = async ({ id }) => {
  try {
    await RequestManager.Contact.delete({ id });
    communityList.value[0].participants =
      communityList.value[0].participants.filter((p) => p.id !== id);
  } catch (error) {}
};

export const contactDeleteFromCommunity = async (
  { id: usersId },
  communityId = null
) => {
  try {
    const arr = [].concat(usersId);

    const community = !communityId
      ? selectedCommunity.value
      : communityList.value.find((item) => item.id === communityId);

    await RequestManager.Community.deleteContactFromCommunityById({
      id: community.id,
      usersId: arr,
    });
    community.participants = community.participants.filter(
      (participant) => !arr.includes(participant.id)
    );
  } catch (err) {}
};
