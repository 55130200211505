<template>
  <ul class="dropdown__list">
    <li>
      <p @click="addContact">Add to community</p>
    </li>
    <li>
      <p>Add to schedule</p>
    </li>
    <li>
      <p>Start chat</p>
    </li>
    <li>
      <p>Send email</p>
    </li>
    <li>
      <p>Share board</p>
    </li>
    <li>
      <p>Share file</p>
    </li>
  </ul>
</template>
<script>

export default {
  methods: {
    addContact() {
      // import {addContact} from "@store/contact.store";
      console.warn('addContact add logic to emit or use sidebar menu array')
    }
  },
};
</script>

<style lang="scss" scoped>

</style>