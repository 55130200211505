<template>
<!--  <template v-if="/docx?/i.test(type)">-->
<!--    <img src="@img/icon/file-doc.svg" alt="">-->
<!--  </template>-->
<!--  <template v-else-if="type === 'pdf'">-->
<!--    <img src="@img/icon/file-pdf.svg" alt="">-->
<!--  </template>-->
<!--  <template v-else>-->
<!--    <img src="@img/icon/file-pdf.svg" alt="">-->
<!--  </template>-->
  <template v-if="type === 'jpg' || type === 'jpeg' || type === 'png' || type === 'gif'">
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.533 20h6.933C18.18 20 20 18.098 20 15.267V8.733C20 5.903 18.178 4 15.467 4H8.533C5.822 4 4 5.902 4 8.733v6.534C4 18.097 5.822 20 8.533 20zm.666-8.8c-1.103 0-1.999-.897-1.999-2s.896-2 2-2c1.101 0 1.998.897 1.998 2s-.897 2-1.999 2zm9.058 3.147c.267.687.128 1.513-.158 2.193-.34.809-.99 1.422-1.808 1.69a3.62 3.62 0 01-1.126.17H8.423c-.671 0-1.265-.161-1.751-.46-.305-.189-.36-.623-.133-.905.378-.47.751-.942 1.128-1.419.717-.911 1.2-1.175 1.738-.944.218.096.437.24.662.392.6.408 1.435.968 2.534.36.751-.421 1.188-1.144 1.567-1.773l.007-.01.08-.132c.128-.212.254-.42.396-.612.179-.24.84-.992 1.698-.457.546.337 1.006.793 1.497 1.28.188.187.321.4.41.627z"/></svg>
  </template>
  <template v-else-if="type === 'doc' || type === 'docx'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z" fill="#505FE1"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z" fill="#505FE1"/><path d="M7 14.058h1.426c.33 0 .623.059.878.177.256.118.454.286.595.503.14.217.21.47.21.762 0 .291-.07.545-.21.762-.141.217-.34.385-.595.503a2.073 2.073 0 01-.878.177H7v-2.884zm1.391 2.233c.259 0 .465-.07.62-.21.158-.14.237-.334.237-.581 0-.247-.079-.44-.237-.581-.155-.14-.361-.21-.62-.21h-.538v1.582h.538zM12.079 17c-.32 0-.606-.065-.862-.194a1.515 1.515 0 01-.599-.535 1.416 1.416 0 01-.215-.771c0-.286.072-.543.215-.77.147-.229.346-.407.6-.536.255-.13.542-.194.86-.194.32 0 .605.065.858.194.255.129.455.307.598.535.147.228.22.485.22.771 0 .286-.073.543-.22.77a1.478 1.478 0 01-.598.536c-.253.13-.539.194-.857.194zm0-.676c.152 0 .29-.034.413-.103a.761.761 0 00.293-.288.861.861 0 00.107-.433.839.839 0 00-.107-.429.754.754 0 00-.293-.292.837.837 0 00-.414-.103.837.837 0 00-.413.103.754.754 0 00-.293.292.838.838 0 00-.107.429.86.86 0 00.107.433c.072.123.17.22.293.288a.837.837 0 00.414.103zM15.704 17c-.316 0-.6-.063-.853-.19a1.491 1.491 0 01-.59-.535 1.424 1.424 0 01-.215-.775 1.424 1.424 0 01.805-1.306c.252-.13.537-.194.853-.194.275 0 .524.047.745.14.22.093.405.228.551.404l-.543.47a.907.907 0 00-.71-.338.922.922 0 00-.44.103.746.746 0 00-.297.292.866.866 0 00-.103.429.89.89 0 00.103.433c.072.123.171.22.297.288.13.069.276.103.44.103a.907.907 0 00.71-.338l.543.47c-.146.176-.33.31-.551.404-.221.093-.47.14-.745.14z" fill="#fff"/></svg>
  </template>
  <template v-else-if="type === 'xls' || type === 'xlsx'">
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z"/></svg>
  </template>
  <template v-else-if="type === 'mp4' || type === 'avi' || type === 'mov'">
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.586 6.586C4 7.172 4 8.114 4 10v4c0 1.886 0 2.828.586 3.414C5.172 18 6.114 18 8 18h8c1.886 0 2.828 0 3.414-.586C20 16.828 20 15.886 20 14v-4c0-1.886 0-2.828-.586-3.414C18.828 6 17.886 6 16 6H8c-1.886 0-2.828 0-3.414.586zm10.664 5.847a.5.5 0 000-.866l-4.5-2.598a.5.5 0 00-.75.433v5.196a.5.5 0 00.75.433l4.5-2.598z"/></svg>
  </template>
  <template v-else-if="type === 'pdf'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z" fill="#E81D1D"/><path d="M8.522 14c.301 0 .563.046.785.137.221.089.393.217.513.386.12.166.18.361.18.587 0 .226-.06.421-.18.587-.12.166-.292.294-.513.386a2.113 2.113 0 01-.785.133h-.58V17H7v-3h1.522zm-.057 1.547c.193 0 .339-.037.437-.111a.39.39 0 00.148-.326.39.39 0 00-.148-.326c-.098-.077-.244-.115-.437-.115h-.523v.878h.523zM10.46 14h1.574c.365 0 .688.061.97.184a1.5 1.5 0 01.657.523c.155.226.233.49.233.793 0 .303-.078.567-.233.793a1.5 1.5 0 01-.657.523 2.408 2.408 0 01-.97.184H10.46v-3zm1.536 2.323c.285 0 .514-.073.685-.219.174-.145.262-.347.262-.604s-.088-.459-.262-.604c-.171-.146-.4-.219-.685-.219h-.594v1.646h.594zM15.33 14.656v.66h1.47v.655h-1.47V17h-.94v-3H17v.656h-1.67z" fill="#fff"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z" fill="#E81D1D"/></svg>
  </template>
  <template v-else>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z"/></svg>
  </template>
</template>

<script>
export default {
  props: {
    file: {
      type: [Object, String],
      default: () => ({})
    }
  },
  computed: {
    type() {
      const regExp = /[A-Za-z0-9]*$/gm;
      return this.file.filename.match(regExp)[0].toLowerCase();
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
