<template>
  <div>
    <div class="th-px" style="margin: 0 0 24px">
      <ContactAddForm
          ref="contactAddForm"
          @submit="submitContactAddForm"
      />
    </div>
    <div class="btn-group th-px">
      <button class="btn btn--outline" @click.prevent="close">Discard</button>
      <button class="btn btn--primary" @click.prevent="addBtnClick">Add</button>
    </div>
  </div>
</template>

<script>

import ContactAddForm from "@component/Contact/ContactAddForm";

export default {
  name: 'CommunityCreate',
  components: {
    ContactAddForm,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    addBtnClick() {
      this.$refs.contactAddForm.submit();
    },
    //
    submitContactAddForm(data) {
      this.$emit('save', data);
    },
  
  },
};
</script>

<style lang="scss" scoped>

</style>
