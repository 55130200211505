
// style
import '@fve/style/const.scss';
import '@fve/style/const-dark.scss';
import './custom-style.scss';

// plugins
// import './dependency/plugin/v-mask';



const install = (app, options) => {
  // TODO: add setting support
};


export default {
  install: install
};
