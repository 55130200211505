<template>
  <!--  <div class="dlg-template" :class="{ ['dlg-template-' + theme]: !!theme}">-->
  <!--    <div class="dlg-header">-->
  <!--      <slot name="icon"></slot>-->
  <!--      <div class="dlg-title" >{{title}}</div>-->
  <!--    </div>-->
  <!--    <div class="dlg-body">-->
  <!--      <div class="content">-->
  <!--        <template v-if="messageType === 'HTML'">-->
  <!--          <div v-html="message"></div>-->
  <!--        </template>-->
  <!--        <template v-else-if="messageType === 'SLOT'">-->
  <!--          <slot/>-->
  <!--        </template>-->
  <!--        <template v-else>-->
  <!--          <div class="dlg-content-message">{{message}}</div>-->
  <!--        </template>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="dlg-footer">-->
  <!--      <a v-if="okLabel"     class="dlg-btn success" @click="$emit('close', {action: 'OK'})">{{ okLabel }}</a>-->
  <!--      <a v-if="cancelLabel" class="dlg-btn cancel"  @click="$emit('close', {action: 'CANCEL'})">{{ cancelLabel }}</a>-->
  <!--    </div>-->
  <!--  </div>-->

  <div class="dlg-template" :class="{ ['dlg-template-' + theme]: !!theme }">
    <div class="mdl-header th-px">
      <svg width="28" height="29" viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon-info'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.333 14.5a7.333 7.333 0 11-14.666 0 7.333 7.333 0 0114.666 0zm2 0a9.333 9.333 0 11-18.666 0 9.333 9.333 0 0118.666 0zm-8.377 4.666v-6.291h-1.911v6.291h1.91zm-1.8-7.325c.214.225.495.337.844.337s.63-.112.845-.337c.214-.232.322-.522.322-.87 0-.333-.108-.604-.322-.813-.215-.216-.496-.325-.845-.325s-.63.112-.845.337a1.162 1.162 0 00-.322.836c0 .332.108.611.322.835z"/></svg>
      <div class="mdl-title">{{ title }}</div>
    </div>
    <div class="mdl-message th-px" v-if="message">{{ message }}</div>
    <div class="btn-group th-px">
      <!-- prettier-ignore -->
      <button class="btn btn--outline" @click.prevent.stop="$emit('close', { action: 'OK' })">{{ okLabel }}</button>
      <!-- prettier-ignore -->
      <button class="btn btn--primary" @click.prevent.stop="$emit('close', { action: 'CANCEL' })">{{ cancelLabel }}</button>
    </div>
  </div>
</template>

<script>
import DialogTemplateMixin from "@plugin/vue-dlg/src/Template/DialogTemplateMixin";

export default {
  mixins: [DialogTemplateMixin],
  props: {
    theme: {
      type: String,
      default: "",
    },
    closeBtn: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    messageType: {
      type: String,
      default: "MESSAGE", // MESSAGE | HTML | SLOT
    },
    message: {
      type: String,
      required: true,
    },
    okLabel: {
      type: String,
      default: "",
    },
    cancelLabel: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.dlg-template {
  //background: #fff;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  color: #50596c;

  //&.dlg-fullheight {
  //  max-height: 100vh;
  //}
  .dlg-header {
    color: #454d5d;
    padding: 16px 16px 16px 32px;
    .dlg-title {
      color: #454d5d;
      font-size: 18px;
      font-weight: 500;
    }
    .dlg-btn-close {
      cursor: pointer;
      height: 20px;
      line-height: 20px;
      padding: 0;
      width: 20px;
      float: right;
      color: #50596c;
      text-decoration: none;
      &::before {
        content: "\2715";
      }
    }
  }
  .dlg-body {
    overflow-y: auto;
    padding: 32px 16px 32px 32px;
    position: relative;

    color: #50596c;
    font-size: 18px;
    font-weight: 500;
    //.dlg-input {
    //  display: block;
    //  width: 100%;
    //}
    //.prompt-error-message {
    //  color: #d9534f;
    //  font-size: 14px;
    //}
  }
  .dlg-footer {
    padding: 16px 16px 16px 32px;
    text-align: center;
  }
  .dlg-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    //background: transparent;
    border: none;
    //color: #5cb85c;
    cursor: pointer;
    display: inline-block;
    outline: none;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    min-width: 100px;
    height: 36px;
    box-sizing: border-box;
    padding: 7px 20px !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 1.6;
    border-radius: 18px;

    color: #fff;
    background-color: #f5ac1c;
    //&.dlg-btn-danger {
    //  color: #d9534f;
    //}
  }
}
.dlg-template .dlg-btn.disabled,
.dlg-template .dlg-btn[disabled],
.dlg-template .dlg-btn:disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

// Theme
.dlg-template {
  max-height: 75vh;
  background: transparent;
  color: var(--color-gray-10);
  //box-shadow: 0 4px 10px rgba(69,77,93,0.3);
  border-radius: 5px;
  overflow: hidden;
  //border-left: 5px solid var(--dlg-success-color, #f5ac1c) !important;

  // success
  &.dlg-template-success {
    .dlg-header {
      //background: #dc472e;
      background: #5cd182;
    }
  }

  // warning
  &.dlg-template-warning {
    .dlg-header {
      background: #f5ac1c;
    }
  }

  // error
  &.dlg-template-error {
    .dlg-header {
      //background: #dc472e;
      background: #ffb3b3;
    }
  }
}

.icon-info {
  fill: var(--color-red-03);
  margin-right: 12px;
}

.mdl-header {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.mdl-title {
  font-weight: 600;
  font-size: 1rem;
  color: var(--color-gray-08);
}

.mdl-message {
  margin-bottom: 24px;
}
</style>
