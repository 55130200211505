import { MethodInfo } from "@service/RequestManagerSrc";

export default {
  /*
  "id": 0,
  "isExternal": true,
  "subject": "string",
  "from": "string",
  "recipient": "string",
  "ccRecipient": "string",
  "bccRecipient": "string",
  "folder": "string",
  "body": "string",
  "draft": true,
  "unread": true
  * */

  //   {
  //   "id": "string",
  //   "isExternal": true,
  //   "subject": "string",
  //   "from": "string",
  //   "recipient": "string",
  //   "ccRecipient": "string",
  //   "bccRecipient": "string",
  //   "folder": "string",
  //   "body": "string",
  //   "seen": true,
  //   "ownerCommunityId": "string",
  //   "ownerCommunityName": "string"
  //   }

  add: (mailObj) => {
    return new MethodInfo({
      type: "POST",
      url: "api://v1/cmm-api/mail",
      post: mailObj,
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
    });
  },

  send: ({ delayedSend, data }) => {
    return new MethodInfo({
      type: "POST",
      // url: `api://v1/cmm-api/sendMail/${mailObj.id}`,
      url: `api://v1/cmm-api/sendMail`,
      get: {
        mailId: data.id,
        delayedSend,
      },
      post: data,
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
    });
  },

  updateEmailById: ({ id, data }) => {
    return new MethodInfo({
      type: "PUT",
      url: `api://v1/cmm-api/mail/${id}`,
      post: { id, ...data },
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
    });
  },

  deleteEmailById: ({ id }) => {
    return new MethodInfo({
      type: "DELETE",
      url: `api://v1/cmm-api/mail/${id}`,
    });
  },

  deleteEmailByList: ({ emailIds }) => {
    return new MethodInfo({
      type: "POST",
      url: `api://v1/cmm-api/batchDelete`,
      post: {
        emailIds: [].concat(emailIds),
      },
    });
  },

  getEmailById: ({ id }) => {
    return new MethodInfo({
      type: "GET",
      url: `api://v1/cmm-api/mail/${id}`,
    });
  },

  /**
   *
   * @param {String} tag
   * @returns {MethodInfo}
   */

  // prettier-ignore
  getAll: ({ offset, limit, withoutCommunity, communityId, folderName, seen, any }) => {
    return new MethodInfo({
      type: "GET",
      url: "api://v1/cmm-api/mail",
      get: {
        offset,
        limit,
        withoutCommunity,
        communityId,
        folderName,
        seen,
        any
      },
      responsePrepare: (data) => {
        return { list: data.content, total: data.total };
      },
    });
  },

  moveToFolder: ({ folderName, emailIds }) => {
    return new MethodInfo({
      type: "POST",
      url: "api://v1/cmm-api/moveToFolder",
      get: {
        folderName,
      },
      post: { emailIds: [].concat(emailIds) },
    });
  },

  /**
   *
   * @param {Array}, listId
   * @param {String} tag
   * @returns {MethodInfo}
   */
  // setTag: ({ listId, tag }) => {
  //   return new MethodInfo({
  //     type: "POST",
  //     url: "test://email/set-tag",
  //     provider: "TEST_SERVER",
  //     post: { listId, tag },
  //     // responsePrepare: (data) => {
  //     //   return {token: data.jwt};
  //     // },
  //   });
  // },
  /**
   *
   * @param listId
   * @returns {MethodInfo}
   */
  setRead: ({ emailIds }) => {
    return new MethodInfo({
      type: "POST",
      url: "api://v1/cmm-api/markRead",
      post: { emailIds: [].concat(emailIds) },
    });
  },

  /**
   *
   * @param listId
   * @returns {MethodInfo}
   */
  setUnread: ({ emailIds }) => {
    return new MethodInfo({
      type: "POST",
      url: "api://v1/cmm-api/markUnread",
      post: { emailIds: [].concat(emailIds) },
    });
  },

  setStarred: ({ listId }) => {
    return new MethodInfo({
      type: "POST",
      url: "test://email/set-starred",
      provider: "TEST_SERVER",
      post: { listId },
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
    });
  },

  setUnstarred: ({ listId }) => {
    return new MethodInfo({
      type: "POST",
      url: "test://email/set-unstarred",
      provider: "TEST_SERVER",
      post: { listId },
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
    });
  },
};
