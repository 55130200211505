<template>
  <div
    class="dlg-template dlg-template-simple-message dtsm"
    :class="{ ['dtsm-' + theme]: !!theme }"
  >
    <div class="dlg-close" @click="$emit('close', { action: 'CLOSE' })">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.6569 7.75735C18.0474 7.36682 18.0474 6.73366 17.6569 6.34313C17.2663 5.95261 16.6332 5.95261 16.2426 6.34313L12.7071 9.87867C12.3166 10.2692 11.6834 10.2692 11.2929 9.87867L7.75736 6.34313C7.36684 5.95261 6.73367 5.95261 6.34315 6.34313C5.95262 6.73366 5.95262 7.36682 6.34315 7.75735L9.87868 11.2929C10.2692 11.6834 10.2692 12.3166 9.87868 12.7071L6.34315 16.2426C5.95262 16.6332 5.95262 17.2663 6.34315 17.6568C6.73367 18.0474 7.36683 18.0474 7.75736 17.6568L11.2929 14.1213C11.6834 13.7308 12.3166 13.7308 12.7071 14.1213L16.2426 17.6568C16.6332 18.0474 17.2663 18.0474 17.6569 17.6568C18.0474 17.2663 18.0474 16.6332 17.6569 16.2426L14.1213 12.7071C13.7308 12.3166 13.7308 11.6834 14.1213 11.2929L17.6569 7.75735Z"
        />
      </svg>
    </div>
    <div class="dtsm-title">{{ title }}</div>
    <div class="dtsm-message">{{ message }}</div>
  </div>
</template>

<script>
import DialogTemplateMixin from '@plugin/vue-dlg/src/Template/DialogTemplateMixin';

export default {
  mixins: [DialogTemplateMixin],
  props: {
    theme: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // TODO: update timer logic
    setTimeout(() => {
      this.close({ action: 'CLOSE' });
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
.dlg-close {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
    fill: var(--color-red-03);
  }
}
.dtsm {
  display: block;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: var(--modal-shadow);
  //margin: 0 5px 5px;
  color: var(--color-gray-09);
  background: #ccd3f3;
  border: 1px solid var(--color-red-03);

  .dtsm-title {
    font-weight: 600;
  }
  .dtsm-message {
  }
}

.dtsm.dtsm-success {
  // color: #fff;
  // background: #4aa4fc;
  // border-color: #187ee7;
  color: var(--color-gray-09);
  // background-color: var(--color-red-01);
  background-color: #EBF8E6;
  border-color: #5cd182;
  .theme-dark & {
    //background-color: var(--color-gray-02);
    //background-color: #EBF8E6;
    background-color: var(--color-gray-02);
    border-color: #5cd182;
  }
  .dlg-close {
    svg {
      fill: #5cd182;
    }
  }
  
}

.dtsm.dtsm-error {
  color: var(--color-gray-09);
  background-color: var(--color-red-01);
  .theme-dark & {
    background-color: var(--color-gray-02);
  }
  // background: var(--color-red-01);
  // border-left: 5px solid var(--color-red-03);
}
</style>
