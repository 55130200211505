import { MethodInfo } from "@service/RequestManagerSrc";

export default {
  add({ file, shared = false }) {
    const formData = new FormData();
    const userId = USER.Auth.getUserId();

    formData.append("file", file, file.name);
    const getObj = {};
    if (shared) {
      getObj.shared = "true";
    }

    return new MethodInfo({
      type: "POST",
      url: `api://v1/cmm-api/bucket/${userId}/file`,
      post: formData,
      get: getObj,
      header: { "Content-Type": "multipart/form-data" },
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
    });
  },

  remove({ filename }) {
    const userId = USER.Auth.getUserId();
    return new MethodInfo({
      type: "DELETE",
      url: `api://v1/cmm-api/bucket/${userId}/file/${filename}`,
      // responsePrepare: (data) => {
      //   return {token: data.jwt};
      // },
    });
  },

  getList({ extension, filename }) {
    const userId = USER.Auth.getUserId();
    // fileName = 'RequestManagerScheme(1)'

    return new MethodInfo({
      type: "GET",
      url: `api://v1/cmm-api/bucket/${userId}/file`,
      get: {
        extension,
        filename,
      },
      // responsePrepare: (data) => {
      //   const nameRegExp = /[^\/]+(?=\/$|$)/gm;
      //   return data.map(item => {
      //     return {
      //       name: item.url.match(nameRegExp)[0] || 'no_name',
      //       ...item,
      //     };
      //   });
      // },
    });
  },
  
  getFileByUrl({url, filename, fileDownload=true}) {
    return new MethodInfo({
      type: 'GET',
      url: url,
      fileName: filename,
      fileDownload: fileDownload,
    });
  },

  getFile({
    filename,
    bucketname: bucketName = USER.Auth.getUserId(),
    fileDownload = true,
  }) {
    return new MethodInfo({
      type: "GET",
      url: `api://v1/cmm-api/bucket/${bucketName}/file/${filename}`,
      fileName: filename,
      fileDownload,
      // responsePrepare: (data) => {
      // },
    });
  },

  shareFile({ bucketName, filename, shareObj }) {
    return new MethodInfo({
      type: "PUT",
      url: `api://v1/cmm-api/bucket/${bucketName}/file/${filename}/share`,
      post: shareObj,
      // responsePrepare: (data) => {
      // },
    });
  },

  getSharedFiles({ filename, extension }) {
    const userId = USER.Auth.getUserId();
    return new MethodInfo({
      type: "GET",
      url: `api://v1/cmm-api/bucket/${userId}/file`,
      get: {
        external: true,
        filename,
        extension,
      },
    });
  },
};
