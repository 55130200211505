
import vueDlgPlugin from "@plugin/vue-dlg/src/plugin";

import dialogAction from "./action";
import "./group-settings";
import './style.scss';

global.DIALOG = dialogAction;

// global.DIALOG.alert.success('success');
// global.DIALOG.alert.warning('warning 1');
// global.DIALOG.alert.warning('warning 2');
// global.DIALOG.alert.error('error');

export default {
  install: (app) => {
    vueDlgPlugin.install(app, {action: dialogAction})
  },
};