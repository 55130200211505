<template>
  <div class="dropdown__list fs__filter__list">
    <template v-for="item in filterList" :key="item.key">
      <div class="fs__filter__item">
        <FveCheckbox
          :field="{
            name: item.key,
            model: true,
          }"
          @update:modelValue="changeFilterState(item, $event)"
          :modelValue="!!fileStorageFilterState[item.key]"
          :label="item.name"
          :key="item.key"
        />
      </div>
    </template>
  </div>
</template>
<script>
import FveCheckbox from "@fve/Switch/FveCheckbox";
import { fileStorageFilterState } from "@store/fileStorage.store";
export default {
  components: {
    FveCheckbox,
  },
  setup() {
    return {
      fileStorageFilterState,
    };
  },
  data() {
    return {
      // prettier-ignore
      filterList: [
        { name: "Image", key: "image", value: ["jpg", "png", "gif"] },
        { name: "Video", key: "video", value: ["mp4", "avi", "mov"] },
        { name: "PDF",   key: "pdf",   value: ["pdf"] },
        { name: "DOC",   key: "doc",   value: ["doc", "docx"] },
        { name: "XLS",   key: "xls",   value: ["xls", "xlsx"] },
        { name: "Other", key: "other", value: [] },
      ],
    };
  },
  methods: {
    changeFilterState({ key, value }, $event) {
      if (fileStorageFilterState.value[key] && !$event) {
        delete fileStorageFilterState.value[key];
      } else {
        fileStorageFilterState.value[key] = value;
      }
    },
  },
};
</script>
