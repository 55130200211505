import { MethodInfo } from "@service/RequestManagerSrc";

const prepareContact = (contactBack) => {
  return {
    id:           contactBack.id        || '',
    firstName:    contactBack.firstName || '',
    lastName:     contactBack.lastName  || '',
    mail:         contactBack.mail      || '',
    profilePhoto: contactBack.profilePhoto ? new FileClass({id: 'BACKEND_IMAGE', src: contactBack.profilePhoto }) : null,
  };
};


export default {
  getAll({ offset, limit }) {
    return new MethodInfo({
      type: 'GET',
      url: 'api://v1/cmm-api/contact',
      get: {
        offset,
        limit,
      },
      responsePrepare: (contactList) => {
        return contactList.map(prepareContact);
      },
    });
  },

  get({ offset, limit, userId }) {
    return new MethodInfo({
      type: "GET",
      url: "api://v1/cmm-api/contact",
      get: {
        offset,
        limit,
        userId,
      },
    });
  },

  add(contactObj) {
    return new MethodInfo({
      type: "POST",
      url: "api://v1/cmm-api/contact",
      post: {
        ...contactObj,
        // firstName: "string",
        // lastName: "string",
        // profilePhoto: "string",
        // email: "string"
      },
      responsePrepare: (contact) => {
        return prepareContact(contact);
      },
    });
  },

  delete({ id }) {
    return new MethodInfo({
      type: "DELETE",
      url: `api://v1/cmm-api/contact/${id}`,
    });
  },

  update({ id, contact }) {
    return new MethodInfo({
      type: "PUT",
      url: `api://v1/cmm-api/contact/${id}`,
      post: {
        // firstName: "string",
        // lastName: "string",
        // profilePhoto: "string",
        ...contact,
      },
    });
  },
};
